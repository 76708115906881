import { NavigationInstruction, Router } from 'aurelia-router';
import { BrowserHistory } from 'aurelia-history-browser';

import { BaseEntity } from './EntityManager/entities/BaseEntity';
import { Utils } from './Utils/Utils';
import { Entry } from './EntityManager/entities/Entry/types';

/**
 * all navigation function don't trigger the `activate` handler (at least if you implement the `consumeIgnoreNavigation` correctly)
 * that means you need to handle e.g. the opening of the edit-entry-widget-overlay outside of the `activate` handler too
 */
export class EditStructureNavigator {
  private static defaultOptions: Options = {
    routeName: 'project',
    mainEntityIdParamName: 'project_id'
  };

  private previousComparisonUrl: string = '';
  private ignoringNavigation: boolean = false;
  private options: Options;

  constructor(
    private readonly router: Router,
    options: Options | null
  ) {
    this.options = Object.assign(
      {},
      EditStructureNavigator.defaultOptions,
      options || {}
    );
  }

  public navigateToEntry(
    mainEntity: BaseEntity,
    entry: Entry | null,
    editEntry: boolean
  ): void {
    const params: Record<string, string | boolean> = {};
    params[this.options.mainEntityIdParamName] = mainEntity.id;

    if (entry) {
      params.entry_id = entry.id;
    }

    if (editEntry) {
      params.edit_entry = true;
    }

    this.navigateToRouteAndIgnoreNextNavigation(this.options.routeName, params);
  }

  /**
   * execute this function and check the result in the `activate` handler
   * if the result is true, don't do anything in the handler
   *
   * this is a result of needing to trigger routes (even though they don't need to be triggered by our logic), so aurelia's history works correctly
   */
  public consumeIgnoreNavigation({
    navigationInstruction
  }: {
    navigationInstruction: NavigationInstruction;
  }): boolean {
    const previousComparisonUrl = this.previousComparisonUrl;
    const newComparisonUrl = this.getComparisonUrlString({
      navigationInstruction
    });
    this.previousComparisonUrl = newComparisonUrl;

    const oldIgnore = this.ignoringNavigation;
    this.ignoringNavigation = false;

    return oldIgnore || previousComparisonUrl === newComparisonUrl;
  }

  /**
   * check this function in the `deactivate` handler
   */
  public isIgnoringNavigation(
    navigationInstruction: NavigationInstruction
  ): boolean {
    const newUrl = this.getComparisonUrlString({ navigationInstruction });
    const previousUrl = this.getComparisonUrlString({
      navigationInstruction: navigationInstruction.previousInstruction
    });

    return this.ignoringNavigation || newUrl === previousUrl;
  }

  private navigateToRouteAndIgnoreNextNavigation(
    routeName: string,
    params: Record<string, string | boolean>
  ): void {
    const route = this.router.generate(routeName, params);
    const currentRoute = this.router.history
      ? ((this.router.history as BrowserHistory).fragment ?? '')
      : '';

    if (!Utils.compareRoutes(route, currentRoute)) {
      // if the routes are the same, the router will not trigger and the deactivate step will be skipped when we actually navigate away from the route
      this.ignoringNavigation = true;
      this.router.navigateToRoute(routeName, params, { trigger: true });
    }
  }

  private getComparisonUrlString({
    navigationInstruction
  }: {
    navigationInstruction: NavigationInstruction;
  }): string {
    // localhost is just a placeholder since the URL needs a domain. In the end, the Url will only be used for change detection, so it doesn't matter which domain we use
    const url = new URL(
      `http://localhost${navigationInstruction.fragment}?${navigationInstruction.queryString}`
    );

    for (const [param] of Array.from(url.searchParams.entries())) {
      // ignore the overlay params, since we don't want to have a navigation only because an overlay got opened
      if (param.startsWith('fullScreenOverlay_')) {
        url.searchParams.delete(param);
      }
    }

    return url.toString();
  }
}

type Options = {
  routeName: string;
  mainEntityIdParamName: string;
};
