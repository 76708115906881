import { SocketCommandEmitter } from './SocketCommandEmitter';
import {
  EndpointParameter,
  EndpointResult
} from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';

export class ExportCsvModuleEndpoints extends SocketCommandEmitter {
  public async exportUsers(
    request: EndpointParameter<'exportCsvModule', 'exportUsers'>
  ): Promise<EndpointResult<'exportCsvModule', 'exportUsers'>> {
    return this.emitCommand('exportCsvModule', 'exportUsers', request);
  }

  public async exportDefects(
    request: EndpointParameter<'exportCsvModule', 'exportDefects'>
  ): Promise<EndpointResult<'exportCsvModule', 'exportDefects'>> {
    return this.emitCommand('exportCsvModule', 'exportDefects', request);
  }
}
