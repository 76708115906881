import { bindable, autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { EntityName } from 'common/Types/BaseEntities/EntityName';

import { Dialogs } from '../../classes/Dialogs';
import { ReportTypeTemplateService } from '../../classes/EntityManager/entities/ReportType/ReportTypeTemplateService';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SocketService } from '../../services/SocketService';

@autoinject()
export class ReportTypeUpDownload {
  @bindable public reportType: ReportType | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly reportTypePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ReportType];

  protected isConnected: boolean = false;
  protected fileInputElement: HTMLInputElement | null = null;

  constructor(
    private readonly reportTypeTemplateService: ReportTypeTemplateService,
    private readonly socketService: SocketService,
    permissionsService: PermissionsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.reportTypePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ReportType,
        context: this as ReportTypeUpDownload,
        propertyName: 'reportType'
      });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleFileInputChange(): void {
    assertNotNullOrUndefined(this.fileInputElement, 'fileInputElement is null');
    assertNotNullOrUndefined(
      this.reportType,
      'trying to upload report without report type'
    );

    const docxType =
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    const file = this.fileInputElement.files?.[0];
    if (file) {
      if (file.type === docxType) {
        if (this.reportTypePermissionsHandle.canUploadDocxFile)
          this.reportTypeTemplateService.uploadTemplate(this.reportType, file);
        this.fileInputElement.value = '';
      } else {
        void Dialogs.errorDialogTk(
          'serverResponses.uploadDocxTemplateFile.uploadError',
          'serverResponses.uploadDocxTemplateFile.wrongFileType'
        );
      }
    }
  }

  protected handleDownloadTemplateClick(): void {
    assertNotNullOrUndefined(
      this.reportType,
      'trying to download report without report type'
    );

    void this.reportTypeTemplateService.downloadTemplate(this.reportType);
  }
}
