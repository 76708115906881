import { NullCompareMode, SortUtils } from 'common/Utils/SortUtils';
import { AppEntityManager } from '../AppEntityManager';
import { Defect } from './types';
import { PictureCopyService } from '../Picture/PictureCopyService';
import { Picture } from '../Picture/types';
import { autoinject } from 'aurelia-framework';

@autoinject()
export class DefectPictureService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureCopyService: PictureCopyService
  ) {}

  /**
   * Every time a picture is created in a defect,
   * the picture should be copied to the parent thing
   * (if the parent thing does not have a main picture yet).
   *
   * @param pictureIdToDataUrlMap - A map of picture ids to data urls.
   * Since the picture to be copied might not yet have been uploaded, a data url is not present in the entity
   *
   * @returns the picture if one was copied to the parent thing.
   *
   */
  public async ensureThingTitlePicture(
    defect: Defect,
    pictureIdToDataUrlMap: Record<string, string>
  ): Promise<Picture | null> {
    // Get the first created picture of the defect
    const firstDefectPicture = this.entityManager.pictureRepository
      .getByDefectId(defect.id)
      .sort((a, b) =>
        SortUtils.localeCompareFalsyStrings(
          a.takenAt ?? a.createdAt,
          b.takenAt ?? b.createdAt,
          NullCompareMode.FalsyToEnd
        )
      )
      .at(0);
    if (!firstDefectPicture) return null;

    // Abort if the parent thing already has a title picture
    const hasTitlePictures =
      this.entityManager.pictureRepository.getByTitleThingId(
        defect.ownerThingId
      ).length > 0;
    if (hasTitlePictures) return null;

    // Set the title picture of the parent thing
    const thing = this.entityManager.thingRepository.getById(
      defect.ownerThingId
    );
    if (!thing) return null;

    // Retrieve data url of the first defect picture
    // If the picture was taken locally and is not yet uploaded, we have the data url available
    // otherwise we can copy the picture file from the original picture directly
    const dataUrl = this.getDataUrl(firstDefectPicture, pictureIdToDataUrlMap);

    if (dataUrl) {
      return this.pictureCopyService.copyFromDataUrl({
        pictureToCopy: firstDefectPicture,
        dataUrl,
        createPicture: ({ baseData }) => {
          return this.entityManager.pictureRepository.create({
            ...baseData,
            ownerUserGroupId: thing.ownerUserGroupId,
            titleThingId: thing.id,
            ownerThingId: thing.id
          });
        }
      });
    } else {
      return this.pictureCopyService.copy({
        pictureToCopy: firstDefectPicture,
        createPicture: ({ baseData }) => {
          return this.entityManager.pictureRepository.create({
            ...baseData,
            ownerUserGroupId: thing.ownerUserGroupId,
            titleThingId: thing.id,
            ownerThingId: thing.id
          });
        }
      });
    }
  }

  private getDataUrl(
    picture: Picture,
    pictureIdToDataUrlMap: Record<string, string>
  ): string | null {
    const pictureIds = [
      picture.id,
      picture.originalId,
      ...picture.originalIds
    ].filter((i): i is string => !!i);
    for (const id of pictureIds) {
      if (pictureIdToDataUrlMap[id]) {
        return pictureIdToDataUrlMap[id];
      }
    }
    return null;
  }
}
