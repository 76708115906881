export class GeneralFileHelper {
  public static MAX_FILE_SIZE = 100 * 1024 * 1024; // 100 MB

  public static getMainEntityInfo<TId>(generalFileInfo: {
    ownerProcessTaskGroupId: TId | null | undefined;
    ownerProjectId: TId | null | undefined;
    ownerUserGroupId: TId | null | undefined;
  }): MainEntityInfo<TId> {
    if (generalFileInfo.ownerProcessTaskGroupId) {
      return {
        id: generalFileInfo.ownerProcessTaskGroupId,
        folderName: 'ProcessTaskGroup'
      };
    } else if (generalFileInfo.ownerProjectId) {
      return {
        id: generalFileInfo.ownerProjectId,
        folderName: 'project'
      };
    } else if (generalFileInfo.ownerUserGroupId) {
      return {
        id: generalFileInfo.ownerUserGroupId,
        folderName: 'UserGroup'
      };
    } else {
      throw new Error(
        `The general file info passed has no main entity! ${JSON.stringify(generalFileInfo)}`
      );
    }
  }

  public static getFullFileName(
    name: string | null,
    extension: string | null
  ): string {
    const parts = [];

    if (name) {
      parts.push(name);
    }

    if (extension) {
      parts.push(extension);
    }

    return parts.join('.');
  }
}

export type MainEntityInfo<TId> = {
  id: TId;
  folderName: string;
};
