import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { SocketService } from '../../services/SocketService';

import { PermissionHelper } from '../../classes/PermissionHelper';
import { Dialogs } from '../../classes/Dialogs';
import { Utils } from '../../classes/Utils/Utils';
import {
  ModuleName,
  RecordItModuleHelper
} from '../../classes/RecordItModuleHelper';
import { NotificationHelper } from '../../classes/NotificationHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserPasswordService } from '../../classes/EntityManager/entities/User/UserPasswordService';
import { UserEmailService } from '../../classes/EntityManager/entities/User/UserEmailService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { User } from '../../classes/EntityManager/entities/User/types';
import {
  ChangePasswordPanel,
  SetNewPasswordClickedEvent
} from '../../aureliaComponents/change-password-panel/change-password-panel';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ValueWithLabel } from '../../types/ValueWithLabel';
import { SupportedLng, supportedLngs } from '../../classes/TranslationUtils';
import { BiometricAuthenticationService } from '../../services/BiometricAuthenticationService/BiometricAuthenticationService';

@autoinject()
export class EditUserProfileDialog {
  private dialog: RecordItDialog | null = null;

  private user: User | null = null;

  private userGroupsOfUser: Array<UserGroup> = [];

  private isConnected = false;

  protected warningText = '';

  private newEmail = '';

  protected changePasswordPanelLoading = false;

  private changePasswordPanelViewModel: ChangePasswordPanel | null = null;

  private subscriptionManager: SubscriptionManager;

  protected DateUtils = DateUtils;

  public static async open(): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open();
  }

  constructor(
    private i18n: I18N,
    private entityManager: AppEntityManager,
    private socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService,
    private userPasswordService: UserPasswordService,
    private userEmailService: UserEmailService,
    private currentUserService: CurrentUserService,
    protected biometricAuthService: BiometricAuthenticationService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  private open(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateUser.bind(this)
      )
    );
    this.updateUser();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateUserGroupsOfUser.bind(this)
    );

    this.dialog?.open();
  }

  private close(): void {
    this.subscriptionManager.disposeSubscriptions();

    this.dialog?.close();
  }

  private updateUserGroupsOfUser(): void {
    if (this.user) {
      this.userGroupsOfUser =
        this.entityManager.userGroupRepository.getUserGroupsOfUser(this.user);
    } else {
      this.userGroupsOfUser = [];
    }
  }

  private updateUser(): void {
    this.user = this.currentUserService.getCurrentUser();
    this.newEmail = this.user ? this.user.email : '';
    this.updateUserGroupsOfUser();
  }

  protected handleUserChanged(): void {
    assertNotNullOrUndefined(
      this.user,
      'cannot change user if it is not available'
    );
    this.entityManager.userRepository.update(this.user);
  }

  protected handleEmailChanged(): void {
    const user = this.user;
    assertNotNullOrUndefined(user, 'cannot change email without a user');

    if (!this.isEmailValid || (user && this.newEmail === user.email)) return;

    Dialogs.yesNoDialog('Wollen Sie die E-Mail-Adresse ändern?')
      .then(() => {
        this.userEmailService.sendEmailVerification(user, this.newEmail);
        this.newEmail = user.email;
      })
      .catch(() => {
        this.newEmail = user.email;
      });
  }

  protected async handleSetNewPasswordClick(
    event: SetNewPasswordClickedEvent
  ): Promise<void> {
    assertNotNullOrUndefined(
      this.user,
      'cannot set new password without a user'
    );

    this.changePasswordPanelLoading = true;
    this.warningText = '';

    const response = await this.userPasswordService.updatePassword(
      this.user,
      event.detail.oldPassword,
      event.detail.newPassword
    );

    if (response.success) {
      NotificationHelper.notifySuccess('Passwort erfolgreich geändert!');
      if (this.changePasswordPanelViewModel) {
        this.changePasswordPanelViewModel.closeChangePasswordPanel();
        this.changePasswordPanelViewModel.resetFields();
      }
    } else {
      this.warningText = response.status
        ? this.i18n.tr(`serverResponses.${response.status}`)
        : 'Passwort konnte nicht geändert werden!';
    }

    this.changePasswordPanelLoading = false;
  }

  protected async handleRequestAccountDeletion(): Promise<void> {
    assertNotNullOrUndefined(
      this.user,
      'cannot request account deletion without a user'
    );

    try {
      await Dialogs.yesNoDialogTk(
        'dialogs.editUserProfileDialog.requestAccountDeletionConfirmationTitle',
        {},
        'dialogs.editUserProfileDialog.requestAccountDeletionConfirmationText'
      );
    } catch (error) {
      if (error instanceof Error && error.message === 'DialogCanceled') return;
      throw error;
    }

    Dialogs.waitDialog();

    this.socketService.requestAccountDeletion(this.user.id, (response) => {
      if (response.success) {
        Dialogs.timedSuccessDialogTk(
          'dialogs.editUserProfileDialog.accountDeletionSuccessfullyRequested'
        );
      } else {
        void Dialogs.errorDialogTk(
          'general.error',
          `serverResponses.requestAccountDeletion.${response.status}`
        );
      }
    });
  }

  @computedFrom('newEmail')
  protected get isEmailValid(): boolean {
    return Utils.validateEmail(this.newEmail);
  }

  protected get supportedLanguages(): Array<ValueWithLabel<SupportedLng>> {
    return supportedLngs.map((lng) => ({
      label: `language.${lng}`,
      value: lng
    }));
  }

  protected getSubscriptionInfos(
    user: User
  ): Array<{ label: string; subscriptionDate: string | boolean | null }> {
    const infos: Array<{
      label: string;
      subscriptionDate: string | boolean | null;
    }> = [];

    for (const moduleName of Object.keys(
      RecordItModuleHelper.MODULE_NAME_TO_CONFIGURATION_MAP
    ) as Array<ModuleName>) {
      if (moduleName === ModuleName.GENERAL) {
        continue;
      }

      const rawValue = PermissionHelper.getRawPermissionValue(
        user,
        PermissionHelper.getPermissionNameForModuleName(moduleName)
      );

      infos.push({
        label:
          RecordItModuleHelper.getShortDisplayNameForModuleName(moduleName),
        subscriptionDate: rawValue
      });
    }

    return infos;
  }

  protected subscriptionIsValid(subscription: string): boolean {
    return PermissionHelper.subscriptionIsValid(subscription);
  }

  protected dialogClosed(): void {
    if (this.changePasswordPanelViewModel)
      this.changePasswordPanelViewModel.resetFields();
    this.warningText = '';
    this.changePasswordPanelLoading = false;
  }
}
