import { autoinject, bindable } from 'aurelia-framework';

import {
  MoreButtonChoice,
  MoreButtonFileChangedEvent
} from '../../../aureliaComponents/more-button/more-button';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { DefectCsvImporterService } from '../../../services/DefectCsvImporterService';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

/**
 * A small bar containing actions for the defects below, such as:
 *  - Creating a new defects.
 *  - Having a toggleable filter icon.
 *
 * @event add-icon-clicked triggerd when the 'plus sign' icon is clicked.
 */
@autoinject()
export class GalleryThingDefectOverviewActionBar {
  /**
   * Controls whether the filter icon is displayed as active or inactive.
   */
  @bindable public isFilterIconActive: boolean = true;

  @bindable public thing: Thing | null = null;

  protected readonly moreButtonChoices: Array<MoreButtonChoice> = [
    {
      name: 'import-defects-from-csv',
      fileInputAccept: 'text/plain,text/comma-separated-values,.csv',
      isFileInput: true,
      labelTk: 'galleryThing.defectImport.importFromCsvFile',
      iconClass: 'fal fa-file-csv'
    }
  ];

  private element: HTMLElement;

  @subscribableLifecycle()
  protected thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(
    element: Element,
    private readonly defectCsvImporterService: DefectCsvImporterService,
    permissionsService: PermissionsService
  ) {
    this.element = element as HTMLElement;
    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Thing,
        context: this,
        expression: 'thing'
      });
  }

  protected handleAddIconClicked(): void {
    DomEventHelper.fireEvent<AddIconClicked>(this.element, {
      name: 'add-icon-clicked',
      detail: null
    });
  }

  protected async handleImportFromCsvClicked(
    event: MoreButtonFileChangedEvent<'import-defects-from-csv'>
  ): Promise<void> {
    const inputElement = event.detail.target as HTMLInputElement;
    if (!inputElement.files || !inputElement.files[0] || !this.thing) return;

    await this.defectCsvImporterService.importDefectsFromCsvFile(
      inputElement.files[0],
      this.thing
    );
    inputElement.value = '';
  }
}

type AddIconClicked = NamedCustomEvent<'add-icon-clicked', null>;
