import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { WebSocketHandlerError } from 'common/WebSocketEndpoints/WebSocketHandlerError/WebSocketHandlerError';
import { EndpointResult } from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';
import { CheckedChangedEvent } from '../../aureliaComponents/expandable-container/expandable-container';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { SocketService } from '../../services/SocketService';
import { Logger } from '../../classes/Logger/Logger';
import { isConnected } from '../../hooks/dependencies';
import { configureHooks } from '../../hooks/configureHooks';
import { computed } from '../../hooks/computed';
import { NotificationHelper } from '../../classes/NotificationHelper';

@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class CopyThingDialog {
  public static async open(options: CopyThingDialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private options: Required<CopyThingDialogOptions> | null = null;

  protected loading = false;
  protected dialog: RecordItDialog | null = null;
  protected copyErrorTk: string | null = null;

  constructor(
    private readonly i18n: I18N,
    private readonly socketService: SocketService
  ) {}

  public open(options: CopyThingDialogOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't CopyThingDialog.open without dialog"
    );

    this.copyErrorTk = null;

    if (this.options) {
      Object.assign(this.options, options);
    } else {
      this.options = {
        ...this.getDefaultOptionsWithoutThingId(),
        ...options
      };
    }

    this.dialog.open();
  }

  protected handleDialogClosed(): void {}

  protected handleAllSelectedCheckedChanged(event: CheckedChangedEvent): void {
    assertNotNullOrUndefined(
      this.options,
      "can't CopyThingDialog.handleAllSelectedCheckedChanged without options"
    );

    this.options.copyTags = event.detail.checked;

    this.options.copyRegions = event.detail.checked;
    this.options.copyRegionProperties = event.detail.checked;
    this.options.copyRegionPropertyValues = event.detail.checked;

    this.options.copyTitleThingPictures = event.detail.checked;
    this.options.copyGlobalThingPictures = event.detail.checked;

    this.options.copyThingProperties = event.detail.checked;
    this.options.copyThingPropertyValues = event.detail.checked;
  }

  @computedFrom(
    'options.copyTags',
    'options.copyRegions',
    'options.copyRegionProperties',
    'options.copyRegionPropertyValues',
    'options.copyTitleThingPictures',
    'options.copyGlobalThingPictures',
    'options.copyThingProperties',
    'options.copyThingPropertyValues'
  )
  protected get allSelected(): boolean {
    if (!this.options) {
      return false;
    }

    return (
      this.options.copyTags &&
      this.options.copyRegions &&
      this.options.copyRegionProperties &&
      this.options.copyRegionPropertyValues &&
      this.options.copyTitleThingPictures &&
      this.options.copyGlobalThingPictures &&
      this.options.copyThingProperties &&
      this.options.copyThingPropertyValues
    );
  }

  protected handleCloseDialogClick(): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't CopyThingDialog.handleCloseDialogClick without dialog"
    );

    this.dialog.close();
  }

  protected async handleCopyThingClick(): Promise<void> {
    assertNotNullOrUndefined(
      this.options,
      "can't CopyThingDialog.handleCopyThingClick without options"
    );

    this.loading = true;
    this.copyErrorTk = null;

    try {
      const result =
        await this.socketService.copyEntitiesModuleEndpoints.copyThing({
          thingId: this.options.thingId,
          newThingName: this.options.newName,
          copyTitleThingPictures: this.options.copyTitleThingPictures,
          copyGlobalThingPictures: this.options.copyGlobalThingPictures,
          copyThingProperties: this.options.copyThingProperties,
          copyThingPropertyValues: this.options.copyThingPropertyValues,
          copyTags: this.options.copyTags,
          copyRegions: this.options.copyRegions,
          copyRegionProperties: this.options.copyRegionProperties,
          copyRegionPropertyValues: this.options.copyRegionPropertyValues
        });

      this.displayNotAllEntitiesCopiedNotificationIfNecessary(result);

      this.handleCloseDialogClick();
    } catch (error) {
      if (error instanceof WebSocketHandlerError) {
        this.copyErrorTk = `WebsocketErrors.${error.type}`;
      } else {
        this.copyErrorTk = 'WebsocketErrors.ERROR_UNKNOWN';
        Logger.logError({ error });
      }
    } finally {
      this.loading = false;
    }
  }

  private getDefaultOptionsWithoutThingId(): Omit<
    Required<CopyThingDialogOptions>,
    'thingId'
  > {
    return {
      newName: '',

      copyTags: false,

      copyRegions: false,
      copyRegionProperties: false,
      copyRegionPropertyValues: false,

      copyTitleThingPictures: false,
      copyGlobalThingPictures: false,

      copyThingProperties: false,
      copyThingPropertyValues: false
    };
  }

  private displayNotAllEntitiesCopiedNotificationIfNecessary(
    result: EndpointResult<'copyEntitiesModule', 'copyThing'>
  ): void {
    const skippedEntitiesCountEntries = Array.from(
      Object.entries(result.entityNameToSkippedDueToLackingPermissionsCount)
    );
    const sumOfSkippedEntities = skippedEntitiesCountEntries.reduce(
      (sum, [_, skippedCounte]) => {
        return sum + skippedCounte;
      },
      0
    );

    if (sumOfSkippedEntities === 0) {
      return;
    }

    const skippedEntitiesDetail = skippedEntitiesCountEntries
      .map(([entityName, skippedCount]) => {
        return `${skippedCount} ${this.i18n.tr(`models.${entityName}`, { count: skippedCount })}`;
      })
      .join('; ');

    NotificationHelper.notifyNeutral(
      this.i18n.tr('dialogs.copyThingDialog.notAllEntitiesCopied', {
        skippedEntitiesDetail
      })
    );
  }

  @computed(isConnected())
  protected get isConnected(): boolean {
    return this.socketService.isConnected();
  }
}

export type CopyThingDialogOptions = {
  thingId: string;
  newName: string;
  copyTags?: boolean;
  copyRegions?: boolean;
  copyRegionProperties?: boolean;
  copyRegionPropertyValues?: boolean;
  copyThingProperties?: boolean;
  copyThingPropertyValues?: boolean;
  copyTitleThingPictures?: boolean;
  copyGlobalThingPictures?: boolean;
};
