import { Vector } from 'common/Geometry/Vector';
import {
  PathCommand,
  IMoveAbsoluteCommand,
  IMoveRelativeCommand,
  CommandType,
  ILineAbsoluteCommand,
  ILineRelativeCommand,
  CommandIdentifier,
  ICubicAbsoluteCommand,
  ICubicRelativeCommand,
  ICloseAbsoluteCommand,
  ICloseRelativeCommand
} from './PathCommand';
import { assertNotNullOrUndefined } from 'common/Asserts';

export class PathCommandParser {
  public parse(str: string): Array<PathCommand> {
    const rawCommands = this.parseString(str);
    return rawCommands.map((rawCommand) => {
      return this.parseRawCommand(rawCommand);
    });
  }

  private parseString(str: string): Array<IRawCommand> {
    const commands: Array<IRawCommand> = [];
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    let currentCommand: IRawCommand | null = null;

    for (let i = 0; i < str.length; i++) {
      const value = str[i];
      assertNotNullOrUndefined(value, `no value exists at index ${i}`);

      if (alphabet.indexOf(value.toLocaleLowerCase()) >= 0) {
        if (currentCommand) {
          commands.push(currentCommand);
        }

        currentCommand = { command: value, options: '' };
      } else if (currentCommand) {
        currentCommand.options += value;
      } else {
        console.warn(`illegal character "${value}" at index ${i} in "${str}"`);
      }
    }

    if (currentCommand) {
      commands.push(currentCommand);
    }

    return commands;
  }

  private parseRawCommand(rawCommand: IRawCommand): PathCommand {
    switch (rawCommand.command) {
      case 'M':
        return this.parseAbsoluteMoveCommand(rawCommand);

      case 'm':
        return this.parseRelativeMoveCommand(rawCommand);

      case 'L':
        return this.parseAbsoluteLineCommand(rawCommand);

      case 'l':
        return this.parseRelativeLineCommand(rawCommand);

      case 'C':
        return this.parseAbsoluteCubicCommand(rawCommand);

      case 'c':
        return this.parseRelativeCubicCommand(rawCommand);

      case 'Z':
        return this.parseAbsoluteCloseCommand();

      case 'z':
        return this.parseRelativeCloseCommand();

      default:
        throw new Error(
          `unsupported command "${rawCommand.command}${rawCommand.options}"`
        );
    }
  }

  private parseAbsoluteMoveCommand(
    rawCommand: IRawCommand
  ): IMoveAbsoluteCommand {
    return {
      identifier: CommandIdentifier.M,
      type: CommandType.Absolute,
      point: this.parseSinglePointCommandOptions(rawCommand.options)
    };
  }

  private parseRelativeMoveCommand(
    rawCommand: IRawCommand
  ): IMoveRelativeCommand {
    return {
      identifier: CommandIdentifier.m,
      type: CommandType.Relative,
      point: this.parseSinglePointCommandOptions(rawCommand.options)
    };
  }

  private parseAbsoluteLineCommand(
    rawCommand: IRawCommand
  ): ILineAbsoluteCommand {
    return {
      identifier: CommandIdentifier.L,
      type: CommandType.Absolute,
      point: this.parseSinglePointCommandOptions(rawCommand.options)
    };
  }

  private parseRelativeLineCommand(
    rawCommand: IRawCommand
  ): ILineRelativeCommand {
    return {
      identifier: CommandIdentifier.l,
      type: CommandType.Relative,
      point: this.parseSinglePointCommandOptions(rawCommand.options)
    };
  }

  private parseAbsoluteCubicCommand(
    rawCommand: IRawCommand
  ): ICubicAbsoluteCommand {
    const points = this.parseTriplePointCommandOptions(rawCommand.options);

    return {
      identifier: CommandIdentifier.C,
      type: CommandType.Absolute,
      point: points[2],
      cp1: points[0],
      cp2: points[1]
    };
  }

  private parseRelativeCubicCommand(
    rawCommand: IRawCommand
  ): ICubicRelativeCommand {
    const points = this.parseTriplePointCommandOptions(rawCommand.options);

    return {
      identifier: CommandIdentifier.c,
      type: CommandType.Relative,
      point: points[2],
      cp1: points[0],
      cp2: points[1]
    };
  }

  private parseAbsoluteCloseCommand(): ICloseAbsoluteCommand {
    return {
      identifier: CommandIdentifier.Z,
      type: CommandType.Absolute
    };
  }

  private parseRelativeCloseCommand(): ICloseRelativeCommand {
    return {
      identifier: CommandIdentifier.z,
      type: CommandType.Relative
    };
  }

  private parseSinglePointCommandOptions(options: string): Vector {
    const result = /([0-9]+(?:.[0-9]+)?)[, ]([0-9]+(?:.[0-9]+)?)(?: |,|$)/.exec(
      options
    ) as (RegExpExecArray & [string, string, string]) | null;

    if (!result) {
      throw new Error(`couldn't parse "${options}" as single point options`);
    }

    return new Vector(parseFloat(result[1]), parseFloat(result[2]));
  }

  private parseTriplePointCommandOptions(
    options: string
  ): [Vector, Vector, Vector] {
    const result =
      /([0-9]+(?:.[0-9]+)?)[, ]([0-9]+(?:.[0-9]+)?)[, ]([0-9]+(?:.[0-9]+)?)[, ]([0-9]+(?:.[0-9]+)?)[, ]([0-9]+(?:.[0-9]+)?)[, ]([0-9]+(?:.[0-9]+)?)(?: |,|$)/.exec(
        options
      ) as
        | (RegExpExecArray &
            [string, string, string, string, string, string, string])
        | null;

    if (!result) {
      throw new Error(`couldn't parse "${options}" as tripple point options`);
    }

    return [
      new Vector(parseFloat(result[1]), parseFloat(result[2])),
      new Vector(parseFloat(result[3]), parseFloat(result[4])),
      new Vector(parseFloat(result[5]), parseFloat(result[6]))
    ];
  }
}

interface IRawCommand {
  command: string;
  options: string;
}
