import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { EmbeddedCamera } from '../../aureliaComponents/embedded-camera/embedded-camera';
import { Dialogs } from '../../classes/Dialogs';
import { SelectCoordinatesOnPositionedPictureOverlay } from '../../dialogs/select-coordinates-on-positioned-picture-overlay/select-coordinates-on-positioned-picture-overlay';
import { SelectCoordinatesOnMapDialog } from '../../dialogs/select-coordinates-on-map-dialog/select-coordinates-on-map-dialog';
import { CoordinateHelper } from '../../classes/CoordinateHelper';
import {
  PermissionBindingService,
  PermissionBindingHandle
} from '../../services/PermissionBindingService';
import { GalleryThingPictureCreatorService } from '../../services/GalleryThingPictureCreatorService';
import { SelectThingPictureDialog } from '../../dialogs/select-thing-picture-dialog/select-thing-picture-dialog';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { CirclePreloader } from '../../aureliaComponents/circle-preloader/circle-preloader';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { TRegionSelectEvent } from '../../regionComponents/region-select/region-select';
import { TSketchFinishedEvent } from '../../aureliaComponents/data-url-sketch-button/data-url-sketch-button';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Tag, ThingTag } from '../../classes/EntityManager/entities/Tag/types';
import {
  Picture,
  PictureCoords
} from '../../classes/EntityManager/entities/Picture/types';
import { PropertyCreationBaseData } from '../../classes/EntityManager/entities/Property/types';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { CoordsFromPositionedPictureInfo } from '../../../../common/src/Types/Entities/Picture/PictureDto';
import { GalleryThingEditEntryDialog } from '../gallery-thing-edit-entry-dialog/gallery-thing-edit-entry-dialog';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';

/**
 * this is meant to be a single global instance!
 * also supports the instance demander
 */
@autoinject()
export class GalleryThingPictureCaptureWidget {
  private static readonly lastStartOptionsStorageKey =
    'GalleryThingPictureCaptureWidget::lastStartOptions';

  private static readonly selectedTagIdsStorageKey =
    'GalleryThingPictureCaptureWidget::selectedTagIds';

  private options: GalleryThingPictureCaptureWidgetStartOptions | null = null;

  private pictureData: PictureData | null = null;

  protected canUseRegionExtension = false;
  protected useCategorizedTags = false;

  protected dialog: RecordItDialog | null = null;

  protected circlePreloader: CirclePreloader | null = null;

  private coords: PictureCoords | null = null;
  private coordsFromPositionedPictureInfo: CoordsFromPositionedPictureInfo | null =
    null;

  protected availableThingTags: Array<ThingTag> = [];

  private pictureTags: Array<Tag> = [];

  private saveSelectedTags = false;
  protected saveSelectedRegion = false;

  private pictureDescription: string | null = null;

  private regionId: string | null = null;

  private editEntryDialogInsteadOfRFM = false;

  private subscriptionManager: SubscriptionManager;

  private permissionBindingHandle: PermissionBindingHandle;

  constructor(
    private i18n: I18N,
    private entityManager: AppEntityManager,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private galleryThingPictureCreatorService: GalleryThingPictureCreatorService,
    subManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService
  ) {
    this.subscriptionManager = subManagerService.create();
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseRegionExtension: 'canUseRegionExtension'
      }
    });
  }

  protected attached(): void {
    if (EmbeddedCamera.hasLastDataUrlPicture()) {
      void this.handleDataUrlPictureAvailable();
    }

    this.subscriptionManager.subscribeToEvent(
      'embedded-camera:data-url-picture-available',
      this.handleDataUrlPictureAvailable.bind(this)
    );
    this.subscriptionManager.subscribeToEvent(
      'embedded-camera:camera-closed',
      this.handleCameraClosed.bind(this)
    );
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'general.editEntryInsteadOfRFM',
        (setting) => {
          this.editEntryDialogInsteadOfRFM = setting;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'via.useCategorizedTags',
        (setting) => {
          this.useCategorizedTags = setting;
        }
      )
    );
    this.permissionBindingHandle.subscribe();
  }

  protected detached(): void {
    this.permissionBindingHandle.unsubscribe();
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateAvailableThingTags(): void {
    if (this.options) {
      this.availableThingTags = this.entityManager.tagRepository.getByThingId(
        this.options.thing.id
      );
    } else {
      this.availableThingTags = [];
    }
  }

  public start(options: GalleryThingPictureCaptureWidgetStartOptions): void {
    this.regionId = options.regionId ?? null;
    void DataStorageHelper.setItem(
      GalleryThingPictureCaptureWidget.lastStartOptionsStorageKey,
      options
    );
    void EmbeddedCamera.capturePicture({});
  }

  private async handleDataUrlPictureAvailable(): Promise<void> {
    assertNotNullOrUndefined(
      this.dialog,
      'Cannot handle the pictureDataUrl if no dialog ref exists'
    );

    this.options = await DataStorageHelper.getItem(
      GalleryThingPictureCaptureWidget.lastStartOptionsStorageKey
    );
    if (!this.options) {
      return; // this callback was not for us
    }

    this.updateAvailableThingTags();

    const uri = EmbeddedCamera.getLastDataUriPictureOnce();
    if (!uri) {
      void Dialogs.errorDialog(
        'Fehler',
        'Bei dem Erstellen des Bildes ist ein Fehler aufgetreten.'
      );
      this.deleteDatabaseData();
    } else {
      this.pictureData = {
        originalDataUrl: uri
      };
      await this.setDefaultCoordinates();
      void this.restoreSelectedTags();
      this.dialog.open();
    }
  }

  private handleCameraClosed(): void {
    this.deleteDatabaseData();
  }

  protected async handleAcceptButtonClicked(): Promise<void> {
    assertNotNullOrUndefined(
      this.options,
      '[GalleryThingPictureCaptureWidget] no options given!'
    );
    assertNotNullOrUndefined(
      this.circlePreloader,
      'Cannot handleAcceptButtonClicked without a circlePreloader ref'
    );

    const pictureTagIds = this.pictureTags.map((tag) => tag.id);
    const picture = await this.galleryThingPictureCreatorService.createPicture(
      {
        thing: this.options.thing,
        regionId: this.regionId,
        properties: this.options.properties,
        description: this.pictureDescription,
        coords: this.coords,
        coordsFromPositionedPictureInfo: this.coordsFromPositionedPictureInfo,
        tagIds: pictureTagIds
      },
      this.options.projectId
    );

    let entry: Entry | null = null;
    if (picture?.entry && this.editEntryDialogInsteadOfRFM) {
      entry = await this.entityManager.entryRepository.getById(picture.entry);
    }

    this.createPictureFiles(picture);

    this.circlePreloader.start();

    setTimeout(() => {
      // preloader to make the user feel better
      assertNotNullOrUndefined(
        this.circlePreloader,
        'Cannot reset preloader without a circlePreloader ref'
      );
      assertNotNullOrUndefined(
        this.dialog,
        'Cannot close dialog without a dialog ref'
      );
      assertNotNullOrUndefined(
        this.options,
        'Cannot open GalleryThingEditEntryDialog without options'
      );
      this.circlePreloader.reset();
      this.dialog.close();
      if (!entry) {
        void this.capturePicture();
      } else {
        void GalleryThingEditEntryDialog.open({
          entry: entry,
          thingId: this.options.thing.id,
          userGroupId: this.options.thing.ownerUserGroupId
        });
      }
    }, 1000);
  }

  private createPictureFiles(picture: Picture): void {
    assertNotNullOrUndefined(
      this.pictureData,
      "can't GalleryThingPictureCaptureWidget.createPictureFiles without pictureData"
    );

    this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
      picture,
      this.pictureData.originalDataUrl
    );

    if (this.pictureData.editedDataUrl) {
      this.savePictureFileDataUrlService.saveEditedPictureDataUrl(
        picture,
        this.pictureData.editedDataUrl
      );
    }

    if (this.pictureData.sketchDataUrl) {
      this.savePictureFileDataUrlService.saveSketchPictureDataUrl(
        picture,
        this.pictureData.sketchDataUrl
      );
    }
  }

  protected async handleDeclineButtonClicked(): Promise<void> {
    assertNotNullOrUndefined(
      this.dialog,
      'Cannot handleDeclineButtonClicked without a dialog ref'
    );

    this.dialog.close();
    await this.capturePicture();
  }

  private async capturePicture(): Promise<void> {
    this.storeSelectedTags();
    this.reset();
    await EmbeddedCamera.capturePicture({});
  }

  private reset(): void {
    this.options = null;
    this.pictureData = null;
    this.coords = null;
    this.pictureDescription = null;
    this.pictureTags = [];
    this.availableThingTags = [];
  }

  private deleteDatabaseData(): void {
    void DataStorageHelper.removeItem(
      GalleryThingPictureCaptureWidget.lastStartOptionsStorageKey
    );
    void DataStorageHelper.removeItem(
      GalleryThingPictureCaptureWidget.selectedTagIdsStorageKey
    );
  }

  protected handleMarkPictureOnMapClick(): void {
    void SelectCoordinatesOnMapDialog.open({
      lat: this.coords ? this.coords.latitude : null,
      long: this.coords ? this.coords.longitude : null,
      onCoordinateSelected: (lat, long) => {
        this.coords = {
          latitude: lat,
          longitude: long
        };
        this.coordsFromPositionedPictureInfo = null;
      }
    });
  }

  protected async handleMarkPictureOnThingPictureClick(): Promise<void> {
    assertNotNullOrUndefined(
      this.options,
      "Can't mark picture without options"
    );

    const options = this.options;
    const thingPictures =
      this.entityManager.pictureRepository.getByGalleryThingId(
        options.thing.id
      );
    const thingPicture: Picture | null =
      thingPictures.length === 1
        ? (thingPictures[0] ?? null)
        : await new Promise((res) => {
            void SelectThingPictureDialog.open({
              thingId: options.thing.id,
              onDialogClosed: (picture) => res(picture)
            });
          });

    if (!thingPicture) return;

    void SelectCoordinatesOnPositionedPictureOverlay.open({
      picture: thingPicture,
      oldPosition: this.coords,
      onCoordinateSelected: ({ coords, coordsFromPositionedPictureInfo }) => {
        this.coords = coords;
        this.coordsFromPositionedPictureInfo = coordsFromPositionedPictureInfo;
      },
      pictureNotPositionedText: this.i18n.tr(
        'galleryThing.thingPictureIsNotPositioned'
      )
    });
  }

  protected handleTagCreated(newTagName: string): void {
    if (!this.options) {
      return;
    }

    const thing = this.entityManager.thingRepository.getById(
      this.options.thing.id
    );
    if (thing) {
      const newTag = this.entityManager.tagRepository.getOrCreateThingTag({
        name: newTagName,
        thingId: thing.id,
        ownerUserGroupId: thing.ownerUserGroupId
      });
      this.pictureTags.push(newTag as Tag);
    }
  }

  protected handleRegionChanged(event: TRegionSelectEvent): void {
    this.regionId = event.detail.value;
  }

  private handleSketchFinished(event: TSketchFinishedEvent): void {
    assertNotNullOrUndefined(
      this.pictureData,
      "can't GalleryThingPictureCaptureWidget.handleSketchFinished without pictureData"
    );

    this.pictureData.sketchDataUrl = event.detail.sketchDataUrl;
    this.pictureData.editedDataUrl = event.detail.editedDataUrl;
  }

  private async setDefaultCoordinates(): Promise<void> {
    const coords = await CoordinateHelper.getClientCoordinatesWithDialog();
    if (coords && coords.longitude && coords.latitude) {
      this.coords = {
        latitude: coords.latitude,
        longitude: coords.longitude
      };
      this.coordsFromPositionedPictureInfo = null;
    }
  }

  private storeSelectedTags(): void {
    const tags = this.saveSelectedTags ? this.pictureTags : null;
    const tagIds = tags ? tags.map((t) => t.id) : null;
    void DataStorageHelper.setItem(
      GalleryThingPictureCaptureWidget.selectedTagIdsStorageKey,
      tagIds
    );
  }

  private async restoreSelectedTags(): Promise<void> {
    const tagIds: Array<string> | null = await DataStorageHelper.getItem(
      GalleryThingPictureCaptureWidget.selectedTagIdsStorageKey
    );
    if (tagIds == null || tagIds.constructor !== Array) {
      this.saveSelectedTags = false;
      this.pictureTags = [];
      return;
    }

    const tags: Array<Tag> = [];
    tagIds.forEach((id) => {
      const tag = this.entityManager.tagRepository.getById(id);
      if (tag) {
        tags.push(tag);
      }
    });

    this.pictureTags = tags;
    this.saveSelectedTags = true;
  }

  protected roundCoordinate(coordinate: number): string {
    return CoordinateHelper.roundCoordinate(coordinate);
  }

  public static async start(
    options: GalleryThingPictureCaptureWidgetStartOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().start(options);
  }
}

export type GalleryThingPictureCaptureWidgetStartOptions = {
  thing: Thing;
  projectId?: string;
  regionId?: string | null;
  properties?: Array<PropertyCreationBaseData>;
};

export type PictureData = {
  originalDataUrl: string;
  sketchDataUrl?: string;
  editedDataUrl?: string;
};
