import { computedFrom } from 'aurelia-framework';

import { ReportType } from '../../../classes/EntityManager/entities/ReportType/types';
import { ReportTypeAdapter } from '../EntityAdapter/ReportTypeAdapter/ReportTypeAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ReportTypePermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ReportTypeAdapter>,
  ReportType
> {
  @computedFrom('combinedRevision')
  public get canUploadDocxFile(): boolean {
    return this.checkAdapterSpecificPermissionByName('canUploadDocxFile');
  }
}
