import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { DateUtils } from '../../../DateUtils';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ThingDto } from './ThingDto';

export function createThingEntityInfo<TEntity extends ThingDto<string, string>>(
  options: CreateEntityInfoOptions<ThingDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.Thing,
  TEntity
> {
  return {
    entityName: EntityName.Thing,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ThingDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        customId: {
          name: 'customId',
          defaultValue: null
        },
        thingGroupId: {
          name: 'thingGroupId',
          defaultValue: null,
          ref: EntityName.ThingGroup
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        description: {
          name: 'description',
          defaultValue: null
        },
        archived: {
          name: 'archived',
          defaultValue: false
        },
        status: {
          name: 'status',
          defaultValue: null
        },
        synchronizedExternally: {
          name: 'synchronizedExternally',
          defaultValue: false
        },
        created: {
          name: 'created',
          defaultValue: DateUtils.getCurrentTimestamp.bind(DateUtils)
        },
        lastEditedByUserId: {
          name: 'lastEditedByUserId',
          defaultValue: null
        },
        usergroup: {
          name: 'usergroup',
          ref: EntityName.UserGroup,
          cascadeDeleteLocally: true
        },
        type: {
          name: 'type',
          defaultValue: null
        },
        latitude: {
          name: 'latitude',
          defaultValue: null
        },
        longitude: {
          name: 'longitude',
          defaultValue: null
        },
        thing_type: {
          name: 'thing_type',
          defaultValue: null,
          ref: EntityName.ThingType
        },
        report_type: {
          name: 'report_type',
          defaultValue: null,
          ref: EntityName.ReportType
        },
        tagCategories: {
          name: 'tagCategories',
          innerInfo: [
            {
              name: {
                name: 'name'
              },
              displayName: {
                name: 'displayName'
              }
            }
          ],
          defaultValue: () => []
        }
      },
      options.additionalFieldInfos
    )
  };
}
