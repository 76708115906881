import {
  autoinject,
  bindable,
  computedFrom,
  observable
} from 'aurelia-framework';

import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';

import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';

/**
 * A small bar containing actions for the pictures below, such as:
 *  - Creating a new date tab.
 *  - Having a toggleable filter icon.
 *
 * @event {NewDateButtonClickedEvent} new-date-button-clicked Fired if the "Create Date" button is clicked.
 */
@autoinject()
export class GalleryThingPictureOverviewActionBar {
  @bindable public thing: Thing | null = null;

  /**
   * Controls whether the filter icon is displayed as active or inactive.
   */
  @bindable public isFilterIconActive: boolean = true;

  @observable protected projectCreationExpanded: boolean = false;

  /**
   * The currently selected date in the date-time picker.
   * null if no date has been selected yet.
   */
  protected dateString: string | null = null;

  private element: HTMLElement;

  @subscribableLifecycle()
  protected thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(element: Element, permissionsService: PermissionsService) {
    this.element = element as HTMLElement;
    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Thing,
        context: this,
        expression: 'thing'
      });
  }

  // /////////// CLICK HANDLERS /////////////

  protected handleNewDateButtonClick(): void {
    const date = this.date;
    assertNotNullOrUndefined(
      date,
      'cannot handle new date button click without a date'
    );

    DomEventHelper.fireEvent<NewDateButtonClickedEvent>(this.element, {
      name: 'new-date-button-clicked',
      detail: { date: date }
    });
    this.projectCreationExpanded = false;
  }

  // /////////// GETTERS /////////////

  /**
   * Disables the "Create Date" button if one of these checks returns false:
   *  - date is undefined.
   *  - date is not parsable by `DateUtils.parseDateFromIsoString`.
   */
  @computedFrom('date')
  protected get isNewDateButtonDisabled(): boolean {
    const date = this.date;
    if (!date) {
      return true;
    } else {
      return false;
    }
  }

  @computedFrom('dateString')
  private get date(): Date | null {
    if (!this.dateString) return null;
    return DateUtils.parseDateFromIsoString(this.dateString);
  }
}

export type NewDateButtonClickedEvent = NamedCustomEvent<
  'new-date-button-clicked',
  { date: Date }
>;
