import { autoinject } from 'aurelia-framework';

import { ThingAndThingGroupNameService } from '../../services/ThingAndThingGroupNameService/ThingAndThingGroupNameService';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SocketService } from '../../services/SocketService';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { Dialogs } from '../../classes/Dialogs';
import { FileDownloadService } from '../../services/FileDownloadService';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { GlobalMenu } from '../../aureliaComponents/global-menu/global-menu';
import { WebSocketHandlerErrorUtils } from '../../classes/Utils/WebSocketHandlerErrorUtils';

@autoinject()
export class EditViaWorkerDefects {
  protected readonly moreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'generalPages.editViaWorkerDefects.exportToCsv',
      name: 'export-defects-to-csv',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'isOffline'
    }
  ];

  protected isMobile = false;
  protected isOffline = true;

  protected filteredDefects: Array<Defect> = [];

  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly thingAndThingGroupNameService: ThingAndThingGroupNameService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly socketService: SocketService,
    private readonly fileDownloadService: FileDownloadService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    void this.thingAndThingGroupNameService.refreshCache();

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOffline = !isConnected;
      })
    );

    GlobalMenu.takeControl(this, {
      visible: this.isMobile,
      choices: this.moreButtonChoices,
      selectedCallbacks: {
        'export-defects-to-csv': () => {
          void this.handleExportDefectsToCsvClicked();
        }
      }
    });
  }

  protected detached(): void {
    GlobalMenu.releaseControl(this);
    this.subscriptionManager.disposeSubscriptions();
  }

  protected async handleExportDefectsToCsvClicked(): Promise<void> {
    const selectedDefectIds = this.filteredDefects.map((defect) => {
      return defect.id;
    });
    Dialogs.waitDialogTk('generalPages.editViaWorkerDefects.csvExportStarted');

    try {
      const response =
        await this.socketService.exportCsvModuleEndpoints.exportDefects({
          selectedDefectIds
        });
      Dialogs.closeAllDialogs();
      void this.fileDownloadService.downloadFile(
        response.filePath.replace(/\\/g, '/')
      );
    } catch (e) {
      WebSocketHandlerErrorUtils.handleWebSocketError(e);
    }
  }
}
