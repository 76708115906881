import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingTag } from '../../classes/EntityManager/entities/Tag/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { Dialogs } from '../../classes/Dialogs';

@autoinject()
export class EditTagCategoriesWidget {
  @bindable()
  public thing: Thing | null = null;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(
    permissionsService: PermissionsService,
    private readonly entityManager: AppEntityManager
  ) {
    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as EditTagCategoriesWidget,
        propertyName: 'thing'
      });
  }

  protected handleAddButtonClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot add tag category without a thing'
    );

    this.thing.tagCategories.push({ name: '', displayName: '' });

    this.entityManager.thingRepository.update(this.thing);
  }

  @computed(expression('thing.id'), model(EntityName.Tag))
  protected get tagsWithoutCategory(): Array<ThingTag> {
    if (!this.thing) return [];

    return this.entityManager.tagRepository
      .getByThingId(this.thing.id)
      .filter((tag) => !tag.category);
  }

  protected handleTagCreated(tagName: string): void {
    assertNotNullOrUndefined(this.thing, 'cannot create tag without a thing');

    this.entityManager.tagRepository.getOrCreateThingTag({
      name: tagName,
      ownerUserGroupId: this.thing.ownerUserGroupId,
      thingId: this.thing.id
    });
  }

  protected handleTagRemoved(tag: ThingTag): void {
    void Dialogs.deleteEntityDialog(tag).then(() => {
      this.entityManager.tagRepository.delete(tag);
    });
  }
}
