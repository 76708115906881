import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../AppEntityManager';
import { SavePictureFileDataUrlService } from '../PictureFile/SavePictureFileDataUrlService';
import { Picture } from './types';
import { PictureFileByActivePictureRevisionService } from '../PictureFile/PictureFileByActivePictureRevisionService';
import { PictureFile } from '../PictureFile/types';

@autoinject()
export class PictureCopyService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService
  ) {}

  /**
   * Currently this doesn't copy markings since this would need special resolution on how they should be copied.
   * E.g. if you want to copy a picture from one entry to another, you don't really want to have markings on the parent of the original entry
   */
  public async copy<T extends Picture>({
    pictureToCopy,
    createPicture
  }: {
    pictureToCopy: Picture;
    createPicture: CreatePicture<T>;
  }): Promise<T> {
    const copiedPicture = createPicture({
      baseData: {
        description: pictureToCopy.description,
        coords: pictureToCopy.coords,
        location_info: pictureToCopy.location_info,
        takenAt: pictureToCopy.takenAt,
        additional_markings: [],
        marking: null,
        takenByUserId:
          pictureToCopy.takenByUserId ?? pictureToCopy.createdByUserId
      }
    });

    const pictureRevisionIdMapping = this.copyPictureRevisions({
      pictureToCopy,
      copiedPicture
    });

    await this.copyPictureFiles({
      copiedPicture,
      pictureToCopy,
      copiedRevisionInfo: pictureRevisionIdMapping
    });

    return copiedPicture;
  }

  /**
   * Copies a picture from a data url and returns the copied picture.
   *
   * PictureFiles & revisions of the original picture are not copied.
   * Instead, a new picture file is created with the data url.
   */
  public copyFromDataUrl<T extends Picture>({
    pictureToCopy,
    dataUrl,
    createPicture
  }: {
    pictureToCopy: Picture;
    dataUrl: string;
    createPicture: CreatePicture<T>;
  }): T {
    const copiedPicture = createPicture({
      baseData: {
        description: pictureToCopy.description,
        coords: pictureToCopy.coords,
        location_info: pictureToCopy.location_info,
        takenAt: pictureToCopy.takenAt,
        additional_markings: [],
        marking: null,
        takenByUserId:
          pictureToCopy.takenByUserId ?? pictureToCopy.createdByUserId
      }
    });

    this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
      copiedPicture,
      dataUrl
    );

    return copiedPicture;
  }

  private copyPictureRevisions({
    copiedPicture,
    pictureToCopy
  }: {
    copiedPicture: Picture;
    pictureToCopy: Picture;
  }): Array<CopiedRevisionInfo> {
    const revisionMapping: Array<CopiedRevisionInfo> = [];

    const pictureRevisionsToCopy =
      this.entityManager.pictureRevisionRepository.getByPictureId(
        pictureToCopy.id
      );

    for (const pictureRevisionToCopy of pictureRevisionsToCopy) {
      const copiedPictureRevision =
        this.entityManager.pictureRevisionRepository.createPictureRevisionForPicture(
          copiedPicture
        );

      copiedPictureRevision.selected = pictureRevisionToCopy.selected;
      this.entityManager.pictureRevisionRepository.update(
        copiedPictureRevision
      );

      revisionMapping.push({
        oldRevisionId: pictureRevisionToCopy.id,
        newRevisionId: copiedPictureRevision.id
      });
    }

    return revisionMapping;
  }

  private async copyPictureFiles({
    copiedPicture,
    pictureToCopy,
    copiedRevisionInfo
  }: {
    copiedPicture: Picture;
    pictureToCopy: Picture;
    copiedRevisionInfo: Array<CopiedRevisionInfo>;
  }): Promise<void> {
    if (copiedRevisionInfo.length === 0) {
      const pictureFilesToCopy =
        this.entityManager.pictureFileRepository.getByRevisionId({
          pictureId: pictureToCopy.id
        });

      for (const pictureFileToCopy of pictureFilesToCopy) {
        await this.copyPictureFile({ pictureFileToCopy, copiedPicture });
      }

      return;
    }

    for (const revisionMapEntry of copiedRevisionInfo) {
      const pictureFilesToCopy =
        this.entityManager.pictureFileRepository.getByRevisionId({
          pictureId: pictureToCopy.id,
          revisionId: revisionMapEntry.oldRevisionId
        });

      for (const pictureFileToCopy of pictureFilesToCopy) {
        await this.copyPictureFile({
          pictureFileToCopy,
          copiedPicture,
          pictureRevisionId: revisionMapEntry.newRevisionId
        });
      }
    }
  }

  private async copyPictureFile({
    pictureFileToCopy,
    copiedPicture,
    pictureRevisionId
  }: {
    pictureFileToCopy: PictureFile;
    copiedPicture: Picture;
    pictureRevisionId?: string;
  }): Promise<void> {
    if (!pictureFileToCopy.file_uploaded) {
      return;
    }

    const copiedPictureFile =
      this.entityManager.pictureFileRepository.createPictureFileForPicture({
        picture: copiedPicture,
        type: pictureFileToCopy.type,
        extension: pictureFileToCopy.file_extension,
        pictureRevisionId: pictureRevisionId || null
      });

    await this.savePictureFileDataUrlService.copyPictureFileFile(
      pictureFileToCopy,
      copiedPictureFile
    );
  }
}

export type PictureBaseData = Pick<
  Picture,
  | 'description'
  | 'location_info'
  | 'coords'
  | 'takenAt'
  | 'marking'
  | 'additional_markings'
  | 'takenByUserId'
>;

export type CreatePicture<T extends Picture> = (options: {
  baseData: PictureBaseData;
}) => T;

type CopiedRevisionInfo = {
  oldRevisionId: string;
  newRevisionId: string;
};
