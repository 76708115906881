import { computedFrom } from 'aurelia-binding';
import { autoinject } from 'aurelia-dependency-injection';
import { EventAggregator } from 'aurelia-event-aggregator';
import { WaitForInitialActualizationService } from '../../classes/EntityManager/entities/generalServices/WaitForInitialActualizationService/WaitForInitialActualizationService';
import { EventAggregatorPromiseHelper } from '../../classes/Promise/EventAggregatorPromiseHelper';

@autoinject()
export class SingleSocketRequestService {
  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly waitForInitialActualizationService: WaitForInitialActualizationService
  ) {}

  public createRequest<TRequestData, TRequestResult>(
    options: CreateRequestOptions<TRequestData, TRequestResult>
  ): SingleSocketRequest<TRequestData, TRequestResult> {
    return new SingleSocketRequest({
      eventAggregator: this.eventAggregator,
      requestCallback: options.requestCallback,
      preConditionCallback: null
    });
  }

  public createAfterFirstActualizationRequest<TRequestData, TRequestResult>(
    options: CreateRequestOptions<TRequestData, TRequestResult>
  ): SingleSocketRequest<TRequestData, TRequestResult> {
    return new SingleSocketRequest({
      eventAggregator: this.eventAggregator,
      requestCallback: options.requestCallback,
      preConditionCallback: () =>
        this.waitForInitialActualizationService.waitForInitialActualization()
    });
  }
}

/**
 * This class makes sure that there are not multiple requests in parallel.
 * Also it automatically throws an Error if the socket disconnected in the middle of the call.
 *
 * Example:
 *  const request = new SingleSocketRequest(...)
 *  void request.send(data1); // triggers the request immediately
 *  void request.send(data2); // since a request is already pending, queue data2 and wait for the first request to finish. Also the promise returned will be reject with a SingleSocketRequestSkipped error
 *  void request.send(data3); // since the second request hasn't started yet, replace data2 with data3 and wait for the first request to finish. After the first request finished, a new request with data3 will automatically be sent
 *
 */
export class SingleSocketRequest<TRequestData, TRequestResult> {
  private readonly eventAggregator: EventAggregator;

  private readonly requestCallback: SingleSocketRequestCallback<
    TRequestData,
    TRequestResult
  >;

  private readonly preConditionCallback: PreConditionCallback | null;

  private requestIsPendingInternal: boolean = false;

  private nextRequestInfo: NextRequestInfo<
    TRequestData,
    TRequestResult
  > | null = null;

  constructor(
    options: SingleSocketRequestOptions<TRequestData, TRequestResult>
  ) {
    this.eventAggregator = options.eventAggregator;
    this.requestCallback = options.requestCallback;
    this.preConditionCallback = options.preConditionCallback;
  }

  public async send(data: TRequestData): Promise<TRequestResult> {
    if (this.requestIsPendingInternal) {
      return this.createNewNextRequestPromise(data);
    }

    return this.sendRequest(data);
  }

  @computedFrom('requestIsPendingInternal')
  public get isPending(): boolean {
    return this.requestIsPendingInternal;
  }

  private createNewNextRequestPromise(
    data: TRequestData
  ): Promise<TRequestResult> {
    if (this.nextRequestInfo) {
      this.nextRequestInfo.reject(new SingleSocketRequestSkippedError());
    }

    return new Promise((resolve, reject) => {
      this.nextRequestInfo = {
        resolve,
        reject,
        data
      };
    });
  }

  private sendNextRequestIfNecessary(): void {
    const info = this.nextRequestInfo;
    this.nextRequestInfo = null;

    if (!info) {
      return;
    }

    this.sendRequest(info.data).then(info.resolve, info.reject);
  }

  private sendRequest(data: TRequestData): Promise<TRequestResult> {
    this.requestIsPendingInternal = true;

    const wrappedRequestPromise =
      EventAggregatorPromiseHelper.createConnectedPromise<TRequestResult>(
        this.eventAggregator,
        new Promise((resolve, reject) => {
          let requestPromise;
          if (this.preConditionCallback) {
            requestPromise = this.preConditionCallback().then(() =>
              this.requestCallback({ data })
            );
          } else {
            requestPromise = this.requestCallback({ data });
          }

          void requestPromise.then(resolve).catch(reject);
        })
      );

    void wrappedRequestPromise.finally(() => {
      this.requestIsPendingInternal = false;
      this.sendNextRequestIfNecessary();
    });

    return wrappedRequestPromise;
  }
}

export class SingleSocketRequestSkippedError extends Error {}

export type SingleSocketRequestOptions<TRequestData, TRequestResult> = {
  eventAggregator: EventAggregator;
  requestCallback: SingleSocketRequestCallback<TRequestData, TRequestResult>;
  preConditionCallback: PreConditionCallback | null;
};

export type CreateRequestOptions<TRequestData, TRequestResult> = {
  requestCallback: SingleSocketRequestCallback<TRequestData, TRequestResult>;
};

export type SingleSocketRequestCallback<TRequestData, TRequestResult> = (args: {
  data: TRequestData;
}) => Promise<TRequestResult>;

/**
 * this callback will called before the request will be sent
 */
export type PreConditionCallback = () => Promise<void>;

type NextRequestInfo<TRequestData, TRequestResult> = {
  resolve: (value: TRequestResult) => void;
  reject: (reason: Error) => void;
  data: TRequestData;
};
