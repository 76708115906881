import { autoinject } from 'aurelia-framework';
import { TChangeUserPasswordResponse } from 'common/EndpointTypes/UserEndpointsHandler';
import { SessionService } from '../../../../services/SessionService/SessionService';
import { SocketService } from '../../../../services/SocketService';
import { User } from './types';

@autoinject()
export class UserPasswordService {
  constructor(
    private readonly socketService: SocketService,
    private readonly sessionService: SessionService
  ) {}

  public updatePassword(
    user: User,
    oldPassword: string | null,
    newPassword: string
  ): Promise<TChangeUserPasswordResponse> {
    return new Promise((res) => {
      this.socketService.setNewUserPassword(
        user.id,
        oldPassword,
        newPassword,
        (response) => {
          if (response.success) {
            void this.sessionService.setCurrentJWT(response.webtoken);
          }

          res(response);
        }
      );
    });
  }
}
