import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { DefectStatus } from 'common/Enums/DefectStatus';
import { ArrayUtils } from 'common/Utils/ArrayUtils';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  DefectSortOption,
  DefectSortOptions,
  DefectUtils
} from '../../classes/EntityManager/entities/Defect/DefectUtils';
import { computed } from '../../hooks/computed';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { arrayChanges, expression } from '../../hooks/dependencies';
import { ModuleName } from '../../classes/RecordItModuleHelper';
import { ThingAndThingGroupNameService } from '../../services/ThingAndThingGroupNameService/ThingAndThingGroupNameService';
import { DefectCopiedEvent } from '../edit-defect-details-widget/edit-defect-details-widget';
import { watch } from '../../hooks/watch';

@autoinject()
export class DefectOverviewWorker {
  /** read-only */
  @bindable() public filteredDefects: Array<Defect> = [];

  private defects: Array<Defect> = [];

  protected currentPageDefects: Array<Defect> = [];

  protected openedDefectInfo: Readonly<{
    defect: Defect | null;
    listItemElement: HTMLElement | null;
  }> | null = null;

  protected isDetailsWidgetOpen: boolean = false;

  protected defectListRef: HTMLElement | null = null;

  protected statusFilterSelectedOption = StatusFilterOptions.OPEN_AND_PROCESSED;

  protected statusFilterOptions = [
    {
      tk: 'defectComponents.defectOverviewWorker.statusFilter.all',
      value: StatusFilterOptions.ALL
    },
    {
      tk: 'defectComponents.defectOverviewWorker.statusFilter.onlyOpenAndProcessed',
      value: StatusFilterOptions.OPEN_AND_PROCESSED
    }
  ];

  protected selectedThingId: string | null = null;

  /**
   * null = option for filtering defects of all thing groups
   * ThingGroupFilterOption.NO_THING_GROUP_SET = option for filtering defects without thing group
   */
  protected selectedThingGroupId: string | null | ThingGroupSpecialFilter =
    null;

  private currentUser: User | null = null;

  private subscriptionManager: SubscriptionManager;

  private readonly sortOptions: DefectSortOptions;
  protected currentSortOption: DefectSortOption;

  protected thingInfosFromDefects: Array<{
    thingId: string;
    thingName: string;
  }> = [];

  protected thingGroupInfosFromDefects: Array<{
    thingGroupId: string | null | ThingGroupSpecialFilter;
    thingGroupLabel: string;
  }> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly thingAndThingGroupNameService: ThingAndThingGroupNameService,
    private readonly i18n: I18N
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.sortOptions = DefectUtils.createSortOptions({ entityManager });
    this.currentSortOption = this.sortOptions.sequenceNumber;
  }

  // /////////// LIFECYCLE /////////////

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser(this.updateCurrentUser.bind(this))
    );

    this.subscriptionManager.subscribeToModelChanges(EntityName.Defect, () => {
      this.updateDefects();
      this.updateThingGroupInfosFromDefects();
      this.updateThingInfosFromDefects();
    });

    this.updateDefects();
    this.updateThingInfosFromDefects();
    this.updateThingGroupInfosFromDefects();

    this.subscriptionManager.addDisposable(
      this.thingAndThingGroupNameService.subscribeToThingNamesChanged(() => {
        this.updateThingInfosFromDefects();
      })
    );
    this.subscriptionManager.addDisposable(
      this.thingAndThingGroupNameService.subscribeToThingGroupNamesChanged(
        () => {
          this.updateThingGroupInfosFromDefects();
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // /////////// METHODS /////////////

  private openDetailsView(defect: Defect): void {
    this.openedDefectInfo = {
      defect,
      listItemElement:
        this.defectListRef?.querySelector(`#defect-${defect.id}`) || null
    };

    this.isDetailsWidgetOpen = true;
  }

  private closeDetailsView(): void {
    // Don't set openedDefect to null because the widget closing animation plays
    // - The next openDetailsView will set it again anyway
    this.isDetailsWidgetOpen = false;
  }

  private matchesStatusFilter(defect: Defect): boolean {
    switch (this.statusFilterSelectedOption) {
      case StatusFilterOptions.ALL:
        return true;
      case StatusFilterOptions.OPEN_AND_PROCESSED:
        const status = defect.status;
        return (
          status === DefectStatus.OPEN || status === DefectStatus.PROCESSED
        );
      default:
        throw new Error('unknown StatusFilterOptions value');
    }
  }

  private matchesThingFilter(defect: Defect): boolean {
    if (!this.selectedThingId) return true;

    return defect.ownerThingId === this.selectedThingId;
  }

  private matchesThingGroupFilter(defect: Defect): boolean {
    if (this.selectedThingGroupId == null) {
      return true;
    }
    const thingGroupInfo =
      this.thingAndThingGroupNameService.getThingGroupNameAndIdByThingId(
        defect.ownerThingId
      );
    if (
      this.selectedThingGroupId === ThingGroupSpecialFilter.NO_THING_GROUP_SET
    ) {
      return !thingGroupInfo?.thingGroupId;
    }

    return thingGroupInfo?.thingGroupId === this.selectedThingGroupId;
  }
  // /////////// UPDATERS /////////////

  private updateCurrentUser(user: User | null): void {
    this.currentUser = user;

    this.updateDefects();
    this.updateThingInfosFromDefects();
  }

  private updateDefects(): void {
    if (!this.currentUser) {
      this.defects = [];
      return;
    }

    this.defects = this.entityManager.defectRepository
      .getAll()
      .filter((d) => d.assigneeId === this.currentUser!.id);
  }

  private updateThingInfosFromDefects(): void {
    const thingInfosFromDefects = this.defects.map((defect) => {
      return {
        thingId: defect.ownerThingId,
        thingName:
          this.thingAndThingGroupNameService.getThingNameByThingId(
            defect.ownerThingId
          ) ?? ''
      };
    });

    this.thingInfosFromDefects = ArrayUtils.unique(
      thingInfosFromDefects,
      ({ thingId }) => thingId
    );
  }

  private updateThingGroupInfosFromDefects(): void {
    const thingGroupInfos = this.defects.reduce<
      Array<{
        thingGroupId: string | null;
        thingGroupLabel: string;
      }>
    >((infos, defect) => {
      const info =
        this.thingAndThingGroupNameService.getThingGroupNameAndIdByThingId(
          defect.ownerThingId
        );
      if (
        info != null &&
        !infos.some((x) => x.thingGroupId === info.thingGroupId)
      ) {
        infos.push({
          thingGroupId: info.thingGroupId,
          thingGroupLabel: info.thingGroupName ?? ''
        });
      }
      return infos;
    }, []);
    this.thingGroupInfosFromDefects = [
      {
        thingGroupId: ThingGroupSpecialFilter.NO_THING_GROUP_SET,
        thingGroupLabel: this.i18n.tr(
          'defectComponents.defectOverviewWorker.thingGroupFilter.withoutThingGroupOption'
        )
      },
      ...thingGroupInfos
    ];
  }

  protected handleDetailViewButtonClick(defect: Defect): void {
    this.openDetailsView(defect);
  }

  protected handleWidgetOverlayCloseIconClicked(): void {
    this.closeDetailsView();
  }

  protected handleDefectCopied(event: DefectCopiedEvent): void {
    this.openDetailsView(event.detail.defect);
  }

  @computed(expression('currentUser'))
  protected get useInlineView(): boolean {
    return !PermissionHelper.userHasPermissionForModule(
      this.currentUser,
      ModuleName.KUK
    );
  }

  @watch(
    arrayChanges('defects'),
    expression('statusFilterSelectedOption'),
    expression('selectedThingId'),
    expression('selectedThingGroupId')
  )
  protected updateFilteredDefects(): void {
    this.filteredDefects = this.defects.filter(
      (d) =>
        this.matchesStatusFilter(d) &&
        this.matchesThingFilter(d) &&
        this.matchesThingGroupFilter(d)
    );
  }
}

enum StatusFilterOptions {
  ALL = 'all',
  OPEN_AND_PROCESSED = 'openAndProcessed'
}

enum ThingGroupSpecialFilter {
  // by using a number we can safely assume that a filter value of type string is always an entity id
  NO_THING_GROUP_SET = 1
}
