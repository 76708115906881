import { TagHelper } from 'common/EntityHelper/TagHelper';
import { CommonEntityHelper } from 'common/EntityHelper/CommonEntityHelper';
import { EntityName } from '../types';
import { ProjectTag, Tag, TagCreationEntity, ThingTag } from './types';
import { AppEntityRepository } from '../../base/AppEntityRepository';

export class TagRepository extends AppEntityRepository<EntityName.Tag> {
  public create(creationEntity: TagCreationEntity): Tag {
    return super.create({
      ...creationEntity,
      name: TagHelper.getTagName(creationEntity.name)
    });
  }

  public getByProjectId(projectId: string): Array<ProjectTag> {
    return this.getAll()
      .filter((tag): tag is ProjectTag => {
        return tag.project === projectId;
      })
      .sort((a, b) =>
        CommonEntityHelper.comparePositionByEntityOrder(
          { order: a.order },
          { order: b.order }
        )
      );
  }

  public getByThingId(thingId: string): Array<ThingTag> {
    return this.getAll()
      .filter((tag): tag is ThingTag => {
        return tag.thing === thingId;
      })
      .sort((a, b) =>
        CommonEntityHelper.comparePositionByEntityOrder(
          { order: a.order },
          { order: b.order }
        )
      );
  }

  public getOrCreateProjectTag(
    name: string,
    projectId: string,
    ownerUserGroupId: string,
    order: number | null = null
  ): ProjectTag {
    return this.getOrCreateTag<ProjectTag>(
      name,
      this.getByProjectId(projectId),
      () => ({
        name: name,
        project: projectId,
        ownerProjectId: projectId,
        ownerUserGroupId,
        order
      })
    );
  }

  public getOrCreateThingTag({
    name,
    thingId,
    ownerUserGroupId,
    order = null
  }: {
    name: string;
    thingId: string;
    ownerUserGroupId: string;
    order?: number | null;
  }): ThingTag {
    return this.getOrCreateTag<ThingTag>(
      name,
      this.getByThingId(thingId),
      () => ({
        name: name,
        thing: thingId,
        ownerUserGroupId,
        order
      })
    );
  }

  private getOrCreateTag<TSpecificTag extends Tag>(
    name: string,
    tags: Array<TSpecificTag>,
    createCreationEntity: () => TagCreationEntity
  ): TSpecificTag {
    const foundTag = tags.find(
      (t) => TagHelper.getTagName(t.name) === TagHelper.getTagName(name)
    );
    if (foundTag) {
      return foundTag;
    } else {
      return this.create(createCreationEntity()) as TSpecificTag;
    }
  }
}
