import {
  EndpointParameter,
  EndpointResult
} from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';
import { SocketCommandEmitter } from './SocketCommandEmitter';

export class CopyEntitiesModuleEndpoints extends SocketCommandEmitter {
  public async copyThing(
    request: EndpointParameter<'copyEntitiesModule', 'copyThing'>
  ): Promise<EndpointResult<'copyEntitiesModule', 'copyThing'>> {
    return this.emitCommand('copyEntitiesModule', 'copyThing', request);
  }
}
