import { inject, BindingEngine, Lazy } from 'aurelia-framework';

import { EventAggregator } from 'aurelia-event-aggregator';
import { SubscriptionManager } from '../classes/SubscriptionManager';
import { SocketService } from './SocketService';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';

@inject(
  EventAggregator,
  BindingEngine,
  SocketService,
  Lazy.of(AppEntityManager)
)
export class SubscriptionManagerService {
  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly bindingEngine: BindingEngine,
    private readonly socketService: SocketService,
    private readonly getEntityManager: () => AppEntityManager
  ) {}

  public create(): SubscriptionManager {
    return new SubscriptionManager(
      this.eventAggregator,
      this.bindingEngine,
      this.socketService,
      this.getEntityManager
    );
  }
}
