import { autoinject, observable } from 'aurelia-framework';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { ScrollHelper } from '../../classes/ScrollHelper';

import { SocketService } from '../../services/SocketService';
import { FilterHelper } from '../../classes/FilterHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SorterSortOption } from '../../aureliaAttributes/sorter';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { Pagination } from '../../aureliaComponents/pagination/pagination';
import { EditReportTypeDialog } from '../../dialogs/edit-report-type-dialog/edit-report-type-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

@autoinject()
export class import_report_type {
  protected readonly reportTypeSortOptions: {
    name: SorterSortOption<ReportType>;
    uploaded: SorterSortOption<ReportType>;
  } = {
    name: {
      name: 'Name',
      sortFunction: (a, b) => {
        let aName = a.name;
        let bName = b.name;
        if (!aName) aName = 'Name';
        if (!bName) bName = 'Name';
        return aName.localeCompare(bName);
      }
    },
    uploaded: {
      name: 'Uploaddatum',
      sortFunction: (a, b) => {
        const aDate = a.upload_date ? new Date(a.upload_date) : new Date(0);
        const bDate = b.upload_date ? new Date(b.upload_date) : new Date(0);
        return aDate.getTime() - bDate.getTime();
      }
    }
  };

  private readonly subscriptionManager: SubscriptionManager;

  private currentReportTypeEditing: ReportType | null = null;

  @observable()
  protected reportTypeFilterString: string;

  private availableReportTypes: Array<ReportType> = [];
  protected filteredReportTypes: Array<ReportType> = [];
  protected sortedReportTypes: Array<ReportType> = [];
  protected currentPageReportTypes: Array<ReportType> = [];
  protected currentSortOption: SorterSortOption<ReportType>;

  protected pagination: Pagination<ReportType> | null = null;
  protected editReportTypeDialog: EditReportTypeDialog | null = null;

  private isAttached: boolean = false;
  protected isOnline: boolean = false;
  protected isMobile: boolean = false;

  protected readonly EntityName = EntityName;

  constructor(
    private readonly socketService: SocketService,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.currentSortOption = this.reportTypeSortOptions.name;
    this.reportTypeFilterString = '';
  }

  protected activate(params: { report_type_id?: string }): void {
    this.updateAvailableReportTypes();

    if (params.report_type_id) {
      const reportType = this.entityManager.reportTypeRepository.getById(
        params.report_type_id
      );
      if (reportType) this.tryEditReportType(reportType);
    }
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ReportType,
      this.updateAvailableReportTypes.bind(this)
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected updateAvailableReportTypes(): void {
    this.availableReportTypes =
      this.entityManager.reportTypeRepository.getAll();
    this.handleFilterReportTypes();
  }

  protected handleCreateReportTypeClick(userGroup: UserGroup): void {
    const reportType = this.entityManager.reportTypeRepository.create({
      usergroup: userGroup.id,
      ownerUserGroupId: userGroup.id
    });

    this.editReportType(reportType);
  }

  protected handleFilterReportTypes(): void {
    this.filteredReportTypes = FilterHelper.filterItems(
      this.availableReportTypes,
      (item) => {
        let returnValue = item.name + ' ' + item.file_name;
        const userGroup = this.entityManager.userGroupRepository.getById(
          item.usergroup
        );
        if (userGroup) returnValue += ' ' + userGroup.name;
        return returnValue;
      },
      this.reportTypeFilterString
    );
  }

  protected reportTypeFilterStringChanged(): void {
    this.handleFilterReportTypes();
  }

  protected handleEditReportTypeClick(reportType: ReportType): void {
    this.editReportType(reportType);
  }

  protected handleReportTypeDialogClosed(): void {
    if (this.currentReportTypeEditing)
      this.goToReportType(this.currentReportTypeEditing);
  }

  protected editReportType(reportType: ReportType): void {
    this.currentReportTypeEditing = reportType;
    this.editReportTypeDialog?.open();
  }

  private goToReportType(reportType: ReportType): void {
    void ScrollHelper.autoScrollToListItem(
      '#import-report-type--report-type-' + reportType.id,
      this.pagination,
      reportType,
      () => this.isAttached
    );
  }

  private tryEditReportType(reportType: ReportType): void {
    if (this.isAttached) {
      this.editReportType(reportType);
    } else {
      setTimeout(() => {
        this.tryEditReportType(reportType);
      }, 10);
    }
  }
}
