import { autoinject } from 'aurelia-framework';

import {
  EndpointParameter,
  EndpointResult
} from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';

import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { EventDispatcher } from '../../classes/EventDispatcher/EventDispatcher';
import { PropertyBinder } from '../../classes/PropertyBinder/PropertyBinder';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import {
  SingleSocketRequest,
  SingleSocketRequestService
} from '../SingleSocketRequestService/SingleSocketRequestService';
import { SocketService } from '../SocketService';
import { SubscriptionManagerService } from '../SubscriptionManagerService';
import { ThingGroupNameHandle } from './ThingGroupNameHandle';
import { ThingNameHandle } from './ThingNameHandle';

/**
 * A temporary solution to also be able to see thing + thingGroup names of assigned defects if they are from another userGroup
 */
@autoinject()
export class ThingAndThingGroupNameService {
  private static readonly LAST_RESPONSE_KEY =
    'ThingAndThingGroupNameService::lastResponse';

  private readonly subscriptionManager: SubscriptionManager;

  private readonly getThingAndThingGroupNamesRequest: SingleSocketRequest<
    EndpointParameter<'entityInfoModule', 'getThingAndThingGroupNames'>,
    EndpointResult<'entityInfoModule', 'getThingAndThingGroupNames'>
  >;

  private lastResponsePropertyBinder: LastResponsePropertyBinder =
    new PropertyBinder({
      defaultValuesByName: { value: null }
    }) as LastResponsePropertyBinder;

  private eventDispatcher: EventDispatcher<{
    thingNamesChanged: null;
    thingGroupNamesChanged: null;
  }> = new EventDispatcher();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly socketService: SocketService,
    singleSocketRequestService: SingleSocketRequestService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.getThingAndThingGroupNamesRequest =
      singleSocketRequestService.createRequest({
        requestCallback: ({ data }) => {
          return socketService.entityInfoModuleEndpoints.getThingAndThingGroupNames(
            data
          );
        }
      });
  }

  public async init(): Promise<void> {
    this.subscriptionManager.subscribeToModelChanges(EntityName.Defect, () => {
      void this.refreshCache();
    });

    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.registerPostActualizationTask(
        async () => {
          await this.refreshCache();
        }
      )
    );

    const lastResponse = await DataStorageHelper.getItem(
      ThingAndThingGroupNameService.LAST_RESPONSE_KEY
    );
    this.lastResponsePropertyBinder.setValue('value', lastResponse ?? null);

    this.subscriptionManager.addDisposable(
      this.lastResponsePropertyBinder.registerBinding('value', () => {
        this.eventDispatcher.dispatchEvent('thingNamesChanged', null);
        this.eventDispatcher.dispatchEvent('thingGroupNamesChanged', null);
      })
    );

    this.subscriptionManager.subscribeToModelChanges(EntityName.Thing, () => {
      this.eventDispatcher.dispatchEvent('thingNamesChanged', null);
    });

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      () => {
        this.eventDispatcher.dispatchEvent('thingGroupNamesChanged', null);
      }
    );
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public getThingNameHandleByThingId(thingId: string): ThingNameHandle {
    return new ThingNameHandle({
      entityManager: this.entityManager,
      subscriptionManagerService: this.subscriptionManagerService,
      lastResponsePropertyBinder: this.lastResponsePropertyBinder,
      thingId
    });
  }

  public getThingGroupNameHandleByThingId(
    thingId: string
  ): ThingGroupNameHandle {
    return new ThingGroupNameHandle({
      entityManager: this.entityManager,
      subscriptionManagerService: this.subscriptionManagerService,
      lastResponsePropertyBinder: this.lastResponsePropertyBinder,
      thingId
    });
  }

  public async refreshCache(): Promise<void> {
    if (!this.socketService.isConnected()) {
      return;
    }

    const notAvailableThingIds = this.getLocallyNotAvailableThingIds(
      this.getThingIdsFromDefects()
    );

    if (notAvailableThingIds.length === 0) {
      return;
    }

    const response = await this.getThingAndThingGroupNamesRequest.send({
      thingIds: notAvailableThingIds
    });

    await this.setNewResponse(response);
  }

  public getThingNameByThingId(thingId: string): string | null {
    const thing = this.entityManager.thingRepository.getById(thingId);

    if (thing) {
      return thing.name;
    }

    return (
      this.lastResponsePropertyBinder.getValue('value')?.thingIdToThingData[
        thingId
      ]?.name ?? null
    );
  }

  public getThingGroupNameAndIdByThingId(
    thingId: string
  ): { thingGroupName: string | null; thingGroupId: string | null } | null {
    const thing = this.entityManager.thingRepository.getById(thingId);
    const thingGroup = thing?.thingGroupId
      ? this.entityManager.thingGroupRepository.getById(thing.thingGroupId)
      : null;

    if (thing && thingGroup) {
      return {
        thingGroupId: thing.thingGroupId,
        thingGroupName: thingGroup.name
      };
    }

    const cachedThingGroupId =
      this.lastResponsePropertyBinder.getValue('value')?.thingIdToThingData[
        thingId
      ]?.thingGroupId;

    if (!cachedThingGroupId) {
      return null;
    }

    return {
      thingGroupId: cachedThingGroupId,
      thingGroupName:
        this.lastResponsePropertyBinder.getValue('value')
          ?.thingGroupIdToThingGroupData[cachedThingGroupId]?.name ?? ''
    };
  }

  public subscribeToThingNamesChanged(callback: () => void): Disposable {
    return this.eventDispatcher.addDisposableEventListener(
      'thingNamesChanged',
      callback
    );
  }

  public subscribeToThingGroupNamesChanged(callback: () => void): Disposable {
    return this.eventDispatcher.addDisposableEventListener(
      'thingGroupNamesChanged',
      callback
    );
  }

  private getThingIdsFromDefects(): Array<string> {
    const thingIds = new Set<string>();

    for (const defect of this.entityManager.defectRepository.getAll()) {
      thingIds.add(defect.ownerThingId);
    }

    return Array.from(thingIds);
  }

  private getLocallyNotAvailableThingIds(
    thingIds: Array<string>
  ): Array<string> {
    return thingIds.filter((thingId) => {
      return !this.entityManager.thingRepository.getById(thingId);
    });
  }

  private async setNewResponse(
    response: EndpointResult<'entityInfoModule', 'getThingAndThingGroupNames'>
  ): Promise<void> {
    this.lastResponsePropertyBinder.setValue('value', response);

    await DataStorageHelper.setItem(
      ThingAndThingGroupNameService.LAST_RESPONSE_KEY,
      response
    );
  }
}

export type LastResponsePropertyBinder = PropertyBinder<{
  value: EndpointResult<
    'entityInfoModule',
    'getThingAndThingGroupNames'
  > | null;
}>;
