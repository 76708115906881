/*
  THIS FILE IS AUTOMATICALLY CREATED BY A GENERATION SCRIPT.
  Only changes to canCreateFieldName, canUpdateFieldName and canDeleteFieldName will be persisted.
*/

import { EntityName } from '../../Types/BaseEntities/EntityName';
import { validateEntityNameToRoleInfo } from './validateEntityNameToRoleInfo';

export const entityNameToRoleInfo = validateEntityNameToRoleInfo({
  roleInfos: {
    [EntityName.Defect]: {
      canCreateFieldName: 'canCreateDefects',
      canUpdateFieldName: 'canUpdateDefects',
      canDeleteFieldName: 'canDeleteDefects'
    },
    [EntityName.DefectChangeLogEntry]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.DefectComment]: {
      canCreateFieldName: 'canCreateDefectComments',
      canUpdateFieldName: 'canUpdateDefectComments',
      canDeleteFieldName: 'canDeleteDefectComments'
    },
    [EntityName.EntityDashboardInfo]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Entry]: {
      canCreateFieldName: 'canCreateEntries',
      canUpdateFieldName: 'canUpdateEntries',
      canDeleteFieldName: 'canDeleteEntries'
    },
    [EntityName.EntryToPerson]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.EntryUsageStatistic]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ExternalUserCredential]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.GeneralFile]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.MapLayer]: {
      canCreateFieldName: 'canCreateMapLayers',
      canUpdateFieldName: 'canUpdateMapLayers',
      canDeleteFieldName: 'canDeleteMapLayers'
    },
    [EntityName.NfcToken]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.NfcTokenToPerson]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.NfcTokenToThing]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Person]: {
      canCreateFieldName: 'canCreatePersons',
      canUpdateFieldName: 'canUpdatePersons',
      canDeleteFieldName: 'canDeletePersons'
    },
    [EntityName.PersonContact]: {
      canCreateFieldName: 'canCreatePersonContacts',
      canUpdateFieldName: 'canUpdatePersonContacts',
      canDeleteFieldName: 'canDeletePersonContacts'
    },
    [EntityName.PersonToPerson]: {
      canCreateFieldName: 'canCreatePersonToPersons',
      canUpdateFieldName: 'canUpdatePersonToPersons',
      canDeleteFieldName: 'canDeletePersonToPersons'
    },
    [EntityName.Picture]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.PictureFile]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.PictureRevision]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessConfiguration]: {
      canCreateFieldName: 'canCreateProcessConfigurations',
      canUpdateFieldName: 'canUpdateProcessConfigurations',
      canDeleteFieldName: 'canDeleteProcessConfigurations'
    },
    [EntityName.ProcessConfigurationActionStatus]: {
      canCreateFieldName: 'canCreateProcessConfigurationActionStates',
      canUpdateFieldName: 'canUpdateProcessConfigurationActionStates',
      canDeleteFieldName: 'canDeleteProcessConfigurationActionStates'
    },
    [EntityName.ProcessConfigurationAuthorizationType]: {
      canCreateFieldName: 'canCreateProcessConfigurationAuthorizationTypes',
      canUpdateFieldName: 'canUpdateProcessConfigurationAuthorizationTypes',
      canDeleteFieldName: 'canDeleteProcessConfigurationAuthorizationTypes'
    },
    [EntityName.ProcessConfigurationCategory]: {
      canCreateFieldName: 'canCreateProcessConfigurationCategories',
      canUpdateFieldName: 'canUpdateProcessConfigurationCategories',
      canDeleteFieldName: 'canDeleteProcessConfigurationCategories'
    },
    [EntityName.ProcessConfigurationDevice]: {
      canCreateFieldName: 'canCreateProcessConfigurationDevices',
      canUpdateFieldName: 'canUpdateProcessConfigurationDevices',
      canDeleteFieldName: 'canDeleteProcessConfigurationDevices'
    },
    [EntityName.ProcessConfigurationDeviceExport]: {
      canCreateFieldName: 'canCreateProcessConfigurationDeviceExports',
      canUpdateFieldName: 'canUpdateProcessConfigurationDeviceExports',
      canDeleteFieldName: 'canDeleteProcessConfigurationDeviceExports'
    },
    [EntityName.ProcessConfigurationFollowUpAppointment]: {
      canCreateFieldName: 'canCreateProcessConfigurationFollowUpAppointments',
      canUpdateFieldName: 'canUpdateProcessConfigurationFollowUpAppointments',
      canDeleteFieldName: 'canDeleteProcessConfigurationFollowUpAppointments'
    },
    [EntityName.ProcessConfigurationForm]: {
      canCreateFieldName: 'canCreateProcessConfigurationForms',
      canUpdateFieldName: 'canUpdateProcessConfigurationForms',
      canDeleteFieldName: 'canDeleteProcessConfigurationForms'
    },
    [EntityName.ProcessConfigurationFormSendTarget]: {
      canCreateFieldName: 'canCreateProcessConfigurationFormSendTargets',
      canUpdateFieldName: 'canUpdateProcessConfigurationFormSendTargets',
      canDeleteFieldName: 'canDeleteProcessConfigurationFormSendTargets'
    },
    [EntityName.ProcessConfigurationMeasurePointType]: {
      canCreateFieldName: 'canCreateProcessConfigurationMeasurePointTypes',
      canUpdateFieldName: 'canUpdateProcessConfigurationMeasurePointTypes',
      canDeleteFieldName: 'canDeleteProcessConfigurationMeasurePointTypes'
    },
    [EntityName.ProcessConfigurationNoteCategory]: {
      canCreateFieldName: 'canCreateProcessConfigurationNoteCategories',
      canUpdateFieldName: 'canUpdateProcessConfigurationNoteCategories',
      canDeleteFieldName: 'canDeleteProcessConfigurationNoteCategories'
    },
    [EntityName.ProcessConfigurationStep]: {
      canCreateFieldName: 'canCreateProcessConfigurationSteps',
      canUpdateFieldName: 'canUpdateProcessConfigurationSteps',
      canDeleteFieldName: 'canDeleteProcessConfigurationSteps'
    },
    [EntityName.ProcessConfigurationStepAutoAppointment]: {
      canCreateFieldName: 'canCreateProcessConfigurationStepAutoAppointments',
      canUpdateFieldName: 'canUpdateProcessConfigurationStepAutoAppointments',
      canDeleteFieldName: 'canDeleteProcessConfigurationStepAutoAppointments'
    },
    [EntityName.ProcessConfigurationStepAutoForm]: {
      canCreateFieldName: 'canCreateProcessConfigurationStepAutoForms',
      canUpdateFieldName: 'canUpdateProcessConfigurationStepAutoForms',
      canDeleteFieldName: 'canDeleteProcessConfigurationStepAutoForms'
    },
    [EntityName.ProcessConfigurationStepPosition]: {
      canCreateFieldName: 'canCreateProcessConfigurationStepPositions',
      canUpdateFieldName: 'canUpdateProcessConfigurationStepPositions',
      canDeleteFieldName: 'canDeleteProcessConfigurationStepPositions'
    },
    [EntityName.ProcessTask]: {
      canCreateFieldName: 'canCreateProcessTasks',
      canUpdateFieldName: 'canUpdateProcessTasks',
      canDeleteFieldName: 'canDeleteProcessTasks'
    },
    [EntityName.ProcessTaskAppointment]: {
      canCreateFieldName: 'canCreateProcessTaskAppointments',
      canUpdateFieldName: 'canUpdateProcessTaskAppointments',
      canDeleteFieldName: 'canDeleteProcessTaskAppointments'
    },
    [EntityName.ProcessTaskAppointmentContact]: {
      canCreateFieldName: 'canCreateProcessTaskAppointmentContacts',
      canUpdateFieldName: 'canUpdateProcessTaskAppointmentContacts',
      canDeleteFieldName: 'canDeleteProcessTaskAppointmentContacts'
    },
    [EntityName.ProcessTaskAppointmentToProcessTaskDevice]: {
      canCreateFieldName: 'canCreateProcessTaskAppointmentToProcessTaskDevices',
      canUpdateFieldName: 'canUpdateProcessTaskAppointmentToProcessTaskDevices',
      canDeleteFieldName: 'canDeleteProcessTaskAppointmentToProcessTaskDevices'
    },
    [EntityName.ProcessTaskAppointmentToProcessTaskPosition]: {
      canCreateFieldName:
        'canCreateProcessTaskAppointmentToProcessTaskPositions',
      canUpdateFieldName:
        'canUpdateProcessTaskAppointmentToProcessTaskPositions',
      canDeleteFieldName:
        'canDeleteProcessTaskAppointmentToProcessTaskPositions'
    },
    [EntityName.ProcessTaskAppointmentToUser]: {
      canCreateFieldName: 'canCreateProcessTaskAppointmentToUsers',
      canUpdateFieldName: 'canUpdateProcessTaskAppointmentToUsers',
      canDeleteFieldName: 'canDeleteProcessTaskAppointmentToUsers'
    },
    [EntityName.ProcessTaskChecklistEntry]: {
      canCreateFieldName: 'canCreateProcessTaskChecklistEntries',
      canUpdateFieldName: 'canUpdateProcessTaskChecklistEntries',
      canDeleteFieldName: 'canDeleteProcessTaskChecklistEntries'
    },
    [EntityName.ProcessTaskComment]: {
      canCreateFieldName: 'canCreateProcessTaskComments',
      canUpdateFieldName: 'canUpdateProcessTaskComments',
      canDeleteFieldName: 'canDeleteProcessTaskComments'
    },
    [EntityName.ProcessTaskDevice]: {
      canCreateFieldName: 'canCreateProcessTaskDevices',
      canUpdateFieldName: 'canUpdateProcessTaskDevices',
      canDeleteFieldName: 'canDeleteProcessTaskDevices'
    },
    [EntityName.ProcessTaskGroup]: {
      canCreateFieldName: 'canCreateProcessTaskGroups',
      canUpdateFieldName: 'canUpdateProcessTaskGroups',
      canDeleteFieldName: 'canDeleteProcessTaskGroups'
    },
    [EntityName.ProcessTaskGroupAuthorization]: {
      canCreateFieldName: 'canCreateProcessTaskGroupAuthorizations',
      canUpdateFieldName: 'canUpdateProcessTaskGroupAuthorizations',
      canDeleteFieldName: 'canDeleteProcessTaskGroupAuthorizations'
    },
    [EntityName.ProcessTaskGroupFilter]: {
      canCreateFieldName: 'canCreateProcessTaskGroupFilters',
      canUpdateFieldName: 'canUpdateProcessTaskGroupFilters',
      canDeleteFieldName: 'canDeleteProcessTaskGroupFilters'
    },
    [EntityName.ProcessTaskInvoice]: {
      canCreateFieldName: 'canCreateProcessTaskInvoices',
      canUpdateFieldName: 'canUpdateProcessTaskInvoices',
      canDeleteFieldName: 'canDeleteProcessTaskInvoices'
    },
    [EntityName.ProcessTaskInvoiceToProcessTask]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskInvoiceToProcessTaskDevice]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskInvoiceToProcessTaskPosition]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskLogEntry]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskMeasurePoint]: {
      canCreateFieldName: 'canCreateProcessTaskMeasurePoints',
      canUpdateFieldName: 'canUpdateProcessTaskMeasurePoints',
      canDeleteFieldName: 'canDeleteProcessTaskMeasurePoints'
    },
    [EntityName.ProcessTaskMeasurePointReading]: {
      canCreateFieldName: 'canCreateProcessTaskMeasurePointReadings',
      canUpdateFieldName: 'canUpdateProcessTaskMeasurePointReadings',
      canDeleteFieldName: 'canDeleteProcessTaskMeasurePointReadings'
    },
    [EntityName.ProcessTaskMeasurePointToPicture]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskNote]: {
      canCreateFieldName: 'canCreateProcessTaskNotes',
      canUpdateFieldName: 'canUpdateProcessTaskNotes',
      canDeleteFieldName: 'canDeleteProcessTaskNotes'
    },
    [EntityName.ProcessTaskOffer]: {
      canCreateFieldName: 'canCreateProcessTaskOffers',
      canUpdateFieldName: 'canUpdateProcessTaskOffers',
      canDeleteFieldName: 'canDeleteProcessTaskOffers'
    },
    [EntityName.ProcessTaskOfferToProcessTask]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskOfferToProcessTaskDevice]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskOfferToProcessTaskPosition]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskPosition]: {
      canCreateFieldName: 'canCreateProcessTaskPositions',
      canUpdateFieldName: 'canUpdateProcessTaskPositions',
      canDeleteFieldName: 'canDeleteProcessTaskPositions'
    },
    [EntityName.ProcessTaskPositionDetailEntry]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProcessTaskRecurringAppointment]: {
      canCreateFieldName: 'canCreateProcessTaskRecurringAppointments',
      canUpdateFieldName: 'canUpdateProcessTaskRecurringAppointments',
      canDeleteFieldName: 'canDeleteProcessTaskRecurringAppointments'
    },
    [EntityName.ProcessTaskToProject]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Project]: {
      canCreateFieldName: 'canCreateProjects',
      canUpdateFieldName: 'canUpdateProjects',
      canDeleteFieldName: 'canDeleteProjects'
    },
    [EntityName.ProjectQuestion]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProjectQuestionCatalogue]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProjectQuestionCatalogueToQuestionSet]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProjectQuestionCategory]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ProjectQuestionSet]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Property]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.PropertyToPerson]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.PropertyToProcessTaskPosition]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Question]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.QuestionCatalogue]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.QuestionCatalogueToQuestionSet]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.QuestionCatalogueToThing]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.QuestionCategory]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.QuestionSet]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Region]: {
      canCreateFieldName: 'canCreateRegions',
      canUpdateFieldName: 'canUpdateRegions',
      canDeleteFieldName: 'canDeleteRegions'
    },
    [EntityName.Report]: {
      canCreateFieldName: 'canCreateReports',
      canUpdateFieldName: 'canUpdateReports',
      canDeleteFieldName: 'canDeleteReports'
    },
    [EntityName.ReportType]: {
      canCreateFieldName: 'canCreateReportTypes',
      canUpdateFieldName: 'canUpdateReportTypes',
      canDeleteFieldName: 'canDeleteReportTypes'
    },
    [EntityName.SharepointListColumnToThingMappingItem]: {
      canCreateFieldName: 'canCreateSharepointListColumnToThingMappingItems',
      canUpdateFieldName: 'canUpdateSharepointListColumnToThingMappingItems',
      canDeleteFieldName: 'canDeleteSharepointListColumnToThingMappingItems'
    },
    [EntityName.StructurePictureArea]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.StructureTemplate]: {
      canCreateFieldName: 'canCreateStructureTemplates',
      canUpdateFieldName: 'canUpdateStructureTemplates',
      canDeleteFieldName: 'canDeleteStructureTemplates'
    },
    [EntityName.StructureTemplateEntry]: {
      canCreateFieldName: 'canCreateStructureTemplateEntries',
      canUpdateFieldName: 'canUpdateStructureTemplateEntries',
      canDeleteFieldName: 'canDeleteStructureTemplateEntries'
    },
    [EntityName.StructureTemplateEntryGroup]: {
      canCreateFieldName: 'canCreateStructureTemplateEntryGroups',
      canUpdateFieldName: 'canUpdateStructureTemplateEntryGroups',
      canDeleteFieldName: 'canDeleteStructureTemplateEntryGroups'
    },
    [EntityName.StructureTemplateEntryGroupToStructureTemplateEntry]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.StructureTemplateEntryProperty]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.StructureTemplateRatingCategory]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.Tag]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.TextBrick]: {
      canCreateFieldName: 'canCreateTextBricks',
      canUpdateFieldName: 'canUpdateTextBricks',
      canDeleteFieldName: 'canDeleteTextBricks'
    },
    [EntityName.TextBrickTemplate]: {
      canCreateFieldName: 'canCreateTextBrickTemplates',
      canUpdateFieldName: 'canUpdateTextBrickTemplates',
      canDeleteFieldName: 'canDeleteTextBrickTemplates'
    },
    [EntityName.Thing]: {
      canCreateFieldName: 'canCreateThings',
      canUpdateFieldName: 'canUpdateThings',
      canDeleteFieldName: 'canDeleteThings'
    },
    [EntityName.ThingAuthorization]: {
      canCreateFieldName: 'canCreateThingAuthorizations',
      canUpdateFieldName: 'canUpdateThingAuthorizations',
      canDeleteFieldName: 'canDeleteThingAuthorizations'
    },
    [EntityName.ThingGroup]: {
      canCreateFieldName: 'canCreateThingGroups',
      canUpdateFieldName: 'canUpdateThingGroups',
      canDeleteFieldName: 'canDeleteThingGroups'
    },
    [EntityName.ThingSection]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ThingSectionConfigProperty]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ThingToPerson]: {
      canCreateFieldName: 'canCreateThingToPersons',
      canUpdateFieldName: 'canUpdateThingToPersons',
      canDeleteFieldName: 'canDeleteThingToPersons'
    },
    [EntityName.ThingType]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.User]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.UserCompanySetting]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.UserDefinedEntity]: {
      canCreateFieldName: 'canCreateUserDefinedEntities',
      canUpdateFieldName: 'canUpdateUserDefinedEntities',
      canDeleteFieldName: 'canDeleteUserDefinedEntities'
    },
    [EntityName.UserDefinedEntityConfig]: {
      canCreateFieldName: 'canCreateUserDefinedEntityConfigs',
      canUpdateFieldName: 'canUpdateUserDefinedEntityConfigs',
      canDeleteFieldName: 'canDeleteUserDefinedEntityConfigs'
    },
    [EntityName.UserDefinedEntityConfigPropertyConfig]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.UserGroup]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.UserRole]: {
      canCreateFieldName: 'canCreateUserRoles',
      canUpdateFieldName: 'canUpdateUserRoles',
      canDeleteFieldName: 'canDeleteUserRoles'
    },
    [EntityName.UserRoleToUser]: {
      canCreateFieldName: 'canCreateUserRoleToUsers',
      canUpdateFieldName: 'canUpdateUserRoleToUsers',
      canDeleteFieldName: 'canDeleteUserRoleToUsers'
    },
    [EntityName.ValueCalculationConfig]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    },
    [EntityName.ValueCalculationResult]: {
      canCreateFieldName: null,
      canUpdateFieldName: null,
      canDeleteFieldName: null
    }
  }
});

export type EntityNameToRoleInfo = typeof entityNameToRoleInfo;
