import { bindable, autoinject } from 'aurelia-framework';

import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EntityName } from 'common/Types/BaseEntities/EntityName';

import { SocketService } from '../../services/SocketService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';

/**
 * @event edit-report-type-dialog-closed
 */
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class EditReportTypeDialog {
  @bindable()
  public reportType: ReportType | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly reportTypePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ReportType];

  protected isConnected: boolean = false;

  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.entityManager = entityManager;
    this.socketService = socketService;
    this.subscriptionManager = subscriptionManagerService.create();

    this.reportTypePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ReportType,
        context: this as EditReportTypeDialog,
        propertyName: 'reportType'
      });
  }

  public open(): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't EditReportTypeDialog.open without dialog"
    );

    this.dialog.open();
  }

  public close(): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't EditReportTypeDialog.close without dialog"
    );

    this.dialog.close();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleReportTypeChanged(): void {
    assertNotNullOrUndefined(
      this.reportType,
      "can't EditReportTypeDialog.handleReportTypeChanged without reportType"
    );

    this.entityManager.reportTypeRepository.update(this.reportType);
  }

  protected formatToDateString(date: string | null): string {
    return DateUtils.formatToDateString(date);
  }

  protected formatToTimeString(date: string | null): string {
    return DateUtils.formatToTimeString(date);
  }

  protected getUsergroupNameById(usergroupId: string): string | null {
    const group = this.entityManager.userGroupRepository.getById(usergroupId);
    return group ? group.name : '';
  }

  protected handleDialogClosed(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'edit-report-type-dialog-closed',
      detail: null
    });
  }
}
