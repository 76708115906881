import { WebSocketHandlerError } from 'common/WebSocketEndpoints/WebSocketHandlerError/WebSocketHandlerError';
import { Dialogs } from '../Dialogs';
import { Logger } from '../Logger/Logger';

export class WebSocketHandlerErrorUtils {
  public static handleWebSocketError(e: unknown): void {
    if (e instanceof WebSocketHandlerError) {
      void Dialogs.errorDialogTk(
        'generalPages.editViaWorkerDefects.csvExportFailed',
        `WebsocketErrors.${e.type}`
      );
    } else {
      void Dialogs.errorDialogTk(
        'generalPages.editViaWorkerDefects.csvExportFailed',
        'WebsocketErrors.ERROR_UNKNOWN'
      );
      Logger.logError({ error: e });
    }
  }
}
