export enum WebSocketHandlerErrorType {
  ERROR_UNKNOWN = 'ERROR_UNKNOWN',
  ERROR_CONFIGURATION = 'ERROR_CONFIGURATION',
  ERROR_UNSERIALIZABLE = 'ERROR_UNSERIALIZABLE',
  ERROR_BAD_REQUEST = 'ERROR_BAD_REQUEST',
  ERROR_PDFSIGNER_BAD_CREDENTIALS = 'ERROR_PDFSIGNER_BAD_CREDENTIALS',
  ERROR_PDFSIGNER_ALREADY_SIGNED = 'ERROR_PDFSIGNER_ALREADY_SIGNED',
  ERROR_PDFSIGNER_SIGNING_IN_PROGRESS = 'ERROR_PDFSIGNER_SIGNING_IN_PROGRESS',
  ERROR_PDFSIGNER_REPORT_NOT_FOUND = 'ERROR_PDFSIGNER_REPORT_NOT_FOUND',
  ERROR_PDFSIGNER_NETWORK = 'ERROR_PDFSIGNER_NETWORK',
  ERROR_ENTITY_NOT_FOUND = 'ERROR_ENTITY_NOT_FOUND',
  ERROR_FORBIDDEN = 'ERROR_FORBIDDEN',
  ERROR_FILE_IO = 'ERROR_FILE_IO',
  ERROR_MISSING_PERMISSIONS_TO_COPY = 'ERROR_MISSING_PERMISSIONS_TO_COPY'
}
