import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { TagCategory } from 'common/Types/Entities/Thing/ThingDto';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { TagHelper } from 'common/EntityHelper/TagHelper';

import { ThingTag } from '../../../classes/EntityManager/entities/Tag/types';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import { computed } from '../../../hooks/computed';
import { expression, model } from '../../../hooks/dependencies';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../../services/PermissionBindingService';
import { NotificationHelper } from '../../../classes/NotificationHelper';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { EntitiesPermissionChecker } from '../../../services/PermissionsService/EntitiesPermissionChecker/EntitiesPermissionChecker';
import { TTextChangedEvent } from '../../../inputComponents/clickable-text-input/clickable-text-input';

@autoinject()
export class EditTagCategoriesWidgetTagCategory {
  @bindable()
  public tagCategory: TagCategory | null = null;

  @bindable()
  public thing: Thing | null = null;

  protected thingIsEditable = false;
  private permissionBindingHandle: PermissionBindingHandle;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  @subscribableLifecycle()
  protected readonly tagsPermissionsChecker: EntitiesPermissionChecker<EntityName.Tag>;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionBindingService: PermissionBindingService,
    private readonly i18n: I18N,
    permissionsService: PermissionsService
  ) {
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      entity: {
        property: 'thing',
        userGroupPropertyOfEntity: 'ownerUserGroupId',
        editableProperty: 'thingIsEditable'
      }
    });

    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as EditTagCategoriesWidgetTagCategory,
        propertyName: 'thing'
      });

    this.tagsPermissionsChecker =
      permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.Tag
      });
  }

  protected attached(): void {
    this.permissionBindingHandle.subscribe();
  }

  protected detached(): void {
    this.permissionBindingHandle.unsubscribe();
  }

  @computed(
    expression('thing.id'),
    model(EntityName.Tag),
    expression('tagCategory.name')
  )
  protected get thingTags(): Array<ThingTag> {
    const tagCategory = this.tagCategory;

    return this.thing && tagCategory
      ? this.entityManager.tagRepository
          .getByThingId(this.thing.id)
          .filter((t) => t.category === tagCategory.name)
      : [];
  }

  @computed(
    expression('thingPermissionsHandle.canEditField.tagCategories'),
    expression('thingTags'),
    expression('tagsPermissionChecker.revision')
  )
  protected get canChangeNameOrDeleteTagCategory(): boolean {
    return (
      this.thingPermissionsHandle.canEditField.tagCategories &&
      this.tagsPermissionsChecker.allEntitiesHavePermission({
        entities: this.thingTags,
        checkPermission: ({ adapter, entity }) => {
          return adapter.canEditField(entity); // category
        }
      })
    );
  }

  protected handleNameChanged(event: TTextChangedEvent): void {
    assertNotNullOrUndefined(
      this.tagCategory,
      'cannot change category name without a tag category'
    );

    assertNotNullOrUndefined(
      this.thing,
      'cannot change tag category name without a thing'
    );

    const oldTagCategoryName = event.detail.oldValue;

    this.tagCategory.name = String(event.detail.value ?? '');

    this.entityManager.thingRepository.update(this.thing);

    const tagsToFix = this.entityManager.tagRepository
      .getByThingId(this.thing.id)
      .filter((t) => t.category && oldTagCategoryName === t.category);

    this.changeCategoryOfTags(tagsToFix, this.tagCategory.name);
  }

  protected handleDisplayNameChanged(event: TTextChangedEvent): void {
    assertNotNullOrUndefined(
      this.tagCategory,
      'cannot change category display name without a tag category'
    );

    assertNotNullOrUndefined(
      this.thing,
      'cannot change tag category display name without a thing'
    );

    this.tagCategory.displayName = String(event.detail.value ?? '');

    this.entityManager.thingRepository.update(this.thing);
  }

  protected handleDeleteButtonClick(): void {
    const thing = this.thing;

    assertNotNullOrUndefined(
      thing,
      'cannot delete tag category without a thing'
    );

    void Dialogs.deleteDialogTk(
      'tagComponents.editTagCategoriesWidget.editTagCategoriesWidgetTagCategory.deleteCategory'
    ).then(() => {
      const removedTagCategoryIndex = thing.tagCategories.findIndex(
        (tagCategory) => tagCategory === this.tagCategory
      );

      if (removedTagCategoryIndex < 0) return;

      const removedTagCategories = thing.tagCategories.splice(
        removedTagCategoryIndex,
        1
      );

      this.entityManager.thingRepository.update(thing);

      const tagsToFix = this.entityManager.tagRepository
        .getByThingId(thing.id)
        .filter(
          (t) =>
            t.category &&
            removedTagCategories.map((c) => c.name).includes(t.category)
        );

      this.changeCategoryOfTags(tagsToFix);
    });
  }

  protected handleTagCreated(tagName: string): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot create new tag without a thing'
    );

    assertNotNullOrUndefined(
      this.tagCategory,
      'cannot create new tag without a tag category'
    );

    const existingTag = this.entityManager.tagRepository
      .getByThingId(this.thing.id)
      .find(
        (t) => TagHelper.getTagName(t.name) === TagHelper.getTagName(tagName)
      );

    if (existingTag) {
      const categoryName =
        existingTag.category ??
        this.i18n.tr(
          'tagComponents.editTagCategoriesWidget.editTagCategoriesWidgetTagCategory.emptyCategory'
        );

      NotificationHelper.notifyNeutral(
        this.i18n.tr(
          'tagComponents.editTagCategoriesWidget.editTagCategoriesWidgetTagCategory.tagAlreadyExists',
          {
            categoryName
          }
        )
      );
    } else {
      this.entityManager.tagRepository.create({
        name: tagName,
        ownerUserGroupId: this.thing.ownerUserGroupId,
        thing: this.thing.id,
        category: this.tagCategory.name
      });
    }
  }

  protected handleTagRemoved(tag: ThingTag): void {
    void Dialogs.deleteEntityDialog(tag).then(() => {
      this.entityManager.tagRepository.delete(tag);
    });
  }

  private changeCategoryOfTags(
    tags: Array<ThingTag>,
    newCategoryName?: string
  ): void {
    tags.forEach((t) =>
      this.entityManager.tagRepository.update({
        ...t,
        category: newCategoryName ?? null
      })
    );
  }
}
