import { autoinject, bindable } from 'aurelia-framework';

import { ActiveUserCompanySettingService } from '../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { activeUserCompanySetting } from '../../../hooks/dependencies';
import { watch } from '../../../hooks/watch';

@autoinject()
export class GalleryThingOverviewActionBarFilterIcon {
  @bindable public isFilterIconActive: boolean = true;

  private filterVisibilityWasSetByUser = false;

  constructor(
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {}

  protected handleFilterIconClick(): void {
    this.filterVisibilityWasSetByUser = true;
    this.isFilterIconActive = !this.isFilterIconActive;
  }

  @watch(activeUserCompanySetting('via.collapseFilterBarInViaAndDefectsView'))
  protected updateIsFilterIconActive(): void {
    if (this.filterVisibilityWasSetByUser) return;
    const setting: boolean =
      this.activeUserCompanySettingService.getSettingProperty(
        'via.collapseFilterBarInViaAndDefectsView'
      ) ?? false;
    this.isFilterIconActive = !setting;
  }
}
