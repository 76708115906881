import { bindable, autoinject } from 'aurelia-framework';

import { EntityName } from 'common/Types/BaseEntities/EntityName';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { SocketService } from '../../services/SocketService';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ReportTypeTemplateService } from '../../classes/EntityManager/entities/ReportType/ReportTypeTemplateService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ReportTypeListItem {
  @bindable()
  public reportType: ReportType | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly reportTypePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ReportType];

  protected listItemElement: HTMLElement | null = null;
  protected isOnline: boolean = false;

  constructor(
    private readonly element: Element,
    private readonly socketService: SocketService,
    private readonly entityManager: AppEntityManager,
    private readonly reportTypeTemplateService: ReportTypeTemplateService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.reportTypePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ReportType,
        context: this as ReportTypeListItem,
        propertyName: 'reportType'
      });
  }

  public highlight(): void {
    if (this.listItemElement) {
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
    }
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleDeleteReportType(): void {
    const reportType = this.reportType;
    assertNotNullOrUndefined(
      reportType,
      `can't ReportTypeListItem.handleDeleteReportType without reportType`
    );

    void Dialogs.deleteEntityDialog(reportType).then(() => {
      this.entityManager.reportTypeRepository.delete(reportType);
    });
  }

  protected handleEditReportTypeClick(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'edit-report-type-clicked',
      detail: null
    });
  }

  protected handleDownloadTemplateClick(): void {
    assertNotNullOrUndefined(
      this.reportType,
      `can't ReportTypeListItem.handleDownloadTemplate without reportType`
    );
    void this.reportTypeTemplateService.downloadTemplate(this.reportType);
  }

  protected formatToDate(time: string | null): string {
    return DateUtils.formatToDateString(time);
  }

  protected formatToTime(time: string | null): string {
    return DateUtils.formatToTimeString(time);
  }
}
