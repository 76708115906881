import { Observable } from 'rxjs';

import { createTypeExtendsValidator } from 'common/Types/typeUtils';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { AppEntityManagerEntityTypesByEntityName } from '../../classes/EntityManager/entities/AppEntityManagerEntityTypesByEntityName';
import { PictureFileByActivePictureRevisionService } from '../../classes/EntityManager/entities/PictureFile/PictureFileByActivePictureRevisionService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../SubscriptionManagerService';
import {
  EntityAdapter,
  EntityOfEntityAdapter
} from './EntityAdapter/EntityAdapter';
import { PictureAdapter } from './EntityAdapter/PictureAdapter/PictureAdapter';
import { PictureRevisionAdapter } from './EntityAdapter/PictureRevisionAdapter/PictureRevisionAdapter';
import { PersonAdapter } from './EntityAdapter/PersonAdapter/PersonAdapter';
import { ProcessTaskDeviceAdapter } from './EntityAdapter/ProcessTaskDeviceAdapter/ProcessTaskDeviceAdapter';
import { ProcessTaskOfferAdapter } from './EntityAdapter/ProcessTaskOfferAdapter/ProcessTaskOfferAdapter';
import { ProcessTaskPositionAdapter } from './EntityAdapter/ProcessTaskPositionAdapter/ProcessTaskPositionAdapter';
import { ProjectAdapter } from './EntityAdapter/ProjectAdapter/ProjectAdapter';
import { PropertyAdapter } from './EntityAdapter/PropertyAdapter/PropertyAdapter';
import { StructurePictureAreaAdapter } from './EntityAdapter/StructurePictureAreaAdapter/StructurePictureAreaAdapter';
import { ThingAdapter } from './EntityAdapter/ThingAdapter/ThingAdapter';
import { ThingSectionAdapter } from './EntityAdapter/ThingSectionAdapter/ThingSectionAdapter';
import { ThingSectionConfigPropertyAdapter } from './EntityAdapter/ThingSectionConfigPropertyAdapter/ThingSectionConfigPropertyAdapter';
import { ThingTypeAdapter } from './EntityAdapter/ThingTypeAdapter/ThingTypeAdapter';
import { EntityAdapterContainer } from './EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle/EntitySpecificPermissionsHandle';
import { PersonPermissionsHandle } from './EntitySpecificPermissionsHandle/PersonPermissionsHandle';
import { ProcessTaskDevicePermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessTaskDevicePermissionsHandle';
import { ProcessTaskOfferPermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessTaskOfferPermissionsHandle';
import { ProcessTaskPositionPermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessTaskPositionPermissionsHandle';
import { ProjectPermissionsHandle } from './EntitySpecificPermissionsHandle/ProjectPermissionsHandle';
import { ThingPermissionsHandle } from './EntitySpecificPermissionsHandle/ThingPermissionsHandle';
import { ThingSectionPermissionsHandle } from './EntitySpecificPermissionsHandle/ThingSectionPermissionsHandle';
import { ThingTypePermissionsHandle } from './EntitySpecificPermissionsHandle/ThingTypePermissionsHandle';
import { ReportAdapter } from './EntityAdapter/ReportAdapter/ReportAdapter';
import { ProcessTaskAdapter } from './EntityAdapter/ProcessTaskAdapter/ProcessTaskAdapter';
import { ProcessTaskPermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessTaskPermissionsHandle';
import { UserGroupAdapter } from './EntityAdapter/UserGroupAdapter/UserGroupAdapter';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ProcessTaskAppointmentAdapter } from './EntityAdapter/ProcessTaskAppointmentAdapter/ProcessTaskAppointmentAdapter';
import { ProcessTaskAppointmentPermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessTaskAppointmentPermissionsHandle';
import { ProcessConfigurationStepAdapter } from './EntityAdapter/ProcessConfigurationStepAdapter/ProcessConfigurationStepAdapter';
import { ProcessConfigurationStepPermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessConfigurationStepPermissionsHandle';
import { UserDefinedEntityConfigAdapter } from './EntityAdapter/UserDefinedEntityConfigAdapter/UserDefinedEntityConfigAdapter';
import { UserDefinedEntityConfigPermissionsHandle } from './EntitySpecificPermissionsHandle/UserDefinedEntityConfigPermissionsHandle';
import { UserDefinedEntityPermissionsHandle } from './EntitySpecificPermissionsHandle/UserDefinedEntityPermissionsHandle';
import { UserDefinedEntityAdapter } from './EntityAdapter/UserDefinedEntityAdapter/UserDefinedEntityAdapter';
import { PicturePermissionsHandle } from './EntitySpecificPermissionsHandle/PicturePermissionsHandle';
import { ReportPermissionsHandle } from './EntitySpecificPermissionsHandle/ReportPermissionHandle';
import { SocketService } from '../SocketService';
import { DefectAdapter } from './EntityAdapter/DefectAdapter/DefectAdapter';
import { DefectPermissionsHandle } from './EntitySpecificPermissionsHandle/DefectPermissionsHandle';
import { ThingGroupAdapter } from './EntityAdapter/ThingGroupAdapter/ThingGroupAdapter';
import { ThingGroupPermissionsHandle } from './EntitySpecificPermissionsHandle/ThingGroupPermissionsHandle';
import { ProcessConfigurationPermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessConfigurationPermissionsHandle';
import { ProcessConfigurationAdapter } from './EntityAdapter/ProcessConfigurationAdapter/ProcessConfigurationAdapter';
import { UserGroupPermissionsHandle } from './EntitySpecificPermissionsHandle/UserGroupPermissionsHandle';
import { UserRoleToUserAdapter } from './EntityAdapter/UserRoleToUserAdapter/UserRoleToUserAdapter';
import { UserRoleAdapter } from './EntityAdapter/UserRoleAdapter/UserRoleAdapter';
import { ProcessConfigurationFormSendTargetPermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessConfigurationFormSendTargetPermissionsHandle';
import { ProcessConfigurationFormSendTargetAdapter } from './EntityAdapter/ProcessConfigurationFormSendTargetAdapter/ProcessConfigurationFormSendTargetAdapter';
import { ProcessTaskGroupAdapter } from './EntityAdapter/ProcessTaskGroupAdapter/ProcessTaskGroupAdapter';
import { ThingToPersonAdapter } from './EntityAdapter/ThingToPersonAdapter/ThingToPersonAdapter';
import { ThingAuthorizationAdapter } from './EntityAdapter/ThingAuthorizationAdapter/ThingAuthorizationAdapter';
import { UserRolePermissionsHandle } from './EntitySpecificPermissionsHandle/UserRolePermissionsHandle';
import { EntryAdapter } from './EntityAdapter/EntryAdapter/EntryAdapter';
import { EntryPermissionsHandle } from './EntitySpecificPermissionsHandle/EntryPermissionsHandle';
import { TextBrickTemplateAdapter } from './EntityAdapter/TextBrickTemplateAdapter/TextBrickTemplateAdapter';
import { TextBrickAdapter } from './EntityAdapter/TextBrickAdapter/TextBrickAdapter';
import { ProcessConfigurationAuthorizationTypeAdapter } from './EntityAdapter/ProcessConfigurationAuthorizationTypeAdapter/ProcessConfigurationAuthorizationTypeAdapter';
import { ProcessTaskGroupAuthorizationAdapter } from './EntityAdapter/ProcessTaskGroupAuthorizationAdapter/ProcessTaskGroupAuthorizationAdapter';
import { ProcessTaskGroupPermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessTaskGroupPermissionsHandle';
import { ProcessTaskCommentAdapter } from './EntityAdapter/ProcessTaskCommentAdapter/ProcessTaskCommentAdapter';
import { ProcessTaskChecklistEntryAdapter } from './EntityAdapter/ProcessTaskChecklistEntryAdapter/ProcessTaskChecklistEntryAdapter';
import { ProcessTaskAppointmentToUserAdapter } from './EntityAdapter/ProcessTaskAppointmentToUserAdapter/ProcessTaskAppointmentToUserAdapter';
import { ProcessTaskAppointmentContactAdapter } from './EntityAdapter/ProcessTaskAppointmentContactAdapter/ProcessTaskAppointmentContactAdapter';
import { ProcessTaskRecurringAppointmentAdapter } from './EntityAdapter/ProcessTaskRecurringAppointmentAdapter/ProcessTaskRecurringAppointmentAdapter';
import { SharepointListColumnToThingMappingItemAdapter } from './EntityAdapter/SharepointListColumnToThingMappingItemAdapter/SharepointListColumnToThingMappingItemAdapter';
import { ProcessConfigurationActionStatusAdapter } from './EntityAdapter/ProcessConfigurationActionStatusAdapter/ProcessConfigurationActionStatusAdapter';
import { ProcessConfigurationCategoryAdapter } from './EntityAdapter/ProcessConfigurationCategoryAdapter/ProcessConfigurationCategoryAdapter';
import { ProcessConfigurationDeviceAdapter } from './EntityAdapter/ProcessConfigurationDeviceAdapter/ProcessConfigurationDeviceAdapter';
import { ProcessConfigurationDevicePermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessConfigurationDevicePermissionsHandle';
import { ProcessConfigurationDeviceExportAdapter } from './EntityAdapter/ProcessConfigurationDeviceExportAdapter/ProcessConfigurationDeviceExportAdapter';
import { ProcessConfigurationFollowUpAppointmentAdapter } from './EntityAdapter/ProcessConfigurationFollowUpAppointmentAdapter/ProcessConfigurationFollowUpAppointmentAdapter';
import { ProcessConfigurationFormAdapter } from './EntityAdapter/ProcessConfigurationFormAdapter/ProcessConfigurationFormAdapter';
import { ProcessConfigurationMeasurePointTypeAdapter } from './EntityAdapter/ProcessConfigurationMeasurePointTypeAdapter/ProcessConfigurationMeasurePointTypeAdapter';
import { ProcessConfigurationStepAutoAppointmentAdapter } from './EntityAdapter/ProcessConfigurationStepAutoAppointmentAdapter/ProcessConfigurationStepAutoAppointmentAdapter';
import { ProcessConfigurationStepAutoFormAdapter } from './EntityAdapter/ProcessConfigurationStepAutoFormAdapter/ProcessConfigurationStepAutoFormAdapter';
import { ProcessConfigurationStepPositionAdapter } from './EntityAdapter/ProcessConfigurationStepPositionAdapter/ProcessConfigurationStepPositionAdapter';
import { ProcessTaskMeasurePointReadingAdapter } from './EntityAdapter/ProcessTaskMeasurePointReadingAdapter/ProcessTaskMeasurePointReadingAdapter';
import { ProcessTaskPositionDetailEntryAdapter } from './EntityAdapter/ProcessTaskPositionDetailEntryAdapter/ProcessTaskPositionDetailEntryAdapter';
import { RegionAdapter } from './EntityAdapter/RegionAdapter/RegionAdapter';
import { PropertyPermissionsHandle } from './EntitySpecificPermissionsHandle/PropertyPermissionsHandle';
import { ProcessConfigurationStepPositionPermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessConfigurationStepPositionPermissionsHandle';
import { PropertyToPersonAdapter } from './EntityAdapter/PropertyToPersonAdapter/PropertyToPersonAdapter';
import { MapLayerAdapter } from './EntityAdapter/MapLayerAdapter/MapLayerAdapter';
import { DefectCommentAdapter } from './EntityAdapter/DefectCommentAdapter/DefectCommentAdapter';
import { GeneralFileAdapter } from './EntityAdapter/GeneralFileAdapter/GeneralFileAdapter';
import { MapLayerPermissionsHandle } from './EntitySpecificPermissionsHandle/MapLayerPermissionsHandle';
import { PictureFileAdapter } from './EntityAdapter/PictureFileAdapter/PictureFileAdapter';
import { ProjectQuestionAdapter } from './EntityAdapter/ProjectQuestionAdapter/ProjectQuestionAdapter';
import { ProjectQuestionPermissionsHandle } from './EntitySpecificPermissionsHandle/ProjectQuestionPermissionHandle';
import { PersonContactAdapter } from './EntityAdapter/PersonContactAdapter/PersonContactAdapter';
import { PersonToPersonAdapter } from './EntityAdapter/PersonToPersonAdapter/PersonToPersonAdapter';
import { NfcTokenToThingAdapter } from './EntityAdapter/NfcTokenToThingAdapter/NfcTokenToThingAdapter';
import { NfcTokenToPersonAdapter } from './EntityAdapter/NfcTokenToPersonAdapter/NfcTokenToPersonAdapter';
import { NfcTokenAdapter } from './EntityAdapter/NfcTokenAdapter/NfcTokenAdapter';
import { ProcessTaskInvoiceAdapter } from './EntityAdapter/ProcessTaskInvoiceAdapter/ProcessTaskInvoiceAdapter';
import { ProcessTaskInvoicePermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessTaskInvoicePermissionsHandle';
import { ProcessTaskInvoiceToProcessTaskAdapter } from './EntityAdapter/ProcessTaskInvoiceToProcessTaskAdapter/ProcessTaskInvoiceToProcessTaskAdapter';
import { ProcessTaskInvoiceToProcessTaskDeviceAdapter } from './EntityAdapter/ProcessTaskInvoiceToProcessTaskDeviceAdapter/ProcessTaskInvoiceToProcessTaskDeviceAdapter';
import { ProcessTaskInvoiceToProcessTaskPositionAdapter } from './EntityAdapter/ProcessTaskInvoiceToProcessTaskPositionAdapter/ProcessTaskInvoiceToProcessTaskPositionAdapter';
import { ProcessTaskOfferToProcessTaskAdapter } from './EntityAdapter/ProcessTaskOfferToProcessTaskAdapter/ProcessTaskOfferToProcessTaskAdapter';
import { ProcessTaskOfferToProcessTaskDeviceAdapter } from './EntityAdapter/ProcessTaskOfferToProcessTaskDeviceAdapter/ProcessTaskOfferToProcessTaskDeviceAdapter';
import { ProcessTaskOfferToProcessTaskPositionAdapter } from './EntityAdapter/ProcessTaskOfferToProcessTaskPositionAdapter/ProcessTaskOfferToProcessTaskPositionAdapter';
import { ProcessTaskMeasurePointAdapter } from './EntityAdapter/ProcessTaskMeasurePointAdapter/ProcessTaskMeasurePointAdapter';
import { ProcessTaskMeasurePointPermissionsHandle } from './EntitySpecificPermissionsHandle/ProcessTaskMeasurePointPermissionsHandle';
import { ProcessTaskMeasurePointToPictureAdapter } from './EntityAdapter/ProcessTaskMeasurePointToPictureAdapter/ProcessTaskMeasurePointToPictureAdapter';
import { ProcessConfigurationNoteCategoryAdapter } from './EntityAdapter/ProcessConfigurationNoteCategoryAdapter/ProcessConfigurationNoteCategoryAdapter';
import { ProcessTaskNoteAdapter } from './EntityAdapter/ProcessTaskNoteAdapter/ProcessTaskNoteAdapter';
import { ProcessTaskAppointmentToProcessTaskPositionAdapter } from './EntityAdapter/ProcessTaskAppointmentToProcessTaskPositionAdapter/ProcessTaskAppointmentToProcessTaskPositionAdapter';
import { ProcessTaskAppointmentToProcessTaskDeviceAdapter } from './EntityAdapter/ProcessTaskAppointmentToProcessTaskDeviceAdapter/ProcessTaskAppointmentToProcessTaskDeviceAdapter';
import { ProcessTaskGroupFilterAdapter } from './EntityAdapter/ProcessTaskGroupFilterAdapter/ProcessTaskGroupFilterAdapter';
import { StructureTemplateAdapter } from './EntityAdapter/StructureTemplateAdapter/StructureTemplateAdapter';
import { StructureTemplatePermissionsHandle } from './EntitySpecificPermissionsHandle/StructureTemplatePermissionsHandle';
import { StructureTemplateRatingCategoryAdapter } from './EntityAdapter/StructureTemplateRatingCategoryAdapter/StructureTemplateRatingCategoryAdapter';
import { StructureTemplateEntryAdapter } from './EntityAdapter/StructureTemplateEntryAdapter/StructureTemplateEntryAdapter';
import { StructureTemplateEntryPermissionsHandle } from './EntitySpecificPermissionsHandle/StructureTemplateEntryPermissionsHandle';
import { StructureTemplateEntryGroupAdapter } from './EntityAdapter/StructureTemplateEntryGroupAdapter/StructureTemplateEntryGroupAdapter';
import { StructureTemplateEntryGroupToStructureTemplateEntryAdapter } from './EntityAdapter/StructureTemplateEntryGroupToStructureTemplateEntryAdapter/StructureTemplateEntryGroupToStructureTemplateEntryAdapter';
import { StructureTemplateEntryGroupPermissionsHandle } from './EntitySpecificPermissionsHandle/StructureTemplateEntryGroupPermissionsHandle';
import { StructureTemplateEntryPropertyAdapter } from './EntityAdapter/StructureTemplateEntryPropertyAdapter/StructureTemplateEntryPropertyAdapter';
import { EntryToPersonAdapter } from './EntityAdapter/EntryToPersonAdapter/EntryToPersonAdapter';
import { PropertyToProcessTaskPositionAdapter } from './EntityAdapter/PropertyToProcessTaskPositionAdapter/PropertyToProcessTaskPositionAdapter';
import { UserDefinedEntityConfigPropertyConfigAdapter } from './EntityAdapter/UserDefinedEntityConfigPropertyConfigAdapter/UserDefinedEntityConfigPropertyConfigAdapter';
import { TagAdapter } from './EntityAdapter/TagAdapter/TagAdapter';
import { ReportTypeAdapter } from './EntityAdapter/ReportTypeAdapter/ReportTypeAdapter';
import { ReportTypePermissionsHandle } from './EntitySpecificPermissionsHandle/ReportTypePermissionsHandle';

const supportedEntityNames = [
  EntityName.Defect,
  EntityName.DefectComment,
  EntityName.Entry,
  EntityName.EntryToPerson,
  EntityName.GeneralFile,
  EntityName.MapLayer,
  EntityName.NfcToken,
  EntityName.NfcTokenToPerson,
  EntityName.NfcTokenToThing,
  EntityName.Person,
  EntityName.PersonContact,
  EntityName.PersonToPerson,
  EntityName.Picture,
  EntityName.PictureFile,
  EntityName.PictureRevision,
  EntityName.ProcessConfiguration,
  EntityName.ProcessConfigurationActionStatus,
  EntityName.ProcessConfigurationAuthorizationType,
  EntityName.ProcessConfigurationCategory,
  EntityName.ProcessConfigurationDevice,
  EntityName.ProcessConfigurationDeviceExport,
  EntityName.ProcessConfigurationFollowUpAppointment,
  EntityName.ProcessConfigurationForm,
  EntityName.ProcessConfigurationFormSendTarget,
  EntityName.ProcessConfigurationMeasurePointType,
  EntityName.ProcessConfigurationNoteCategory,
  EntityName.ProcessConfigurationStep,
  EntityName.ProcessConfigurationStepAutoAppointment,
  EntityName.ProcessConfigurationStepAutoForm,
  EntityName.ProcessConfigurationStepPosition,
  EntityName.ProcessTask,
  EntityName.ProcessTaskAppointment,
  EntityName.ProcessTaskAppointmentContact,
  EntityName.ProcessTaskAppointmentToProcessTaskDevice,
  EntityName.ProcessTaskAppointmentToProcessTaskPosition,
  EntityName.ProcessTaskAppointmentToUser,
  EntityName.ProcessTaskChecklistEntry,
  EntityName.ProcessTaskComment,
  EntityName.ProcessTaskDevice,
  EntityName.ProcessTaskGroup,
  EntityName.ProcessTaskGroupAuthorization,
  EntityName.ProcessTaskGroupFilter,
  EntityName.ProcessTaskInvoice,
  EntityName.ProcessTaskInvoiceToProcessTask,
  EntityName.ProcessTaskInvoiceToProcessTaskDevice,
  EntityName.ProcessTaskInvoiceToProcessTaskPosition,
  EntityName.ProcessTaskMeasurePoint,
  EntityName.ProcessTaskMeasurePointReading,
  EntityName.ProcessTaskMeasurePointToPicture,
  EntityName.ProcessTaskNote,
  EntityName.ProcessTaskOffer,
  EntityName.ProcessTaskOfferToProcessTask,
  EntityName.ProcessTaskOfferToProcessTaskDevice,
  EntityName.ProcessTaskOfferToProcessTaskPosition,
  EntityName.ProcessTaskPosition,
  EntityName.ProcessTaskPositionDetailEntry,
  EntityName.ProcessTaskRecurringAppointment,
  EntityName.Project,
  EntityName.ProjectQuestion,
  EntityName.Property,
  EntityName.PropertyToPerson,
  EntityName.PropertyToProcessTaskPosition,
  EntityName.Region,
  EntityName.Report,
  EntityName.ReportType,
  EntityName.SharepointListColumnToThingMappingItem,
  EntityName.StructurePictureArea,
  EntityName.StructureTemplate,
  EntityName.StructureTemplateEntry,
  EntityName.StructureTemplateEntryGroup,
  EntityName.StructureTemplateEntryGroupToStructureTemplateEntry,
  EntityName.StructureTemplateEntryProperty,
  EntityName.StructureTemplateRatingCategory,
  EntityName.Tag,
  EntityName.TextBrick,
  EntityName.TextBrickTemplate,
  EntityName.Thing,
  EntityName.ThingAuthorization,
  EntityName.ThingGroup,
  EntityName.ThingSection,
  EntityName.ThingSectionConfigProperty,
  EntityName.ThingToPerson,
  EntityName.ThingType,
  EntityName.UserDefinedEntity,
  EntityName.UserDefinedEntityConfig,
  EntityName.UserDefinedEntityConfigPropertyConfig,
  EntityName.UserGroup,
  EntityName.UserRole,
  EntityName.UserRoleToUser
] as const;

export type SupportedEntityName = (typeof supportedEntityNames)[number];

const entityNameToAdapterFactory =
  createTypeExtendsValidator<EntityNameToAdapterFactoryConstraint>()({
    [EntityName.Defect]: ({
      computedValueService,
      subscriptionManagerService,
      currentUserService
    }) => {
      return new DefectAdapter({
        computedValueService,
        subscriptionManagerService,
        currentUserService
      });
    },
    [EntityName.DefectComment]: ({
      entityManager,
      currentUserService,
      computedValueService,
      subscriptionManagerService
    }) => {
      return new DefectCommentAdapter({
        entityManager,
        currentUserService,
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.Entry]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new EntryAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.EntryToPerson]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new EntryToPersonAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.GeneralFile]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new GeneralFileAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.MapLayer]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new MapLayerAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.NfcToken]: ({ entityManager }) => {
      return new NfcTokenAdapter({
        entityManager
      });
    },
    [EntityName.NfcTokenToPerson]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new NfcTokenToPersonAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.NfcTokenToThing]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new NfcTokenToThingAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.Person]: ({
      computedValueService,
      subscriptionManagerService,
      activeUserCompanySettingService
    }) => {
      return new PersonAdapter({
        computedValueService,
        subscriptionManagerService,
        activeUserCompanySettingService
      });
    },
    [EntityName.PersonContact]: ({
      entityManager,
      activeUserCompanySettingService,
      computedValueService,
      subscriptionManagerService
    }) => {
      return new PersonContactAdapter({
        entityManager,
        activeUserCompanySettingService,
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.PersonToPerson]: ({
      entityManager,
      computedValueService,
      subscriptionManagerService,
      activeUserCompanySettingService
    }) => {
      return new PersonToPersonAdapter({
        entityManager,
        computedValueService,
        subscriptionManagerService,
        activeUserCompanySettingService
      });
    },
    [EntityName.Picture]: ({
      entityManager,
      pictureFileByActivePictureRevisionService,
      subscriptionManagerService,
      socketService
    }) => {
      return new PictureAdapter({
        entityManager,
        pictureFileByActivePictureRevisionService,
        subscriptionManagerService,
        socketService
      });
    },
    [EntityName.PictureFile]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new PictureFileAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.PictureRevision]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new PictureRevisionAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfiguration]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationActionStatus]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationActionStatusAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationAuthorizationType]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationAuthorizationTypeAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationCategory]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationCategoryAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationDevice]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationDeviceAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationDeviceExport]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationDeviceExportAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationFollowUpAppointment]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationFollowUpAppointmentAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationForm]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationFormAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationFormSendTarget]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationFormSendTargetAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationMeasurePointType]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationMeasurePointTypeAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationNoteCategory]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationNoteCategoryAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationStep]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationStepAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationStepAutoAppointment]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationStepAutoAppointmentAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationStepAutoForm]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationStepAutoFormAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessConfigurationStepPosition]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessConfigurationStepPositionAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTask]: ({
      computedValueService,
      subscriptionManagerService,
      activeUserCompanySettingService
    }) => {
      return new ProcessTaskAdapter({
        computedValueService,
        subscriptionManagerService,
        activeUserCompanySettingService
      });
    },
    [EntityName.ProcessTaskAppointment]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskAppointmentAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskAppointmentContact]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskAppointmentContactAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskAppointmentToProcessTaskDevice]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskAppointmentToProcessTaskDeviceAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskAppointmentToProcessTaskPosition]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskAppointmentToProcessTaskPositionAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskAppointmentToUser]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskAppointmentToUserAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskChecklistEntry]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskChecklistEntryAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskComment]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskCommentAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskDevice]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskDeviceAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskGroup]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskGroupAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskGroupAuthorization]: ({
      computedValueService,
      subscriptionManagerService,
      currentUserService
    }) => {
      return new ProcessTaskGroupAuthorizationAdapter({
        computedValueService,
        subscriptionManagerService,
        currentUserService
      });
    },
    [EntityName.ProcessTaskGroupFilter]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskGroupFilterAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskInvoice]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskInvoiceAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskInvoiceToProcessTask]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new ProcessTaskInvoiceToProcessTaskAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskInvoiceToProcessTaskDevice]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new ProcessTaskInvoiceToProcessTaskDeviceAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskInvoiceToProcessTaskPosition]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new ProcessTaskInvoiceToProcessTaskPositionAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskMeasurePoint]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskMeasurePointAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskMeasurePointReading]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskMeasurePointReadingAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskMeasurePointToPicture]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new ProcessTaskMeasurePointToPictureAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskNote]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskNoteAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskOffer]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskOfferAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskOfferToProcessTask]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new ProcessTaskOfferToProcessTaskAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskOfferToProcessTaskDevice]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new ProcessTaskOfferToProcessTaskDeviceAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskOfferToProcessTaskPosition]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new ProcessTaskOfferToProcessTaskPositionAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskPosition]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskPositionAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskPositionDetailEntry]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new ProcessTaskPositionDetailEntryAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.ProcessTaskRecurringAppointment]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProcessTaskRecurringAppointmentAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.Project]: ({
      computedValueService,
      subscriptionManagerService,
      activeUserCompanySettingService,
      currentUserService
    }) => {
      return new ProjectAdapter({
        computedValueService,
        subscriptionManagerService,
        activeUserCompanySettingService,
        currentUserService
      });
    },
    [EntityName.ProjectQuestion]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ProjectQuestionAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.Property]: ({ entityManager, subscriptionManagerService }) => {
      return new PropertyAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.PropertyToPerson]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new PropertyToPersonAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.PropertyToProcessTaskPosition]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new PropertyToProcessTaskPositionAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.Region]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new RegionAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.Report]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ReportAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ReportType]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ReportTypeAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.SharepointListColumnToThingMappingItem]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new SharepointListColumnToThingMappingItemAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.StructurePictureArea]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new StructurePictureAreaAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.StructureTemplate]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new StructureTemplateAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.StructureTemplateEntry]: ({
      entityManager,
      computedValueService,
      subscriptionManagerService
    }) => {
      return new StructureTemplateEntryAdapter({
        entityManager,
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.StructureTemplateEntryGroup]: ({
      entityManager,
      computedValueService,
      subscriptionManagerService
    }) => {
      return new StructureTemplateEntryGroupAdapter({
        entityManager,
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.StructureTemplateEntryGroupToStructureTemplateEntry]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new StructureTemplateEntryGroupToStructureTemplateEntryAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.StructureTemplateEntryProperty]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new StructureTemplateEntryPropertyAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.StructureTemplateRatingCategory]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new StructureTemplateRatingCategoryAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.Tag]: ({
      computedValueService,
      currentUserService,
      subscriptionManagerService
    }) => {
      return new TagAdapter({
        computedValueService,
        currentUserService,
        subscriptionManagerService
      });
    },
    [EntityName.TextBrick]: ({
      computedValueService,
      subscriptionManagerService,
      entityManager,
      currentUserService
    }) => {
      return new TextBrickAdapter({
        computedValueService,
        subscriptionManagerService,
        entityManager,
        currentUserService
      });
    },
    [EntityName.TextBrickTemplate]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new TextBrickTemplateAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.Thing]: ({
      computedValueService,
      subscriptionManagerService,
      activeUserCompanySettingService,
      currentUserService
    }) => {
      return new ThingAdapter({
        computedValueService,
        subscriptionManagerService,
        activeUserCompanySettingService,
        currentUserService
      });
    },
    [EntityName.ThingAuthorization]: ({
      computedValueService,
      subscriptionManagerService,
      currentUserService
    }) => {
      return new ThingAuthorizationAdapter({
        computedValueService,
        subscriptionManagerService,
        currentUserService
      });
    },
    [EntityName.ThingGroup]: ({
      subscriptionManagerService,
      computedValueService
    }) => {
      return new ThingGroupAdapter({
        subscriptionManagerService,
        computedValueService
      });
    },
    [EntityName.ThingSection]: ({
      computedValueService,
      subscriptionManagerService,
      activeUserCompanySettingService
    }) => {
      return new ThingSectionAdapter({
        computedValueService,
        subscriptionManagerService,
        activeUserCompanySettingService
      });
    },
    [EntityName.ThingSectionConfigProperty]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ThingSectionConfigPropertyAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ThingToPerson]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new ThingToPersonAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.ThingType]: ({
      computedValueService,
      subscriptionManagerService,
      currentUserService
    }) => {
      return new ThingTypeAdapter({
        computedValueService,
        subscriptionManagerService,
        currentUserService
      });
    },
    [EntityName.UserDefinedEntity]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new UserDefinedEntityAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.UserDefinedEntityConfig]: ({
      computedValueService,
      subscriptionManagerService
    }) => {
      return new UserDefinedEntityConfigAdapter({
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.UserDefinedEntityConfigPropertyConfig]: ({
      entityManager,
      subscriptionManagerService
    }) => {
      return new UserDefinedEntityConfigPropertyConfigAdapter({
        entityManager,
        subscriptionManagerService
      });
    },
    [EntityName.UserGroup]: ({
      currentUserService,
      subscriptionManagerService,
      computedValueService
    }) => {
      return new UserGroupAdapter({
        currentUserService,
        subscriptionManagerService,
        computedValueService
      });
    },
    [EntityName.UserRole]: ({
      entityManager,
      subscriptionManagerService,
      computedValueService
    }) => {
      return new UserRoleAdapter({
        entityManager,
        computedValueService,
        subscriptionManagerService
      });
    },
    [EntityName.UserRoleToUser]: ({
      subscriptionManagerService,
      computedValueService
    }) => {
      return new UserRoleToUserAdapter({
        computedValueService,
        subscriptionManagerService
      });
    }
  });

const entityNameToPermissionsHandleFactory =
  createTypeExtendsValidator<EntityNameToPermissionsHandleFactoryConstraint>()({
    [EntityName.Defect]: ({ adapterContainer, entity$ }) => {
      return new DefectPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.DefectComment]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.Entry]: ({ adapterContainer, entity$ }) => {
      return new EntryPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.EntryToPerson]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.GeneralFile]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.MapLayer]: ({ adapterContainer, entity$ }) => {
      return new MapLayerPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.NfcToken]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.NfcTokenToPerson]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.NfcTokenToThing]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.Person]: ({ adapterContainer, entity$ }) => {
      return new PersonPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.PersonContact]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.PersonToPerson]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.Picture]: ({ adapterContainer, entity$ }) => {
      return new PicturePermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.PictureFile]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.PictureRevision]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfiguration]: ({ adapterContainer, entity$ }) => {
      return new ProcessConfigurationPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationActionStatus]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationAuthorizationType]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationCategory]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationDevice]: ({
      adapterContainer,
      entity$
    }) => {
      return new ProcessConfigurationDevicePermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationDeviceExport]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationFollowUpAppointment]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationForm]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationFormSendTarget]: ({
      adapterContainer,
      entity$
    }) => {
      return new ProcessConfigurationFormSendTargetPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationMeasurePointType]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationNoteCategory]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationStep]: ({ adapterContainer, entity$ }) => {
      return new ProcessConfigurationStepPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationStepAutoAppointment]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationStepAutoForm]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessConfigurationStepPosition]: ({
      adapterContainer,
      entity$
    }) => {
      return new ProcessConfigurationStepPositionPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTask]: ({ adapterContainer, entity$ }) => {
      return new ProcessTaskPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskAppointment]: ({ adapterContainer, entity$ }) => {
      return new ProcessTaskAppointmentPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskAppointmentContact]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskAppointmentToProcessTaskDevice]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskAppointmentToProcessTaskPosition]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskAppointmentToUser]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskChecklistEntry]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskComment]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskDevice]: ({ adapterContainer, entity$ }) => {
      return new ProcessTaskDevicePermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskGroup]: ({ adapterContainer, entity$ }) => {
      return new ProcessTaskGroupPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskGroupAuthorization]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskGroupFilter]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskInvoice]: ({ adapterContainer, entity$ }) => {
      return new ProcessTaskInvoicePermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskInvoiceToProcessTask]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskInvoiceToProcessTaskDevice]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskInvoiceToProcessTaskPosition]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskMeasurePoint]: ({ adapterContainer, entity$ }) => {
      return new ProcessTaskMeasurePointPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskMeasurePointReading]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskMeasurePointToPicture]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskNote]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskOffer]: ({ adapterContainer, entity$ }) => {
      return new ProcessTaskOfferPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskOfferToProcessTask]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskOfferToProcessTaskDevice]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskOfferToProcessTaskPosition]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskPosition]: ({ adapterContainer, entity$ }) => {
      return new ProcessTaskPositionPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskPositionDetailEntry]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProcessTaskRecurringAppointment]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.Project]: ({ adapterContainer, entity$ }) => {
      return new ProjectPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ProjectQuestion]: ({ adapterContainer, entity$ }) => {
      return new ProjectQuestionPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.Property]: ({ adapterContainer, entity$ }) => {
      return new PropertyPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.PropertyToPerson]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.PropertyToProcessTaskPosition]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.Region]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.Report]: ({ adapterContainer, entity$ }) => {
      return new ReportPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ReportType]: ({ adapterContainer, entity$ }) => {
      return new ReportTypePermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.SharepointListColumnToThingMappingItem]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.StructurePictureArea]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.StructureTemplate]: ({ adapterContainer, entity$ }) => {
      return new StructureTemplatePermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.StructureTemplateEntry]: ({ adapterContainer, entity$ }) => {
      return new StructureTemplateEntryPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.StructureTemplateEntryGroup]: ({
      adapterContainer,
      entity$
    }) => {
      return new StructureTemplateEntryGroupPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.StructureTemplateEntryGroupToStructureTemplateEntry]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.StructureTemplateEntryProperty]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.StructureTemplateRatingCategory]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.Tag]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.TextBrick]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.TextBrickTemplate]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.Thing]: ({ adapterContainer, entity$ }) => {
      return new ThingPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ThingAuthorization]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ThingGroup]: ({ adapterContainer, entity$ }) => {
      return new ThingGroupPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ThingSection]: ({ adapterContainer, entity$ }) => {
      return new ThingSectionPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ThingSectionConfigProperty]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ThingToPerson]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.ThingType]: ({ adapterContainer, entity$ }) => {
      return new ThingTypePermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.UserDefinedEntity]: ({ adapterContainer, entity$ }) => {
      return new UserDefinedEntityPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.UserDefinedEntityConfig]: ({ adapterContainer, entity$ }) => {
      return new UserDefinedEntityConfigPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.UserDefinedEntityConfigPropertyConfig]: ({
      adapterContainer,
      entity$
    }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.UserGroup]: ({ adapterContainer, entity$ }) => {
      return new UserGroupPermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.UserRole]: ({ adapterContainer, entity$ }) => {
      return new UserRolePermissionsHandle({
        adapterContainer,
        entity$
      });
    },
    [EntityName.UserRoleToUser]: ({ adapterContainer, entity$ }) => {
      return new EntitySpecificPermissionsHandle({
        adapterContainer,
        entity$
      });
    }
  });

export const entityNameToPermissionsConfig =
  createEntityNameToPermissionsConfig();

function createEntityNameToPermissionsConfig(): EntityNameToPermissionsConfig {
  const partialConfig: Partial<EntityNameToPermissionsConfig> = {};

  for (const entityName of supportedEntityNames) {
    partialConfig[entityName] = {
      createAdapter: entityNameToAdapterFactory[entityName] as any,
      createPermissionsHandle: entityNameToPermissionsHandleFactory[
        entityName
      ] as any
    };
  }

  return partialConfig as EntityNameToPermissionsConfig;
}

export type EntityNameToPermissionsConfig = {
  [TEntityName in SupportedEntityName]: {
    createAdapter: (typeof entityNameToAdapterFactory)[TEntityName];
    createPermissionsHandle: (typeof entityNameToPermissionsHandleFactory)[TEntityName];
  };
};

export type CreateAdapterOptions = {
  entityManager: AppEntityManager;
  computedValueService: ComputedValueService;
  currentUserService: CurrentUserService;
  subscriptionManagerService: SubscriptionManagerService;
  activeUserCompanySettingService: ActiveUserCompanySettingService;
  socketService: SocketService;
  pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService;
};

export type EntityNameToAdapter = {
  [key in SupportedEntityName]: ReturnType<
    (typeof entityNameToAdapterFactory)[key]
  >;
};

export type EntityNameToAdapterContainer = {
  [key in SupportedEntityName]: EntityAdapterContainer<
    EntityNameToAdapter[key]
  >;
};

export type EntityNameToPermissionsHandle = {
  [key in SupportedEntityName]: ReturnType<
    (typeof entityNameToPermissionsHandleFactory)[key]
  >;
};

type EntityNameToAdapterFactoryConstraint = {
  [TEntityName in SupportedEntityName]: (
    options: CreateAdapterOptions
  ) => EntityAdapter<
    AppEntityManagerEntityTypesByEntityName[TEntityName]['entity']
  >;
};

type EntityNameToPermissionsHandleFactoryConstraint = {
  [TEntityName in SupportedEntityName]: (options: {
    adapterContainer: EntityAdapterContainer<EntityNameToAdapter[TEntityName]>;
    entity$: Observable<
      EntityOfEntityAdapter<EntityNameToAdapter[TEntityName]> | null | undefined
    >;
  }) => EntitySpecificPermissionsHandle<
    EntityAdapterContainer<EntityNameToAdapter[TEntityName]>
  >;
};
