import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  GetEntityInfos,
  PictureCreatorService
} from '../../classes/Picture/PictureCreatorService';
import { SocketService } from '../../services/SocketService';
import { EmbeddedCamera } from '../../aureliaComponents/embedded-camera/embedded-camera';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { CreateMapPictureDialog } from '../../dialogs/create-map-picture-dialog/create-map-picture-dialog';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { UploadChoiceSelectedEvent } from '../picture-upload-area/picture-upload-area';
import {
  PictureEntityIdField,
  PictureSubEntityField
} from '../../classes/EntityManager/entities/Picture/types';

/**
 * @event picture-uploaded triggered when a new picture is uploaded
 */
@autoinject()
export class NoPictureUploadPlaceholder {
  @bindable public mainEntityIdField: PictureEntityIdField | null = null;
  @bindable public mainEntityId: string | null = null;

  @bindable public subEntityField: PictureSubEntityField | null = null;
  @bindable public subEntityValue: any = null;

  @bindable public ownerUserGroupId: string | null = null;
  @bindable public ownerProjectId: string | null = null;

  @bindable public urfmChoiceEnabled = true;

  private domElement: HTMLElement;

  private subscriptionManager: SubscriptionManager;

  protected isApp = false;
  protected isConnected = false;

  constructor(
    element: Element,
    private readonly socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly pictureCreatorService: PictureCreatorService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isApp = DeviceInfoHelper.isApp();

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected async handleUploadChoiceSelected(
    event: UploadChoiceSelectedEvent
  ): Promise<void> {
    switch (event.detail.method) {
      case 'camera':
        this.handleCameraChoice();
        break;
      case 'sketch':
        this.handleSketchChoice();
        break;
      case 'map':
        this.handleMapPictureChoice();
        break;
      case 'file':
        const files =
          event.detail.files && event.detail.files.length >= 1
            ? event.detail.files
            : null;
        if (files) {
          await this.handleFileChoice(files);
        }
        break;

      default:
        console.error('unknown choice', event.detail);
        break;
    }
  }

  private sendPictureUploadedEvent(
    pictureIdToDataUrlMap: Record<string, string>
  ): void {
    DomEventHelper.fireEvent<PictureUploadedEvent>(this.domElement, {
      name: 'picture-uploaded',
      detail: { pictureIdToDataUrlMap }
    });
  }

  private handleCameraChoice(): void {
    void EmbeddedCamera.capturePicture({
      getEntityInfos: this.createGetEntityInfos(),
      onCapture: (picture, dataUrl) =>
        this.sendPictureUploadedEvent(picture ? { [picture.id]: dataUrl } : {})
    });
  }

  private handleSketchChoice(): void {
    const creator = this.pictureCreatorService.withEntityInfos(
      this.createGetEntityInfos()
    );
    const { picture, dataUrl } = creator.createWhitePicture();
    this.sendPictureUploadedEvent({ [picture.id]: dataUrl });
  }

  private handleMapPictureChoice(): void {
    void CreateMapPictureDialog.open({
      getEntityInfos: this.createGetEntityInfos(),
      onCapture: (picture, dataUrl) =>
        this.sendPictureUploadedEvent({ [picture.id]: dataUrl })
    });
  }

  private async handleFileChoice(files: Array<File>): Promise<void> {
    const creator = this.pictureCreatorService.withEntityInfos(
      this.createGetEntityInfos()
    );

    const pictureIdToDataUrlMap: Record<string, string> = {};
    for (const file of files) {
      const data = await creator.createPictureFromFile(file);
      if (data) {
        pictureIdToDataUrlMap[data.picture.id] = data.dataUrl;
      }
    }

    this.sendPictureUploadedEvent(pictureIdToDataUrlMap);
  }

  private createGetEntityInfos(): GetEntityInfos {
    return () => {
      assertNotNullOrUndefined(
        this.mainEntityIdField,
        "can't getEntityInfos without a mainEntityIdField"
      );
      assertNotNullOrUndefined(
        this.mainEntityId,
        "can't getEntityInfos without a mainEntityId"
      );
      assertNotNullOrUndefined(
        this.ownerUserGroupId,
        "can't getEntityInfos without a ownerUserGroupId"
      );

      return {
        mainEntityIdField: this.mainEntityIdField,
        mainEntityId: this.mainEntityId,
        subEntityField: this.subEntityField,
        subEntityValue: this.subEntityValue,
        ownerProjectId: this.ownerProjectId,
        ownerUserGroupId: this.ownerUserGroupId
      };
    };
  }
}

export type PictureUploadedEvent = NamedCustomEvent<
  'picture-uploaded',
  {
    pictureIdToDataUrlMap: Record<string, string>;
  }
>;
