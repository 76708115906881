import { autoinject, observable, computedFrom } from 'aurelia-framework';
import { NavigationInstruction, RouteConfig, Router } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';

import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { FilterHelper } from '../../classes/FilterHelper';

import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import { GlobalData } from '../../classes/GlobalData';
import { GlobalMenu } from '../../aureliaComponents/global-menu/global-menu';
import { EditProjectDialog } from '../../dialogs/edit-project-dialog/edit-project-dialog';
import { ExportMultipleProjectsDialog } from '../../dialogs/export-multiple-projects-dialog/export-multiple-projects-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingExportService } from '../../classes/EntityManager/entities/Thing/ThingExportService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AssignReportTypeToProjectService } from '../../classes/EntityManager/entities/Project/AssignReportTypeToProjectService';
import { SocketService } from '../../services/SocketService';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import {
  MoreButtonChoice,
  MoreButtonFileChangedEvent
} from '../../aureliaComponents/more-button/more-button';
import { ParameterPanel } from '../../aureliaComponents/parameter-panel/parameter-panel';
import { CreateProjectClickedEvent } from '../../aureliaComponents/create-project-button/create-project-button';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SorterSortOption } from '../../aureliaAttributes/sorter';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { User } from '../../classes/EntityManager/entities/User/types';
import { ModuleName } from '../../classes/RecordItModuleHelper';
import { ProjectCsvImporterService } from '../../services/ProjectCsvImporterService';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { ProjectCreationService } from '../../classes/EntityManager/entities/Project/ProjectCreationService';
import { FilterMode } from '../../filterComponents/archive-filter/archive-filter';
import { ThingViewType } from '../../aureliaComponents/switch-thing-type-button/switch-thing-type-button';
import { ExportThingsAsCsvDialog } from '../../dialogs/export-things-as-csv-dialog/export-things-as-csv-dialog';
import { ProjectActionService } from '../../classes/EntityManager/entities/Project/ProjectActionService';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import {
  CallbackParamsWithoutUserGroup,
  ImportFromCsvFileDialog
} from '../../dialogs/import-from-csv-file-dialog/import-from-csv-file-dialog';
import {
  GlobalUserDefinedEntity,
  UserDefinedEntity
} from '../../classes/EntityManager/entities/UserDefinedEntity/types';
import {
  FieldInfosFromConfig,
  FieldType,
  ParsedLineData,
  ValidateCallbackResult
} from '../../aureliaComponents/csv-import-widget/csv-import-widget';
import { UserDefinedEntityCreationService } from '../../classes/EntityManager/entities/UserDefinedEntity/UserDefinedEntityCreationService';
import { PropertyImporter } from '../../classes/CsvImporter/PropertyImporter';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { ManageValueCalculationConfigDefinitionsWidgetThingAdapter } from '../../valueCalculationComponents/manage-value-calculation-config-definitions-widget/ManageValueCalculationConfigDefinitionsWidgetAdapter/ManageValueCalculationConfigDefinitionsWidgetThingAdapter/ManageValueCalculationConfigDefinitionsWidgetThingAdapter';
import { ManageValueCalculationConfigDefinitionsWidgetProjectAdapter } from '../../valueCalculationComponents/manage-value-calculation-config-definitions-widget/ManageValueCalculationConfigDefinitionsWidgetAdapter/ManageValueCalculationConfigDefinitionsWidgetProjectAdapter/ManageValueCalculationConfigDefinitionsWidgetProjectAdapter';
import { NavigationService } from '../../services/NavigationService';
import { ThingValueCalculationResultAdapter } from '../../valueCalculationComponents/ValueCalculationResultAdapter/ThingValueCalculationResultAdapter';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { UserDefinedEntityConfig } from '../../classes/EntityManager/entities/UserDefinedEntityConfig/types';
import { SelectableItemList } from '../../aureliaComponents/selectable-item-list/selectable-item-list';
import { Inspect3dImporterService } from '../../services/Inspect3dImporterService';

@autoinject()
export class edit_projects {
  @observable private availableProjects: Array<Project>;
  protected filteredProjects: Array<Project> = [];
  protected searchStringFilteredProjects: Array<Project> = [];
  protected projectTypeFilteredProjects: Array<Project> = [];
  protected reportTypeFilteredProjects: Array<Project> = [];
  protected sortedProjects: Array<Project> = [];
  protected selectedProjects: Array<Project> = [];
  protected switchThingTypeButtonShown = false;

  protected ThingViewType = ThingViewType;

  private pagination: HTMLElement | null = null;

  @observable private projectFilterString: string;
  protected sortOptions = GlobalData.projectSortOptions;

  protected currentSortOption: SorterSortOption<Project> =
    GlobalData.projectSortOptions.name;

  private isAttached = false;

  @observable private thing: Thing | null = null;

  @observable private projectType: ProjectType | null;

  @observable private isMobile: boolean;

  @observable protected archiveFilterMode: FilterMode;

  private isOnline = false;

  protected showThingProperties = false;
  protected showUserDefinedEntities = false;
  protected showDependentValueCalculations = false;
  protected propertiesParameterPanelOpen = false;
  protected userDefinedEntitiesParameterPanelOpen = false;
  protected valueCalculationParameterPanelOpen = false;

  protected valueCalculationsThingAdapter: ManageValueCalculationConfigDefinitionsWidgetThingAdapter | null =
    null;

  protected valueCalculationsProjectAdapter: ManageValueCalculationConfigDefinitionsWidgetProjectAdapter | null =
    null;

  protected valueCalculationResultAdapter: ThingValueCalculationResultAdapter | null =
    null;

  private moreButtonChoices: Array<MoreButtonChoice> = [];
  private commonMoreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'generalPages.editProjects.exportThingPicturesAsZipFile',
      name: 'export-pictures-of-thing',
      iconClass: 'fal fa-file-archive',
      disabledContext: this,
      disabledPropertyName: 'exportPicturesOfThingDisabled'
    },
    {
      labelTk: 'generalPages.editProjects.exportAllProjects',
      name: 'export-all-projects',
      iconClass: 'fal fa-share-all',
      disabledContext: this,
      disabledPropertyName: 'exportAllProjectsDisabled'
    },
    {
      labelTk: 'generalPages.editProjects.downloadNewestReportsAsZipFile',
      name: 'download-latest-reports',
      iconClass: 'fal fa-download',
      disabledContext: this,
      disabledPropertyName: 'downloadLatestReportsDisabled'
    },
    {
      labelTk: 'generalPages.editProjects.exportProjectsWithContentToCsvFile',
      name: 'export-projects-with-content-to-csv',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'exportProjectsWithContentToCsvDisabled'
    },
    {
      labelTk: 'generalPages.editProjects.importFromCsv',
      name: 'import-user-defined-entities-from-csv',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'importUserDefinedEntitiesFromCsvDisabled',
      isFileInput: true,
      fileInputAccept: 'text/plain,text/comma-separated-values,.csv'
    }
  ];

  private csvImportFields: FieldInfosFromConfig<FieldInfoConfiguration> = [
    {
      field: 'userDefinedEntityConfigName',
      headerTk:
        'generalPages.editUserDefinedEntities.csvColumns.userDefinedEntityConfig',
      type: FieldType.STRING,
      validateCallback: this.validateUserDefinedEntityConfigColumn.bind(this),
      required: true,
      validationRequired: true,
      extraField: true
    },
    {
      field: 'customId',
      headerTk: 'generalPages.editUserDefinedEntities.csvColumns.customId',
      type: FieldType.STRING,
      validateCallback: this.validateCustomIds.bind(this),
      required: true,
      validationRequired: true
    },
    {
      field: 'name',
      headerTk: 'generalPages.editUserDefinedEntities.csvColumns.name',
      type: FieldType.STRING,
      required: true,
      validationRequired: false
    }
  ];

  private subscriptionManager: SubscriptionManager;

  @computedFrom('isOnline')
  private get exportPicturesOfThingDisabled(): boolean {
    return !this.isOnline;
  }

  @computed(expression('permissionsHandle.canCreateProjects'))
  private get importProjectsDisabled(): boolean {
    return !this.permissionsHandle.canCreateProjects;
  }

  @computedFrom('isOnline')
  private get downloadLatestReportsDisabled(): boolean {
    return !this.isOnline;
  }

  @computedFrom('isOnline', 'availableProjects.length')
  private get exportAllProjectsDisabled(): boolean {
    return !this.isOnline || this.availableProjects.length === 0;
  }

  @computedFrom('isOnline', 'availableProjects.length')
  private get exportProjectsWithContentToCsvDisabled(): boolean {
    return !this.isOnline || this.availableProjects.length === 0;
  }

  @computedFrom('isOnline', 'permissionsHandle.canCreateUserDefinedEntities')
  protected get importUserDefinedEntitiesFromCsvDisabled(): boolean {
    return (
      !this.isOnline || !this.permissionsHandle.canCreateUserDefinedEntities
    );
  }

  private propertiesParameterPanelViewModel: ParameterPanel | null = null;
  private userDefinedEntitiesParameterPanelViewModel: ParameterPanel | null =
    null;

  private valueCalculationParameterPanelViewModel: ParameterPanel | null = null;

  private currentUser: User | null = null;

  protected ProjectType = ProjectType;

  private readonly propertyImporter: PropertyImporter;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  protected selectableItemList: SelectableItemList<Project, unknown> | null =
    null;

  constructor(
    private router: Router,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private activeEntitiesService: ActiveEntitiesService,
    private entityManager: AppEntityManager,
    private thingExportService: ThingExportService,
    private socketService: SocketService,
    private assignReportTypeToProjectService: AssignReportTypeToProjectService,
    private currentUserService: CurrentUserService,
    private readonly projectCsvImporterService: ProjectCsvImporterService,
    private readonly inspect3DImporterService: Inspect3dImporterService,
    private readonly projectCreationService: ProjectCreationService,
    private readonly i18n: I18N,
    private readonly projectActionService: ProjectActionService,
    private readonly userDefinedCreationService: UserDefinedEntityCreationService,
    private readonly permissionsService: PermissionsService,
    private readonly navigationService: NavigationService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Thing,
        context: this,
        expression: 'thing'
      });

    this.availableProjects = [];
    this.projectFilterString = '';
    this.isMobile = true;
    this.projectType = null;
    this.archiveFilterMode = FilterMode.FILTER_MODE_SHOW_NO_ARCHIVED;
    this.propertyImporter = new PropertyImporter({ entityManager });
  }

  private updateAvailableProjects(): void {
    if (this.thing) {
      this.availableProjects =
        this.entityManager.projectRepository.getByThingIdWithoutOperationsProjects(
          this.thing.id
        );
    } else {
      this.availableProjects = [];
    }
  }

  private updateMoreButtonChoices(): void {
    const moreButtonChoices = [...this.commonMoreButtonChoices];
    if (
      this.currentUser &&
      PermissionHelper.userHasPermissionForModule(
        this.currentUser,
        ModuleName.BASIC
      )
    ) {
      moreButtonChoices.push(
        this.getMoreButtonChoiceForCsvImport(
          'import-basic-projects-from-csv',
          'importBasicProjectsFromCsvFile'
        )
      );
    }
    if (
      this.currentUser &&
      PermissionHelper.userHasPermissionForModule(
        this.currentUser,
        ModuleName.B1300
      )
    ) {
      moreButtonChoices.push(
        this.getMoreButtonChoiceForCsvImport(
          'import-b1300-projects-from-csv',
          'importB1300ProjectsFromCsvFile'
        )
      );
    }
    if (
      this.currentUser &&
      PermissionHelper.userHasPermissionForModule(
        this.currentUser,
        ModuleName.INSPECT
      )
    ) {
      moreButtonChoices.push(
        this.getMoreButtonChoiceForCsvImport(
          'import-inspect-projects-from-csv',
          'importInspectProjectsFromCsvFile'
        )
      );
      if (this.currentUser.permissions.canUseInspect3dImport)
        moreButtonChoices.push({
          labelTk: `generalPages.editProjects.importInspectProjectsFromInspect3d`,
          name: 'import-inspect-projects-from-inspect3d',
          iconClass: 'fal fa-file-zipper',
          disabledContext: this,
          disabledPropertyName: 'importProjectsDisabled',
          isFileInput: true,
          fileInputAccept: 'application/zip,.zip',
          fileInputMultiple: true
        });
    }
    this.moreButtonChoices = moreButtonChoices;
    GlobalMenu.setChoices(this, moreButtonChoices);
  }

  private getMoreButtonChoiceForCsvImport(
    name: string,
    labelTk: string
  ): MoreButtonChoice {
    return {
      labelTk: `generalPages.editProjects.${labelTk}`,
      name: name,
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'importProjectsDisabled',
      isFileInput: true,
      fileInputAccept: 'text/plain,text/comma-separated-values,.csv'
    };
  }

  private handleCreateProjectClick(event: CreateProjectClickedEvent): void {
    if (!this.thing) {
      return;
    }

    let project: Project;
    if (event.detail.projectType === ProjectType.BASIC) {
      project = this.projectCreationService.createProject({
        thing: this.thing,
        projectType: event.detail.projectType,
        reportType: event.detail.reportType ?? null
      });
    } else {
      project = this.projectCreationService.createProject({
        thing: this.thing,
        projectType: event.detail.projectType,
        structureTemplateId: event.detail.structureTemplate.id,
        reportType: event.detail.reportType ?? null
      });
    }

    if (event.detail.reportType)
      this.assignReportTypeToProjectService.assignReportTypeToProject(
        project,
        event.detail.reportType
      );

    this.updateAvailableProjects();
    this.editProject(project);
  }

  private handleExportPicturesOfThingClick(): void {
    if (!this.thing) return;
    this.thingExportService.exportPicturesOfThingIdAsZipFile(this.thing.id);
  }

  // ++++++++++ View Helper ++++++++++

  private getUserGroupName(usergroup: string): string | null {
    const group = this.entityManager.userGroupRepository.getById(usergroup);
    return group ? group.name : 'Gruppe nicht gefunden';
  }

  @computedFrom('currentUser.permissions')
  protected get defaultThingType(): ThingViewType {
    if (!this.currentUser) return ThingViewType.THING;
    const modules = PermissionHelper.getAvailableModulesForUser(
      this.currentUser
    );
    if (modules[0] === ModuleName.VIA && modules.length === 1)
      return ThingViewType.GALLERY;
    if (modules[0] === ModuleName.CHECKLIST && modules.length === 1)
      return ThingViewType.CHECKLIST;

    return ThingViewType.THING;
  }

  // ++++++++++ lifecycle ++++++++++

  protected activate(params: any, routeConfig: RouteConfig): void {
    void DataStorageHelper.getItem('lastThingRoute').then((thingRoute) => {
      if (VALID_LAST_ROUTES.includes(thingRoute)) {
        this.router.navigateToRoute(thingRoute, params, {
          replace: true,
          trigger: true
        });
      }
    });

    this.thing = this.entityManager.thingRepository.getById(params.thing_id);

    if (!this.thing) {
      throw new Error(`thing ${params.thing_id} couldn't be found`);
    }

    this.activeEntitiesService.setActiveThing(this.thing);

    if (routeConfig.navModel && this.thing?.name)
      routeConfig.navModel.title =
        this.i18n.tr(routeConfig.navModel.title) + ' - ' + this.thing.name;
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      this.updateAvailableProjects.bind(this)
    );
    this.updateAvailableProjects();

    if (
      this.router.currentInstruction.queryParams.open_parameter_panel &&
      this.propertiesParameterPanelViewModel
    ) {
      this.propertiesParameterPanelViewModel.expand();
    }

    this.subscriptionManager.subscribeToPropertyChange(
      this.router,
      'currentInstruction',
      (currentInstruction) => {
        this.getFilterSettingsFromCurrentInstruction(currentInstruction);
      }
    );
    this.getFilterSettingsFromCurrentInstruction(
      this.router.currentInstruction
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged((user) => {
        this.currentUser = user;
        this.updateMoreButtonChoices();
      })
    );
    this.currentUser = this.currentUserService.getCurrentUser();
    this.updateMoreButtonChoices();

    GlobalMenu.takeControl(this, {
      visible: this.isMobile,
      choices: this.moreButtonChoices,
      selectedCallbacks: {
        'export-pictures-of-thing':
          this.handleExportPicturesOfThingClick.bind(this),
        'export-all-projects': () => {
          void this.handleExportAllProjectsClick();
        },
        'download-latest-reports': () => {
          void this.handleDownloadLatestReportsClick();
        },
        'export-projects-with-content-to-csv':
          this.handleExportProjectsWithContentAsCsvFileClick.bind(this)
      },
      fileChangedCallbacks: {
        'import-basic-projects-from-csv': (event) => {
          void this.handleImportProjectsFromCsvFileChanged(
            event,
            ProjectType.BASIC
          );
        },
        'import-b1300-projects-from-csv': (event) => {
          void this.handleImportProjectsFromCsvFileChanged(
            event,
            ProjectType.B1300
          );
        },
        'import-inspect-projects-from-csv': (event) => {
          void this.handleImportProjectsFromCsvFileChanged(
            event,
            ProjectType.INSPECT
          );
        },
        'import-inspect-projects-from-inspect3d': (event) => {
          void this.handleImportProjectsFromInspect3dChanged(event);
        }
      }
    });

    if (this.thing) {
      this.valueCalculationResultAdapter =
        new ThingValueCalculationResultAdapter({
          entityManager: this.entityManager,
          subscriptionManagerService: this.subscriptionManagerService,
          baseEntity: this.thing
        });
    }
  }

  protected detached(): void {
    this.isAttached = false;

    GlobalMenu.releaseControl(this);

    this.valueCalculationResultAdapter = null;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected deactivate(): void {
    this.activeEntitiesService.setActiveThing(null);
  }

  private async handleImportProjectsFromCsvFileChanged(
    event: MoreButtonFileChangedEvent<
      'import-basic-projects-from-csv' | 'import-structure-projects-from-csv'
    >['detail'],
    projectType: ProjectType
  ): Promise<void> {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files[0] && this.isOnline) {
      if (!this.thing) return;
      await this.projectCsvImporterService.importProjectsFromCsvFile(
        inputElement.files[0],
        projectType,
        this.thing
      );
      inputElement.value = '';
    }
  }

  private async handleImportProjectsFromInspect3dChanged(
    event: MoreButtonFileChangedEvent<'import-inspect-projects-from-inspect3d'>['detail']
  ): Promise<void> {
    const inputElement = event.target as HTMLInputElement;
    const projectZipFile = inputElement.files ? inputElement.files[0] : null;
    if (!this.thing || !projectZipFile) return;

    void this.inspect3DImporterService.importInspect3dProject({
      projectZipFile,
      thing: this.thing
    });

    inputElement.value = '';
  }

  private async handleExportAllProjectsClick(): Promise<void> {
    void ExportMultipleProjectsDialog.open({
      projectsToExport: this.availableProjects
    });
  }

  private async handleDownloadLatestReportsClick(): Promise<void> {
    if (!this.thing) return;
    await this.thingExportService.exportLatestReportsOfThingIdAsZipFile(
      this.thing.id
    );
  }

  private handleExportProjectsWithContentAsCsvFileClick(): void {
    if (!this.thing) return;
    void ExportThingsAsCsvDialog.open({
      thingIds: [this.thing.id],
      filterForProjectIds: this.selectedProjects.map((p) => p.id)
    });
  }

  private handleFilterProjects(): void {
    this.searchStringFilteredProjects = FilterHelper.filterItems(
      this.availableProjects,
      GlobalData.projectFilterFunction,
      this.projectFilterString
    );
  }

  protected isMobileChanged(): void {
    if (GlobalMenu.hasControl(this)) {
      GlobalMenu.setVisible(this, this.isMobile);
    }
  }

  protected availableProjectsChanged(): void {
    this.handleFilterProjects();
  }

  protected projectFilterStringChanged(): void {
    this.handleFilterProjects();
  }

  protected projectTypeChanged(): void {
    if (this.isAttached) this.saveFilterSettingsInRouteHistory();
  }

  protected thingChanged(): void {
    if (this.thing) {
      this.valueCalculationsThingAdapter =
        new ManageValueCalculationConfigDefinitionsWidgetThingAdapter({
          entityManager: this.entityManager,
          subscriptionManagerService: this.subscriptionManagerService,
          permissionsService: this.permissionsService,
          thing: this.thing
        });

      this.valueCalculationsProjectAdapter =
        new ManageValueCalculationConfigDefinitionsWidgetProjectAdapter({
          entityManager: this.entityManager,
          subscriptionManagerService: this.subscriptionManagerService,
          permissionsService: this.permissionsService,
          thing: this.thing
        });
    } else {
      this.valueCalculationsThingAdapter = null;
      this.valueCalculationsProjectAdapter = null;
    }
  }

  private handleEnterProjectClick(project: Project): void {
    this.projectActionService.navigateToProject(project);
  }

  private handleEditProjectClick(project: Project): void {
    this.editProject(project);
  }

  private handleTogglePropertiesClick(): void {
    if (this.propertiesParameterPanelViewModel)
      void this.propertiesParameterPanelViewModel.toggle();
  }

  private handleToggleUserDefinedEntitiesClick(): void {
    if (this.userDefinedEntitiesParameterPanelViewModel)
      void this.userDefinedEntitiesParameterPanelViewModel.toggle();
  }

  private handleToggleDependentValueCalculationsClick(): void {
    if (this.valueCalculationParameterPanelViewModel)
      void this.valueCalculationParameterPanelViewModel.toggle();
  }

  private handleShowProjectPropertiesClick(project: Project): void {
    this.projectActionService.navigateToProject(project, {
      open_parameter_panel: true
    });
  }

  private handleExportProjectClick(project: Project): void {
    this.router.navigate(this.navigationService.getExportPageUrl(project));
  }

  private editProject(project: Project): void {
    void EditProjectDialog.open({
      project: project,
      onDialogClosed: (p) => {
        this.goToProject(p);
      }
    });
  }

  private goToProject(project: Project): void {
    void this.selectableItemList?.goToItem(
      '#edit-projects--project-' + project.id,
      project
    );
  }

  protected async handleImportUserDefinedEntitiesFromCsvFileChanged(
    event: MoreButtonFileChangedEvent<any>['detail']
  ): Promise<void> {
    if (!event.target.files?.[0]) return;

    await ImportFromCsvFileDialog.open<FieldInfoConfiguration>({
      file: event.target.files[0],
      entityNameTk: 'models.UserDefinedEntityModel_plural',
      fields: this.csvImportFields,
      showOverwriteCheckbox: true,
      overwriteDefaultValue: true,
      overwriteCheckboxInfoText:
        'generalPages.editProjects.overwriteCheckboxInfoText',
      showUserGroupSelect: false,
      showAdditionalFieldsAsParametersCheckbox: true,
      importFromCsvFileCallback:
        this.handleImportUserDefinedEntitiesFromCsvFileSubmitted.bind(this)
    });

    event.target.value = '';
  }

  protected async handleImportUserDefinedEntitiesFromCsvFileSubmitted(
    detail: CallbackParamsWithoutUserGroup<FieldInfoConfiguration>
  ): Promise<void> {
    const lineData = detail.parsedContent;
    assertNotNullOrUndefined(
      this.thing,
      'cannot import data elements into thing without thing'
    );

    if (detail.overwrite) {
      const { deletedUserDefinedEntities } =
        await this.deleteExistingUserDefinedEntitiesIfAllowed({
          thing: this.thing
        });

      if (!deletedUserDefinedEntities) {
        console.warn(
          'aborting importing because not all UserDefinedEntities could be deleted'
        );
        return;
      }
    }

    const userDefinedEntityConfigs =
      this.entityManager.userDefinedEntityConfigRepository.getByUserGroupId(
        this.thing.ownerUserGroupId
      );

    const globalUserDefinedEntities =
      this.entityManager.userDefinedEntityRepository.getGlobalByOwnerUserGroupId(
        this.thing.ownerUserGroupId
      );

    for (const lD of lineData) {
      const config = userDefinedEntityConfigs.find(
        (x) => x.name === lD.fields.userDefinedEntityConfigName
      );
      if (!config) {
        throw new Error(
          'cannot find entity config with name ' +
            lD.fields.userDefinedEntityConfigName
        );
      }

      const existingThingUserDefinedEntity =
        this.entityManager.userDefinedEntityRepository
          .getByThingId(this.thing.id)
          .find((x) => {
            return x.customId === lD.fields.customId;
          });

      if (!config.allowDuplicates && existingThingUserDefinedEntity) return;

      await this.createUserDefinedEntityIfAllowed({
        thing: this.thing,
        lD,
        globalUserDefinedEntities,
        config,
        detail
      });
    }
  }

  private async deleteExistingUserDefinedEntitiesIfAllowed({
    thing
  }: {
    thing: Thing;
  }): Promise<{ deletedUserDefinedEntities: boolean }> {
    const existingThingUserDefinedEntities =
      this.entityManager.userDefinedEntityRepository.getByThingId(thing.id);

    if (existingThingUserDefinedEntities.length === 0) {
      return { deletedUserDefinedEntities: true };
    }

    const canDeleteEveryUserDefinedEntity =
      await this.permissionsService.useAdapterOnce({
        entityName: EntityName.UserDefinedEntity,
        useAdapter: (adapter) => {
          return existingThingUserDefinedEntities.every((userDefinedEntity) => {
            return adapter.canDeleteEntity(userDefinedEntity);
          });
        }
      });

    if (!canDeleteEveryUserDefinedEntity) {
      return { deletedUserDefinedEntities: false };
    }

    for (const existingEntity of existingThingUserDefinedEntities) {
      this.entityManager.userDefinedEntityRepository.delete(existingEntity);
    }

    return { deletedUserDefinedEntities: true };
  }

  private async createUserDefinedEntityIfAllowed({
    thing,
    lD,
    config,
    globalUserDefinedEntities,
    detail
  }: {
    thing: Thing;
    lD: ParsedLineData<FieldInfoConfiguration>;
    config: UserDefinedEntityConfig;
    globalUserDefinedEntities: Array<GlobalUserDefinedEntity>;
    detail: CallbackParamsWithoutUserGroup<FieldInfoConfiguration>;
  }): Promise<void> {
    const canCreateUserDefinedEntities =
      await this.permissionsService.useAdapterOnce({
        entityName: EntityName.Thing,
        useAdapter: (adapter) => {
          return adapter.canCreateUserDefinedEntities(thing);
        }
      });

    if (!canCreateUserDefinedEntities) {
      return;
    }

    const globalEntity = globalUserDefinedEntities.find(
      (x) => x.customId === lD.fields.customId
    );

    if (globalEntity) {
      this.createUserDefinedEntity({
        lD: lD,
        globalEntity: globalEntity,
        thing: thing,
        detail: detail
      });
    } else {
      this.createUserDefinedEntityWithoutTemplate({
        lD: lD,
        userDefinedEntityConfigId: config.id,
        thing: thing,
        detail: detail
      });
    }
  }

  private createUserDefinedEntityWithoutTemplate({
    lD,
    userDefinedEntityConfigId,
    thing,
    detail
  }: {
    lD: ParsedLineData<FieldInfoConfiguration>;
    userDefinedEntityConfigId: string;
    thing: Thing;
    detail: CallbackParamsWithoutUserGroup<FieldInfoConfiguration>;
  }): void {
    const userDefinedEntity =
      this.userDefinedCreationService.createThingUserDefinedEntityWithoutTemplate(
        {
          name: lD.fields.name ?? '',
          customId: lD.fields.customId ?? '',
          ownerUserGroupId: thing.ownerUserGroupId,
          globalUserDefinedEntityId: null,
          userDefinedEntityConfigId,
          thingId: thing.id
        }
      );

    if (detail.fieldsAsProperties) {
      this.updatePropertyValues({
        userDefinedEntity,
        lineData: lD,
        detail
      });
    }
  }

  private createUserDefinedEntity({
    lD,
    globalEntity,
    thing,
    detail
  }: {
    lD: ParsedLineData<FieldInfoConfiguration>;
    globalEntity: GlobalUserDefinedEntity;
    thing: Thing;
    detail: CallbackParamsWithoutUserGroup<FieldInfoConfiguration>;
  }): void {
    const userDefinedEntity =
      this.userDefinedCreationService.createThingUserDefinedEntity(
        globalEntity,
        thing
      );

    if (detail.fieldsAsProperties) {
      this.updatePropertyValues({
        userDefinedEntity,
        lineData: lD,
        detail
      });
    }
  }

  private updatePropertyValues({
    userDefinedEntity,
    lineData,
    detail
  }: {
    userDefinedEntity: UserDefinedEntity;
    lineData: ParsedLineData<FieldInfoConfiguration>;
    detail: CallbackParamsWithoutUserGroup<FieldInfoConfiguration>;
  }): void {
    this.propertyImporter.import({
      properties:
        this.entityManager.propertyRepository.getByUserDefinedEntityId(
          userDefinedEntity.id
        ),
      propertyValues: lineData.additionalFields,
      decimalSeparator: detail.decimalSeparator
    });
  }

  private validateUserDefinedEntityConfigColumn(
    parsedData: Array<ParsedLineData<FieldInfoConfiguration>>
  ): ValidateCallbackResult {
    const names = this.entityManager.userDefinedEntityConfigRepository
      .getAll()
      .map((g) => g.name);

    const valid = parsedData.every((data) =>
      names.includes(data.fields.userDefinedEntityConfigName ?? '')
    );

    return {
      valid,
      errorMsgTk: valid
        ? null
        : 'generalPages.editProjects.invalidUserDefinedEntityConfigNames'
    };
  }

  private validateCustomIds(
    parsedData: Array<ParsedLineData<FieldInfoConfiguration>>
  ): ValidateCallbackResult {
    let result: ValidateCallbackResult = { valid: true };
    for (const data of parsedData) {
      result = this.validateCustomId(data.fields, parsedData);
      if (!result.valid) return result;
    }

    return result;
  }

  private validateCustomId(
    fields: Record<string, string>,
    parsedData: Array<ParsedLineData<FieldInfoConfiguration>>
  ): ValidateCallbackResult {
    if (!fields.customId)
      return {
        valid: false,
        errorMsgTk: 'generalPages.editProjects.missingCustomId'
      };
    if (!fields.userDefinedEntityConfigName)
      return {
        valid: false,
        errorMsgTk:
          'generalPages.editProjects.missingUserDefinedEntityConfigNames'
      };

    const userDefinedEntityConfig =
      this.entityManager.userDefinedEntityConfigRepository.getByName(
        fields.userDefinedEntityConfigName
      );

    if (!userDefinedEntityConfig)
      return {
        valid: false,
        errorMsgTk:
          'generalPages.editProjects.invalidUserDefinedEntityConfigNames'
      };
    if (userDefinedEntityConfig.allowDuplicates) return { valid: true };

    const idOccurences = parsedData.filter(
      (x) => x.fields.customId === fields.customId
    );
    if (idOccurences.length > 1)
      return {
        valid: false,
        errorMsgTk: 'generalPages.editProjects.duplicateCustomId'
      };
    return { valid: true };
  }

  private saveFilterSettingsInRouteHistory(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot save route params without thing'
    );
    assertNotNullOrUndefined(
      this.router.currentInstruction.config.name,
      'current route has no name'
    );

    this.router.navigateToRoute(
      this.router.currentInstruction.config.name,
      {
        thing_id: this.thing.id,
        projectType: this.projectType
      },
      { trigger: true, replace: false }
    );
  }

  private getFilterSettingsFromCurrentInstruction(
    currentInstruction: NavigationInstruction
  ): void {
    this.projectType = currentInstruction.queryParams['projectType'] || null;
  }
}

type FieldInfoConfiguration = {
  entityType: UserDefinedEntity;
  fieldInfos: [
    {
      field: 'userDefinedEntityConfigName';
      type: FieldType.STRING;
    },
    {
      field: 'customId';
      type: FieldType.STRING;
    },
    {
      field: 'name';
      type: FieldType.STRING;
    }
  ];
};

const VALID_LAST_ROUTES = ['edit_gallery_object', 'object'];
