import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { UserRoleDto } from './UserRoleDto';

export function createUserRoleEntityInfo<
  TEntity extends UserRoleDto<string, string>
>(
  options: CreateEntityInfoOptions<UserRoleDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.UserRole,
  TEntity
> {
  return {
    entityName: EntityName.UserRole,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      UserRoleDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        automaticallyAssignToNewUsers: {
          name: 'automaticallyAssignToNewUsers',
          defaultValue: false
        },
        canSeeEntitiesWithoutProcessTaskGroupAuthorization: {
          name: 'canSeeEntitiesWithoutProcessTaskGroupAuthorization',
          defaultValue: false
        },
        canSeeEntitiesWithoutThingAuthorization: {
          name: 'canSeeEntitiesWithoutThingAuthorization',
          defaultValue: false
        },
        canSeePersonsWithoutExplicitAuthorization: {
          name: 'canSeePersonsWithoutExplicitAuthorization',
          defaultValue: false
        },
        canCreateDefectComments: {
          name: 'canCreateDefectComments',
          defaultValue: false
        },
        canUpdateDefectComments: {
          name: 'canUpdateDefectComments',
          defaultValue: false
        },
        canDeleteDefectComments: {
          name: 'canDeleteDefectComments',
          defaultValue: false
        },
        canCreateDefects: {
          name: 'canCreateDefects',
          defaultValue: false
        },
        canUpdateDefects: {
          name: 'canUpdateDefects',
          defaultValue: false
        },
        canDeleteDefects: {
          name: 'canDeleteDefects',
          defaultValue: false
        },
        canCreateEntries: {
          name: 'canCreateEntries',
          defaultValue: false
        },
        canUpdateEntries: {
          name: 'canUpdateEntries',
          defaultValue: false
        },
        canDeleteEntries: {
          name: 'canDeleteEntries',
          defaultValue: false
        },
        canCreateMapLayers: {
          name: 'canCreateMapLayers',
          defaultValue: false
        },
        canUpdateMapLayers: {
          name: 'canUpdateMapLayers',
          defaultValue: false
        },
        canDeleteMapLayers: {
          name: 'canDeleteMapLayers',
          defaultValue: false
        },
        canCreatePersonContacts: {
          name: 'canCreatePersonContacts',
          defaultValue: false
        },
        canUpdatePersonContacts: {
          name: 'canUpdatePersonContacts',
          defaultValue: false
        },
        canDeletePersonContacts: {
          name: 'canDeletePersonContacts',
          defaultValue: false
        },
        canCreatePersons: {
          name: 'canCreatePersons',
          defaultValue: false
        },
        canUpdatePersons: {
          name: 'canUpdatePersons',
          defaultValue: false
        },
        canDeletePersons: {
          name: 'canDeletePersons',
          defaultValue: false
        },
        canCreatePersonToPersons: {
          name: 'canCreatePersonToPersons',
          defaultValue: false
        },
        canUpdatePersonToPersons: {
          name: 'canUpdatePersonToPersons',
          defaultValue: false
        },
        canDeletePersonToPersons: {
          name: 'canDeletePersonToPersons',
          defaultValue: false
        },
        canCreateProcessConfigurations: {
          name: 'canCreateProcessConfigurations',
          defaultValue: false
        },
        canUpdateProcessConfigurations: {
          name: 'canUpdateProcessConfigurations',
          defaultValue: false
        },
        canDeleteProcessConfigurations: {
          name: 'canDeleteProcessConfigurations',
          defaultValue: false
        },
        canCreateProcessConfigurationActionStates: {
          name: 'canCreateProcessConfigurationActionStates',
          defaultValue: false
        },
        canUpdateProcessConfigurationActionStates: {
          name: 'canUpdateProcessConfigurationActionStates',
          defaultValue: false
        },
        canDeleteProcessConfigurationActionStates: {
          name: 'canDeleteProcessConfigurationActionStates',
          defaultValue: false
        },
        canCreateProcessConfigurationAuthorizationTypes: {
          name: 'canCreateProcessConfigurationAuthorizationTypes',
          defaultValue: false
        },
        canUpdateProcessConfigurationAuthorizationTypes: {
          name: 'canUpdateProcessConfigurationAuthorizationTypes',
          defaultValue: false
        },
        canDeleteProcessConfigurationAuthorizationTypes: {
          name: 'canDeleteProcessConfigurationAuthorizationTypes',
          defaultValue: false
        },
        canCreateProcessConfigurationCategories: {
          name: 'canCreateProcessConfigurationCategories',
          defaultValue: false
        },
        canUpdateProcessConfigurationCategories: {
          name: 'canUpdateProcessConfigurationCategories',
          defaultValue: false
        },
        canDeleteProcessConfigurationCategories: {
          name: 'canDeleteProcessConfigurationCategories',
          defaultValue: false
        },
        canCreateProcessConfigurationDevices: {
          name: 'canCreateProcessConfigurationDevices',
          defaultValue: false
        },
        canUpdateProcessConfigurationDevices: {
          name: 'canUpdateProcessConfigurationDevices',
          defaultValue: false
        },
        canDeleteProcessConfigurationDevices: {
          name: 'canDeleteProcessConfigurationDevices',
          defaultValue: false
        },
        canCreateProcessConfigurationDeviceExports: {
          name: 'canCreateProcessConfigurationDeviceExports',
          defaultValue: false
        },
        canUpdateProcessConfigurationDeviceExports: {
          name: 'canUpdateProcessConfigurationDeviceExports',
          defaultValue: false
        },
        canDeleteProcessConfigurationDeviceExports: {
          name: 'canDeleteProcessConfigurationDeviceExports',
          defaultValue: false
        },
        canCreateProcessConfigurationFollowUpAppointments: {
          name: 'canCreateProcessConfigurationFollowUpAppointments',
          defaultValue: false
        },
        canUpdateProcessConfigurationFollowUpAppointments: {
          name: 'canUpdateProcessConfigurationFollowUpAppointments',
          defaultValue: false
        },
        canDeleteProcessConfigurationFollowUpAppointments: {
          name: 'canDeleteProcessConfigurationFollowUpAppointments',
          defaultValue: false
        },
        canCreateProcessConfigurationForms: {
          name: 'canCreateProcessConfigurationForms',
          defaultValue: false
        },
        canUpdateProcessConfigurationForms: {
          name: 'canUpdateProcessConfigurationForms',
          defaultValue: false
        },
        canDeleteProcessConfigurationForms: {
          name: 'canDeleteProcessConfigurationForms',
          defaultValue: false
        },
        canCreateProcessConfigurationFormSendTargets: {
          name: 'canCreateProcessConfigurationFormSendTargets',
          defaultValue: false
        },
        canUpdateProcessConfigurationFormSendTargets: {
          name: 'canUpdateProcessConfigurationFormSendTargets',
          defaultValue: false
        },
        canDeleteProcessConfigurationFormSendTargets: {
          name: 'canDeleteProcessConfigurationFormSendTargets',
          defaultValue: false
        },
        canCreateProcessConfigurationMeasurePointTypes: {
          name: 'canCreateProcessConfigurationMeasurePointTypes',
          defaultValue: false
        },
        canUpdateProcessConfigurationMeasurePointTypes: {
          name: 'canUpdateProcessConfigurationMeasurePointTypes',
          defaultValue: false
        },
        canDeleteProcessConfigurationMeasurePointTypes: {
          name: 'canDeleteProcessConfigurationMeasurePointTypes',
          defaultValue: false
        },
        canCreateProcessConfigurationNoteCategories: {
          name: 'canCreateProcessConfigurationNoteCategories',
          defaultValue: false
        },
        canUpdateProcessConfigurationNoteCategories: {
          name: 'canUpdateProcessConfigurationNoteCategories',
          defaultValue: false
        },
        canDeleteProcessConfigurationNoteCategories: {
          name: 'canDeleteProcessConfigurationNoteCategories',
          defaultValue: false
        },
        canCreateProcessConfigurationSteps: {
          name: 'canCreateProcessConfigurationSteps',
          defaultValue: false
        },
        canUpdateProcessConfigurationSteps: {
          name: 'canUpdateProcessConfigurationSteps',
          defaultValue: false
        },
        canDeleteProcessConfigurationSteps: {
          name: 'canDeleteProcessConfigurationSteps',
          defaultValue: false
        },
        canCreateProcessConfigurationStepAutoAppointments: {
          name: 'canCreateProcessConfigurationStepAutoAppointments',
          defaultValue: false
        },
        canUpdateProcessConfigurationStepAutoAppointments: {
          name: 'canUpdateProcessConfigurationStepAutoAppointments',
          defaultValue: false
        },
        canDeleteProcessConfigurationStepAutoAppointments: {
          name: 'canDeleteProcessConfigurationStepAutoAppointments',
          defaultValue: false
        },
        canCreateProcessConfigurationStepAutoForms: {
          name: 'canCreateProcessConfigurationStepAutoForms',
          defaultValue: false
        },
        canUpdateProcessConfigurationStepAutoForms: {
          name: 'canUpdateProcessConfigurationStepAutoForms',
          defaultValue: false
        },
        canDeleteProcessConfigurationStepAutoForms: {
          name: 'canDeleteProcessConfigurationStepAutoForms',
          defaultValue: false
        },
        canCreateProcessConfigurationStepPositions: {
          name: 'canCreateProcessConfigurationStepPositions',
          defaultValue: false
        },
        canUpdateProcessConfigurationStepPositions: {
          name: 'canUpdateProcessConfigurationStepPositions',
          defaultValue: false
        },
        canDeleteProcessConfigurationStepPositions: {
          name: 'canDeleteProcessConfigurationStepPositions',
          defaultValue: false
        },
        canCreateProcessTaskAppointmentToProcessTaskDevices: {
          name: 'canCreateProcessTaskAppointmentToProcessTaskDevices',
          defaultValue: false
        },
        canUpdateProcessTaskAppointmentToProcessTaskDevices: {
          name: 'canUpdateProcessTaskAppointmentToProcessTaskDevices',
          defaultValue: false
        },
        canDeleteProcessTaskAppointmentToProcessTaskDevices: {
          name: 'canDeleteProcessTaskAppointmentToProcessTaskDevices',
          defaultValue: false
        },
        canCreateProcessTaskAppointmentToProcessTaskPositions: {
          name: 'canCreateProcessTaskAppointmentToProcessTaskPositions',
          defaultValue: false
        },
        canUpdateProcessTaskAppointmentToProcessTaskPositions: {
          name: 'canUpdateProcessTaskAppointmentToProcessTaskPositions',
          defaultValue: false
        },
        canDeleteProcessTaskAppointmentToProcessTaskPositions: {
          name: 'canDeleteProcessTaskAppointmentToProcessTaskPositions',
          defaultValue: false
        },
        canCreateProcessTaskGroupFilters: {
          name: 'canCreateProcessTaskGroupFilters',
          defaultValue: false
        },
        canUpdateProcessTaskGroupFilters: {
          name: 'canUpdateProcessTaskGroupFilters',
          defaultValue: false
        },
        canDeleteProcessTaskGroupFilters: {
          name: 'canDeleteProcessTaskGroupFilters',
          defaultValue: false
        },
        canCreateProcessTaskInvoices: {
          name: 'canCreateProcessTaskInvoices',
          defaultValue: false
        },
        canUpdateProcessTaskInvoices: {
          name: 'canUpdateProcessTaskInvoices',
          defaultValue: false
        },
        canDeleteProcessTaskInvoices: {
          name: 'canDeleteProcessTaskInvoices',
          defaultValue: false
        },
        canCreateProcessTaskMeasurePoints: {
          name: 'canCreateProcessTaskMeasurePoints',
          defaultValue: false
        },
        canUpdateProcessTaskMeasurePoints: {
          name: 'canUpdateProcessTaskMeasurePoints',
          defaultValue: false
        },
        canDeleteProcessTaskMeasurePoints: {
          name: 'canDeleteProcessTaskMeasurePoints',
          defaultValue: false
        },
        canCreateProcessTaskMeasurePointReadings: {
          name: 'canCreateProcessTaskMeasurePointReadings',
          defaultValue: false
        },
        canUpdateProcessTaskMeasurePointReadings: {
          name: 'canUpdateProcessTaskMeasurePointReadings',
          defaultValue: false
        },
        canDeleteProcessTaskMeasurePointReadings: {
          name: 'canDeleteProcessTaskMeasurePointReadings',
          defaultValue: false
        },
        canCreateProcessTasks: {
          name: 'canCreateProcessTasks',
          defaultValue: false
        },
        canUpdateProcessTasks: {
          name: 'canUpdateProcessTasks',
          defaultValue: false
        },
        canDeleteProcessTasks: {
          name: 'canDeleteProcessTasks',
          defaultValue: false
        },
        canCreateProcessTaskAppointments: {
          name: 'canCreateProcessTaskAppointments',
          defaultValue: false
        },
        canUpdateProcessTaskAppointments: {
          name: 'canUpdateProcessTaskAppointments',
          defaultValue: false
        },
        canDeleteProcessTaskAppointments: {
          name: 'canDeleteProcessTaskAppointments',
          defaultValue: false
        },
        canCreateProcessTaskAppointmentsInFieldCalendar: {
          name: 'canCreateProcessTaskAppointmentsInFieldCalendar',
          defaultValue: false
        },
        canCreateProcessTaskAppointmentContacts: {
          name: 'canCreateProcessTaskAppointmentContacts',
          defaultValue: false
        },
        canUpdateProcessTaskAppointmentContacts: {
          name: 'canUpdateProcessTaskAppointmentContacts',
          defaultValue: false
        },
        canDeleteProcessTaskAppointmentContacts: {
          name: 'canDeleteProcessTaskAppointmentContacts',
          defaultValue: false
        },
        canCreateProcessTaskAppointmentToUsers: {
          name: 'canCreateProcessTaskAppointmentToUsers',
          defaultValue: false
        },
        canUpdateProcessTaskAppointmentToUsers: {
          name: 'canUpdateProcessTaskAppointmentToUsers',
          defaultValue: false
        },
        canDeleteProcessTaskAppointmentToUsers: {
          name: 'canDeleteProcessTaskAppointmentToUsers',
          defaultValue: false
        },
        canCreateProcessTaskChecklistEntries: {
          name: 'canCreateProcessTaskChecklistEntries',
          defaultValue: false
        },
        canUpdateProcessTaskChecklistEntries: {
          name: 'canUpdateProcessTaskChecklistEntries',
          defaultValue: false
        },
        canDeleteProcessTaskChecklistEntries: {
          name: 'canDeleteProcessTaskChecklistEntries',
          defaultValue: false
        },
        canCreateProcessTaskComments: {
          name: 'canCreateProcessTaskComments',
          defaultValue: false
        },
        canUpdateProcessTaskComments: {
          name: 'canUpdateProcessTaskComments',
          defaultValue: false
        },
        canDeleteProcessTaskComments: {
          name: 'canDeleteProcessTaskComments',
          defaultValue: false
        },
        canCreateProcessTaskDevices: {
          name: 'canCreateProcessTaskDevices',
          defaultValue: false
        },
        canUpdateProcessTaskDevices: {
          name: 'canUpdateProcessTaskDevices',
          defaultValue: false
        },
        canDeleteProcessTaskDevices: {
          name: 'canDeleteProcessTaskDevices',
          defaultValue: false
        },
        canCreateProcessTaskGroups: {
          name: 'canCreateProcessTaskGroups',
          defaultValue: false
        },
        canUpdateProcessTaskGroups: {
          name: 'canUpdateProcessTaskGroups',
          defaultValue: false
        },
        canDeleteProcessTaskGroups: {
          name: 'canDeleteProcessTaskGroups',
          defaultValue: false
        },
        canCreateProcessTaskGroupAuthorizations: {
          name: 'canCreateProcessTaskGroupAuthorizations',
          defaultValue: false
        },
        canUpdateProcessTaskGroupAuthorizations: {
          name: 'canUpdateProcessTaskGroupAuthorizations',
          defaultValue: false
        },
        canDeleteProcessTaskGroupAuthorizations: {
          name: 'canDeleteProcessTaskGroupAuthorizations',
          defaultValue: false
        },
        canCreateProcessTaskNotes: {
          name: 'canCreateProcessTaskNotes',
          defaultValue: false
        },
        canUpdateProcessTaskNotes: {
          name: 'canUpdateProcessTaskNotes',
          defaultValue: false
        },
        canDeleteProcessTaskNotes: {
          name: 'canDeleteProcessTaskNotes',
          defaultValue: false
        },
        canCreateProcessTaskOffers: {
          name: 'canCreateProcessTaskOffers',
          defaultValue: false
        },
        canUpdateProcessTaskOffers: {
          name: 'canUpdateProcessTaskOffers',
          defaultValue: false
        },
        canDeleteProcessTaskOffers: {
          name: 'canDeleteProcessTaskOffers',
          defaultValue: false
        },
        canCreateProcessTaskPositions: {
          name: 'canCreateProcessTaskPositions',
          defaultValue: false
        },
        canUpdateProcessTaskPositions: {
          name: 'canUpdateProcessTaskPositions',
          defaultValue: false
        },
        canDeleteProcessTaskPositions: {
          name: 'canDeleteProcessTaskPositions',
          defaultValue: false
        },
        canCreateProcessTaskRecurringAppointments: {
          name: 'canCreateProcessTaskRecurringAppointments',
          defaultValue: false
        },
        canUpdateProcessTaskRecurringAppointments: {
          name: 'canUpdateProcessTaskRecurringAppointments',
          defaultValue: false
        },
        canDeleteProcessTaskRecurringAppointments: {
          name: 'canDeleteProcessTaskRecurringAppointments',
          defaultValue: false
        },
        canCreateProjects: {
          name: 'canCreateProjects',
          defaultValue: false
        },
        canUpdateProjects: {
          name: 'canUpdateProjects',
          defaultValue: false
        },
        canDeleteProjects: {
          name: 'canDeleteProjects',
          defaultValue: false
        },
        canCreateRegions: {
          name: 'canCreateRegions',
          defaultValue: false
        },
        canUpdateRegions: {
          name: 'canUpdateRegions',
          defaultValue: false
        },
        canDeleteRegions: {
          name: 'canDeleteRegions',
          defaultValue: false
        },
        canCreateReports: {
          name: 'canCreateReports',
          defaultValue: false
        },
        canUpdateReports: {
          name: 'canUpdateReports',
          defaultValue: false
        },
        canDeleteReports: {
          name: 'canDeleteReports',
          defaultValue: false
        },
        canCreateReportSignatures: {
          name: 'canCreateReportSignatures',
          defaultValue: false
        },
        canCreateReportTypes: {
          name: 'canCreateReportTypes',
          defaultValue: false
        },
        canUpdateReportTypes: {
          name: 'canUpdateReportTypes',
          defaultValue: false
        },
        canDeleteReportTypes: {
          name: 'canDeleteReportTypes',
          defaultValue: false
        },
        canCreateSharepointListColumnToThingMappingItems: {
          name: 'canCreateSharepointListColumnToThingMappingItems',
          defaultValue: false
        },
        canUpdateSharepointListColumnToThingMappingItems: {
          name: 'canUpdateSharepointListColumnToThingMappingItems',
          defaultValue: false
        },
        canDeleteSharepointListColumnToThingMappingItems: {
          name: 'canDeleteSharepointListColumnToThingMappingItems',
          defaultValue: false
        },
        canCreateStructureTemplateEntryGroups: {
          name: 'canCreateStructureTemplateEntryGroups',
          defaultValue: false
        },
        canUpdateStructureTemplateEntryGroups: {
          name: 'canUpdateStructureTemplateEntryGroups',
          defaultValue: false
        },
        canDeleteStructureTemplateEntryGroups: {
          name: 'canDeleteStructureTemplateEntryGroups',
          defaultValue: false
        },
        canCreateStructureTemplateEntries: {
          name: 'canCreateStructureTemplateEntries',
          defaultValue: false
        },
        canUpdateStructureTemplateEntries: {
          name: 'canUpdateStructureTemplateEntries',
          defaultValue: false
        },
        canDeleteStructureTemplateEntries: {
          name: 'canDeleteStructureTemplateEntries',
          defaultValue: false
        },
        canCreateStructureTemplates: {
          name: 'canCreateStructureTemplates',
          defaultValue: false
        },
        canUpdateStructureTemplates: {
          name: 'canUpdateStructureTemplates',
          defaultValue: false
        },
        canDeleteStructureTemplates: {
          name: 'canDeleteStructureTemplates',
          defaultValue: false
        },
        canCreateTextBricks: {
          name: 'canCreateTextBricks',
          defaultValue: false
        },
        canUpdateTextBricks: {
          name: 'canUpdateTextBricks',
          defaultValue: false
        },
        canDeleteTextBricks: {
          name: 'canDeleteTextBricks',
          defaultValue: false
        },
        canCreateTextBrickTemplates: {
          name: 'canCreateTextBrickTemplates',
          defaultValue: false
        },
        canUpdateTextBrickTemplates: {
          name: 'canUpdateTextBrickTemplates',
          defaultValue: false
        },
        canDeleteTextBrickTemplates: {
          name: 'canDeleteTextBrickTemplates',
          defaultValue: false
        },
        canCreateThings: {
          name: 'canCreateThings',
          defaultValue: false
        },
        canUpdateThings: {
          name: 'canUpdateThings',
          defaultValue: false
        },
        canDeleteThings: {
          name: 'canDeleteThings',
          defaultValue: false
        },
        canCreateThingAuthorizations: {
          name: 'canCreateThingAuthorizations',
          defaultValue: false
        },
        canUpdateThingAuthorizations: {
          name: 'canUpdateThingAuthorizations',
          defaultValue: false
        },
        canDeleteThingAuthorizations: {
          name: 'canDeleteThingAuthorizations',
          defaultValue: false
        },
        canCreateThingGroups: {
          name: 'canCreateThingGroups',
          defaultValue: false
        },
        canUpdateThingGroups: {
          name: 'canUpdateThingGroups',
          defaultValue: false
        },
        canDeleteThingGroups: {
          name: 'canDeleteThingGroups',
          defaultValue: false
        },
        canCreateThingToPersons: {
          name: 'canCreateThingToPersons',
          defaultValue: false
        },
        canUpdateThingToPersons: {
          name: 'canUpdateThingToPersons',
          defaultValue: false
        },
        canDeleteThingToPersons: {
          name: 'canDeleteThingToPersons',
          defaultValue: false
        },
        canCreateUserDefinedEntityConfigs: {
          name: 'canCreateUserDefinedEntityConfigs',
          defaultValue: false
        },
        canUpdateUserDefinedEntityConfigs: {
          name: 'canUpdateUserDefinedEntityConfigs',
          defaultValue: false
        },
        canDeleteUserDefinedEntityConfigs: {
          name: 'canDeleteUserDefinedEntityConfigs',
          defaultValue: false
        },
        canCreateUserDefinedEntities: {
          name: 'canCreateUserDefinedEntities',
          defaultValue: false
        },
        canUpdateUserDefinedEntities: {
          name: 'canUpdateUserDefinedEntities',
          defaultValue: false
        },
        canDeleteUserDefinedEntities: {
          name: 'canDeleteUserDefinedEntities',
          defaultValue: false
        },
        canCreateUserRoles: {
          name: 'canCreateUserRoles',
          defaultValue: false
        },
        canUpdateUserRoles: {
          name: 'canUpdateUserRoles',
          defaultValue: false
        },
        canDeleteUserRoles: {
          name: 'canDeleteUserRoles',
          defaultValue: false
        },
        canCreateUserRoleToUsers: {
          name: 'canCreateUserRoleToUsers',
          defaultValue: false
        },
        canUpdateUserRoleToUsers: {
          name: 'canUpdateUserRoleToUsers',
          defaultValue: false
        },
        canDeleteUserRoleToUsers: {
          name: 'canDeleteUserRoleToUsers',
          defaultValue: false
        }
      },
      options.additionalFieldInfos
    )
  };
}
