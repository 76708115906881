import { bindable, autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';
import { FileSizeUtils } from 'common/Utils/FileSizeUtils';
import {
  ExportStatus,
  ExportType,
  UploadedStatus
} from 'common/Types/Entities/Report/ReportDto';

import { SocketService } from '../../services/SocketService';

import { Dialogs } from '../../classes/Dialogs';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ReportUtils } from '../../classes/EntityManager/entities/Report/ReportUtils';
import { SessionService } from '../../services/SessionService/SessionService';
import { FileDownloadService } from '../../services/FileDownloadService';
import { Report } from '../../classes/EntityManager/entities/Report/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { computed } from '../../hooks/computed';
import { expression, isConnected, model } from '../../hooks/dependencies';
import { RequestWithStatusService } from '../../services/RequestWithStatusService';
import { Status } from '../../classes/RequestWithStatus';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { SignPdfReportDialog } from '../../dialogs/sign-pdf-report-dialog/sign-pdf-report-dialog';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

@autoinject()
export class ReportListItem {
  @bindable()
  public report: Report | null = null;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Report];

  private panelOpen: boolean = false;

  private listItemElement: HTMLElement | null = null;

  protected readonly uploadReportFileRequest;

  protected ExportStatus = ExportStatus;
  protected UploadedStatus = UploadedStatus;

  constructor(
    private readonly fileDownloadService: FileDownloadService,
    private readonly socketService: SocketService,
    private readonly entityManager: AppEntityManager,
    private readonly sessionService: SessionService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    permissionsService: PermissionsService,
    requestWithStatusService: RequestWithStatusService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        context: this as ReportListItem,
        entityName: EntityName.Report,
        propertyName: 'report'
      }
    );

    this.uploadReportFileRequest =
      requestWithStatusService.createRequestWithStatus(async () => {
        assertNotNullOrUndefined(
          this.report,
          'cannot upload to sharepoint without a report'
        );

        const response =
          await this.socketService.sharepointSocketEndpoints.uploadReportFile({
            userGroupId: this.report.ownerUserGroupId,
            reportId: this.report.id
          });

        if (!response.success) {
          void Dialogs.errorDialogTk(
            `serverResponses.sharepointUploadReportFile.${response.status}`
          );
          return Status.ERROR;
        }

        return Status.OK;
      });
  }

  public highlight(): void {
    assertNotNullOrUndefined(
      this.listItemElement,
      "can't ReportListItem.highlight without listItemElement"
    );
    EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleDeleteReport(): void {
    const report = this.report;
    assertNotNullOrUndefined(report, 'no report to delete');

    void Dialogs.deleteEntityDialog(report).then(() => {
      this.entityManager.reportRepository.delete(report);
    });
  }

  protected async handleDownloadReport(): Promise<void> {
    assertNotNullOrUndefined(this.report, 'no report to download');
    if (this.canDownloadReport) {
      const token = this.sessionService.getCurrentJWT();
      assertNotNullOrUndefined(
        token,
        'tried to download report without a token'
      );

      const downloadUrl = ReportUtils.getRelativeOnlineFilePathForReport(
        this.report,
        token
      );
      await this.fileDownloadService.downloadFile(downloadUrl);
    }
  }

  protected async handleSignReport(): Promise<void> {
    assertNotNullOrUndefined(this.report, 'no report to sign');
    if (this.isSignable) {
      await SignPdfReportDialog.open({ reportId: this.report.id });
    }
  }

  protected handleUploadReportToSharepoint(): void {
    if (!this.sharepointUploadEnabled)
      throw new Error('sharepoint upload is not enabled');
    this.uploadReportFileRequest.startRequest();
  }

  @computed(isConnected(), expression('report.status'))
  private get canDownloadReport(): boolean {
    return (
      this.socketService.isConnected() &&
      this.report?.status === ExportStatus.READY
    );
  }

  @computed(
    expression('sharepointUploadConfigured'),
    expression('canDownloadReport'),
    expression('report.uploadedToExternalLocationStatus'),
    expression('report.signatureTime'),
    expression('report.type'),
    expression('userGroup.sharepointCredentials.sharepointLimitToPdfFiles'),
    expression('userGroup.sharepointCredentials.sharepointOnlyAllowSignedFiles')
  )
  protected get sharepointUploadEnabled(): boolean {
    if (!this.sharepointUploadConfigured) {
      return false;
    }
    if (!this.canDownloadReport) {
      return false;
    }
    if (
      this.report?.uploadedToExternalLocationStatus === UploadedStatus.UPLOADED
    ) {
      return false;
    }

    const sharepointConfig = this.userGroup?.sharepointCredentials;
    const isPdf = this.report?.type === ExportType.PDF;
    const isSigned = this.report?.signatureTime != null;

    if (sharepointConfig?.sharepointOnlyAllowSignedFiles) {
      if (!isSigned) {
        return false;
      }
    }

    if (sharepointConfig?.sharepointLimitToPdfFiles) {
      if (!isPdf) {
        return false;
      }
    }

    return true;
  }

  @computed(expression('userGroup.sharepointCredentials'))
  private get sharepointUploadConfigured(): boolean {
    const sharepointCredentials = this.userGroup?.sharepointCredentials;
    return [
      sharepointCredentials?.applicationId,
      sharepointCredentials?.tenantId,
      sharepointCredentials?.clientCertificate.thumbprint,
      sharepointCredentials?.clientCertificate.privateKey,
      sharepointCredentials?.sharepointExportSite,
      sharepointCredentials?.sharepointExportPath
    ].every(Boolean);
  }

  @computed(expression('report.ownerUserGroupId'), model(EntityName.UserGroup))
  private get userGroup(): UserGroup | null {
    return this.report?.ownerUserGroupId
      ? this.entityManager.userGroupRepository.getById(
          this.report?.ownerUserGroupId
        )
      : null;
  }

  @computed(expression('report.type'))
  protected get iconClass(): string {
    switch (this.report?.type) {
      case ExportType.DOCX:
        return 'fa-file-word';
      case ExportType.PDF:
        return 'fa-file-pdf';
      case ExportType.ZIP:
      case ExportType.KUK_ZIP:
        return 'fa-file-archive';
      default:
        return 'fa-file';
    }
  }

  @computed(expression('report.type'))
  protected get isSignable(): boolean {
    if (this.report?.type !== ExportType.PDF) {
      return false;
    }
    return true;
  }

  @computed(expression('report.signatureTime'))
  protected get signatureTime(): string | null {
    return this.report?.signatureTime
      ? DateUtils.formatToDateString(this.report.signatureTime)
      : null;
  }

  @computed(expression('report.signatureUserId'), model(EntityName.User))
  protected get signatureUsername(): string | null {
    return this.report?.signatureUserId
      ? (this.entityManager.userRepository.getById(this.report.signatureUserId)
          ?.username ?? null)
      : null;
  }

  protected getReportTypeName(reportTypeId: string): string | null {
    const reportType =
      this.entityManager.reportTypeRepository.getById(reportTypeId);
    return reportType ? reportType.name : null;
  }

  protected formatFileSize(size: number | null): string {
    if (size == null) {
      return 'N/A';
    }

    return FileSizeUtils.formatWithAutomaticUnit(size);
  }

  protected formatTimeToDateWithTime(time: number | null): string {
    if (time == null) {
      return '';
    }

    return DateUtils.formatToDateWithTimeString(time);
  }

  @computed(
    expression('report.status'),
    expression('uploadReportFileRequest.inProgress')
  )
  protected get showPreloader(): boolean {
    return (
      (!!this.report?.status &&
        [ExportStatus.EXPORTING, ExportStatus.CONVERTING].includes(
          this.report.status
        )) ||
      this.uploadReportFileRequest.inProgress
    );
  }

  @computed(expression('report.status'), expression('showPreloader'))
  protected get showDisabled(): boolean {
    return (
      !this.report?.status ||
      this.report.status !== ExportStatus.READY ||
      this.showPreloader
    );
  }
}
