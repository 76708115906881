import { z } from 'zod';
import { createTypeExtendsValidator } from '../Types/typeUtils';

export enum DeveloperCommandPermission {
  ALL = 'all',
  USER_ADMIN = 'userAdmin'
}

export const developerCommands =
  createTypeExtendsValidator<DeveloperCommandsContraint>()({
    patchOldGalleryThingPictures: {
      permission: DeveloperCommandPermission.USER_ADMIN,
      dataSchema: z.object({})
    },
    copyDefectPicturesFromOriginalUsergroup: {
      permission: DeveloperCommandPermission.USER_ADMIN,
      dataSchema: z.object({
        targetUserGroupId: z.string(),
        isDryRun: z.boolean().optional(),
        magicPropertyName: z.string().optional()
      })
    },
    revertDeletion: {
      dataSchema: z.object({
        entityName: z.string(),
        entityId: z.string(),
        dryRun: z.boolean(),
        deletedAtOffsetInMin: z.number().optional()
      }),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    serverInfo: {
      dataSchema: z.object({}),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    accountDeletion: {
      permission: DeveloperCommandPermission.USER_ADMIN,
      dataSchema: z.object({
        userId: z.string()
      })
    },
    moveDataBetweenUsergroups: {
      dataSchema: z.object({
        targetUserGroupId: z.string(),
        sourceUserGroupId: z.string(),
        isDryRun: z.boolean().optional()
      }),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    patchPictureFilesProjectField: {
      dataSchema: z.object({}),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    patchTagNameCases: {
      dataSchema: z.object({
        isDryRun: z.boolean().optional()
      }),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    patchProcessTaskGroupContactPersons: {
      dataSchema: z.object({
        isDryRun: z.boolean().optional()
      }),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    renameKeyOfDbEntity: {
      dataSchema: z.object({
        entityName: z.string(),
        oldKeyName: z.string(),
        newKeyName: z.string(),
        setOldKeyToNullIfThisKeyNameIsNotNull: z.string().optional(),
        isDryRun: z.boolean().optional()
      }),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    patchInvalidEntities: {
      dataSchema: z.object({}),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    tryToConvertAllEntitiesToADto: {
      dataSchema: z.object({
        startingEntityName: z.string().nullable().optional()
      }),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    synchronizeSharepointLists: {
      dataSchema: z.object({}),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    createThingAuthorizationsFromCuratorIds: {
      dataSchema: z.object({
        isDryRun: z.boolean().optional()
      }),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    patchOperationsProjects: {
      dataSchema: z.object({
        isDryRun: z.boolean().optional()
      }),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    patchEmailHashes: {
      dataSchema: z.object({}),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    refreshViaPictureAutomaticMarkings: {
      dataSchema: z.object({
        thingId: z.string(),
        resetCoordsFromPositionedPictureInfo: z.boolean(),
        isDryRun: z.boolean().optional()
      }),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    rebuildOperationsCache: {
      dataSchema: z.object({}),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    patchDefectPictureSequenceNumbers: {
      dataSchema: z.object({
        isDryRun: z.boolean().optional()
      }),
      permission: DeveloperCommandPermission.USER_ADMIN
    },
    patchTagCategories: {
      dataSchema: z.object({
        isDryRun: z.boolean().optional()
      }),
      permission: DeveloperCommandPermission.USER_ADMIN
    }
  });

export type DeveloperCommands = typeof developerCommands;
export type DeveloperCommandName = keyof typeof developerCommands;
export type DataForDeveloperCommandName<
  TCommandName extends DeveloperCommandName
> = z.infer<DeveloperCommands[TCommandName]['dataSchema']>;

type DeveloperCommandsContraint = {
  [key in string]: DeveloperCommandConfig;
};

export type DeveloperCommandConfig = {
  permission: DeveloperCommandPermission;
  dataSchema: z.ZodObject<any>;
};
